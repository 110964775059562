import React, { useState } from "react";
import { NewTheme } from "../Theme/Theme";
import { useSelector } from "react-redux";

export default function TransactionTopCard(props) {
  var volumesum = 0;
  var qtysum = 0;
  var qtysum2 = 0;
  var qtysum3 = 0;
  var profitloss = 0;
  var brokerage = 0;
  props.transactions.map((i) => {
    volumesum += i.volume;
    profitloss = parseFloat(profitloss) + parseFloat(i.profit_loss);
    brokerage = parseFloat(brokerage) + parseFloat(i.brokerage_amt);

    if (i.script_type == "fut") {
      qtysum = parseFloat(qtysum) + parseFloat(i.qty);
    }
    if (i.script_type == "mcx") {
      qtysum2 = parseFloat(qtysum2) + parseFloat(i.qty);
    }
    if (i.script_type == "fo") {
      qtysum3 = parseFloat(qtysum3) + parseFloat(i.qty);
    }
  });
  return (
    <div
      style={{
        height: "auto",
        background: NewTheme.lightmain,
        display: "flex",
        fontWeight: "bold",
        fontSize: 13,
      }}
    >
      <div
        style={{
          flex: 1,
          borderStyle: "solid",
          borderWidth: 0,
          borderLeftWidth: 1,
          boxSizing: "border-box",
          padding: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <div>SEGMENT FILTER</div>
          <select onChange={(e) => props.filtersegement(e.target.value)}>
            <option selected value={0}>
              ALL
            </option>
            <option value={1}>FUTURE</option>
            <option value={2}>MCX</option>
            <option value={3}>OPTION</option>
          </select>
        </div>
      </div>
      <div
        style={{
          flex: 2,
          borderStyle: "solid",
          borderWidth: 0,
          boxSizing: "border-box",
          padding: 5,
        }}
      ></div>
      <div
        style={{
          flex: 1,
          borderStyle: "solid",
          borderWidth: 0,
          borderLeftWidth: 1,
          boxSizing: "border-box",
          padding: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>Qty Fut</div>
          <div
            style={{
              color:
                props.profitlos >= 0 ? NewTheme.greencolor : NewTheme.redcolor,
              fontWeight: "bold",
            }}
          >
            {parseFloat(qtysum).toFixed(2)}
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>Qty Mcx</div>
          <div style={{ color: NewTheme.redcolor }}>
            {parseFloat(qtysum2).toFixed(2)}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>Qty Option</div>
          <div
            style={{
              fontWeight: "bold",
            }}
          >
            {parseFloat(qtysum3).toFixed(2)}
          </div>
        </div>
      </div>
      <div
        style={{
          flex: 1,
          borderStyle: "solid",
          borderWidth: 0,
          borderLeftWidth: 1,
          boxSizing: "border-box",
          padding: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>Profit Loss</div>
          <div
            style={{
              color:
                props.profitlos >= 0 ? NewTheme.greencolor : NewTheme.redcolor,
              fontWeight: "bold",
            }}
          >
            {parseFloat(profitloss).toFixed(2)}
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>Brokerage</div>
          <div style={{ color: NewTheme.redcolor }}>
            {parseFloat(brokerage).toFixed(2)}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>Total Volume</div>
          <div
            style={{
              fontWeight: "bold",
            }}
          >
            {parseFloat(volumesum).toFixed(2)}
          </div>
        </div>
      </div>
    </div>
  );
}
