import { React, useState, useEffect, useContext } from "react";

import SocketContext from "../../SocketContext";
import moment from "moment";
import BidaskCard from "./BidAsk";
import { NewTheme } from "../../Theme/Theme";

const Watchitem = (props) => {
  const i = props.item;
  // console.log(typeof i);
  // console.log("i");
  // console.log(typeof i.symbol_display);
  // console.log("hello");
  // console.log(props.list);
  const socket = useContext(SocketContext);
  const [chng, setchng] = useState(0);
  const [percent, setpercent] = useState(0);
  const [ask, setask] = useState(0);
  const [askhigh, setaskhigh] = useState(false);
  const [bid, setbid] = useState(0);
  const [bidhigh, setbidhigh] = useState(false);
  const [high2, sethigh2] = useState(0);
  const [low2, setlow2] = useState(0);
  const [ltp, setltp] = useState(0);
  const [ticktime, SetTicktime] = useState(0);
  const [open, setopen] = useState(0);
  const [close, setclose] = useState(0);
  // const [trade,settrade] = useState("")
  // const
  // setTimeout(() => {
  //   console.log("Helo instdet hte time out");
  // }, 1000);
  // setInterval(() => {
  //   console.log("the time inteval")
  // }, 10000);
  useEffect(() => {
    // loadProfile();
    console.log("Tick time");
    console.log("i");
    console.log(i);
    socket.emit("giverate", i.script_id);
    socket.on("trade" + i.script_id, trade);
    socket.on("bidask" + i.script_id, bidask);
    return () => {
      socket.off("trade" + i.script_id, trade);
      socket.off("bidask" + i.script_id, bidask);
    };
  }, []);

  var trade = (msg) => {
    SetTicktime(msg.Timestamp);
    if (bid !== msg.Bid) {
      //   if( parseFloat(bidnew)  < parseFloat(msg.Bid) ){
      //     // bidUp=1

      //    }else if( parseFloat(bidnew)  > parseFloat(msg.Bid) ){
      //     //  bidUp=2

      //    }
      console.log("-----");
      console.log(bid);
      console.log(msg.Bid);
      console.log(typeof bid);
      console.log(typeof msg.Bid);
      if (parseFloat(bid) < parseFloat(msg.Bid)) {
        setbidhigh(true);
      }
      if (parseFloat(bid) >= parseFloat(msg.Bid)) {
        setbidhigh(false);
      }
      console.log(bidhigh);

      setbid(msg.Bid);

      //   bidnew = msg.Bid
    }
    if (ask !== msg.Ask) {
      //   if( parseFloat(asknew)  < parseFloat(msg.Ask) ){
      // //    askUp=1

      //   }else if( parseFloat(asknew)  > parseFloat(msg.Ask) ){
      //     // askUp=2

      //   }
      console.log("_-_-_-_");
      console.log(ask);
      console.log(msg.Ask);
      console.log(typeof ask);
      console.log(typeof msg.Ask);
      if (parseFloat(ask) < parseFloat(msg.Ask)) {
        setaskhigh(true);
      }
      if (parseFloat(ask) >= parseFloat(msg.Ask)) {
        setaskhigh(false);
      }
      console.log(askhigh);
      setask(msg.Ask);
      //   asknew =msg.Ask
    }
    if (high2 !== msg.High) {
      sethigh2(msg.High);
    }
    if (low2 !== msg.Low) {
      setlow2(msg.Low);
    }
    if (msg.LTP - msg.Previous_Close !== chng) {
      setchng(msg.LTP - msg.Previous_Close);
      setpercent(((msg.LTP - msg.Previous_Close) / msg.Previous_Close) * 100);
    }
    if (ltp !== msg.LTP) {
      setltp(msg.LTP);
    }

    if (open !== msg.Open) {
      setopen(msg.Open);
    }
    if (close !== msg.Previous_Close) {
      setclose(msg.Previous_Close);
    }
  };

  var bidask = (msg) => {
    SetTicktime(msg.Timestamp);
    if (bid !== msg.Bid) {
      //       if( parseFloat(bidnew)  < parseFloat(msg.Bid) ){
      //         // bidUp=1
      // //
      //        }else if( parseFloat(bidnew)  > parseFloat(msg.Bid) ){
      //         //  bidUp=2

      //        }
      //   bidnew = msg.Bid

      // setBidopsite(true);
      setbid(msg.Bid);
      // setTimeout(() => {
      //   setBidopsite(false);
      // }, 200);
    }
    if (ask !== msg.Ask) {
      //   if( parseFloat(asknew)  < parseFloat(msg.Ask) ){

      //     // askUp=1
      //   }else if( parseFloat(asknew)  > parseFloat(msg.Ask) ){
      // //    askUp=2

      //   }
      //   asknew = msg.Ask
      // setAskopsite(true);
      setask(msg.Ask);

      // setTimeout(() => {
      //   setAskopsite(false);
      // }, 200);
    }
  };

  return (
    <div
      key={i.id}
      style={{
        display: "flex",
        padding: 5,
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
      }}
    >
      <div style={{ flex: 2 }}>
        <div style={{ fontSize: 16, fontWeight: "bold" }}>
          {i.symbol_display}
          <div style={{ fontSize: 11, color: "gray" }}>
            {moment(i.expiry_date).format("DD-MMM")}
          </div>
        </div>
        <div
          style={{
            fontSize: 12,
            fontWeight: "bold",
            color: NewTheme.MainColor,
          }}
        >
          LTP: {ltp}
        </div>
        <div
          style={{
            fontSize: 12,
            fontWeight: "bold",
            color: NewTheme.MainColor,
          }}
        >
          Chng: {parseFloat(chng).toFixed(2)}
        </div>
      </div>

      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ width: "80%", alignSelf: "center", height: 35 }}>
          <BidaskCard value={bid} />
        </div>
        <div
          style={{
            alignSelf: "center",
            fontSize: 12,
            fontWeight: "bold",
            marginTop: 5,
          }}
        >
          H:{high2}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ width: "80%", alignSelf: "center", height: 35 }}>
          <BidaskCard value={ask} />
        </div>
        <div
          style={{
            alignSelf: "center",
            fontSize: 12,
            fontWeight: "bold",
            marginTop: 5,
          }}
        >
          L:{low2}
        </div>
      </div>
    </div>

    // <div className="data_item">
    //   <div className="data_script_part">
    //     <p className="script_text">{props.item.symbol_display}</p>
    //     <p className="ltp_text">
    //       <span>{moment(i.expiry_date).format("DD-MMM")}</span> LTP: {ltp}
    //     </p>
    //     <p className="bscript_text">
    //       678.09 ({parseFloat(percent).toFixed(2)}%)
    //     </p>
    //     <p className="expiry_text">
    //       {/* {ticktime} */}
    //       {moment(ticktime).format("DD-MM-YYYY HH-mm-ss")}
    //       {/* 18:45:76: 09;09;09 */}
    //     </p>
    //   </div>
    //   <div className="data_bid_part">
    //     <p className="ask_text" style={{ borderRadius: 10 }}>
    //       <BidaskCard value={bid} />
    //     </p>

    //     <p style={{ fontWeight: "bold" }} className="h_text">
    //       H:{high2}
    //     </p>
    //   </div>
    //   <div className="data_ask_part">
    //     <p className="ask_text" style={{ borderRadius: 10 }}>
    //       <BidaskCard value={ask} />
    //     </p>
    //     <p style={{ fontWeight: "bold" }} className="l_text">
    //       L:{low2}
    //     </p>
    //   </div>
    // </div>
  );
};

export default Watchitem;
