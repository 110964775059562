import { React, useState, useEffect, useCallback, useRef } from "react";
// import useWindowSize from 'usehooks-ts'

import moment, { now } from "moment";

import logo from "../../logo.svg";
import Backend from "../../Backend";
import { NewTheme } from "../Theme/Theme";
import Transactioncard from "./Transactioncard";
import TransactionTopCard from "./TransactionTopCard";
import "./Trans.css";
const backend = new Backend();

const Transaction = (props) => {
  const [transaction, SetTransactions] = useState([]);
  const [transaction2, SetTransactions2] = useState([]);
  const [loading, setLoading] = useState(false);
  const [brokerage, SetBrokerage] = useState("");
  const [profitloss, SetProfitloss] = useState("");
  const [transactionSelected, setTransactionSelected] = useState("");
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  useEffect(() => {
    loadTransaction();
    var dataslect = document.getElementById("start");
    setTimeout(() => {
      dataslect.focus();
    }, 200);
  }, []);

  const loadTransaction = () => {
    setLoading(true);
    let data = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
      filter: "THISMONTH",
    };

    backend.loadTransactions(data).then((r) => {
      console.log(r);
      setTimeout(() => {
        setLoading(false);
        // refRBSheet.current.open();
      }, 100);

      if (r.error == "False") {
        console.log(r);
        let finalObj = {};
        let totalbrokerage = 0;
        let totalpf = 0;
        r.data.forEach((transaction) => {
          const date = moment(transaction.date_created).format("DD-MMM-YY");

          totalbrokerage =
            parseFloat(totalbrokerage) + parseFloat(transaction.brokerage_amt);
          totalpf = parseFloat(totalpf) + parseFloat(transaction.profit_loss);
          if (finalObj[date]) {
            finalObj[date].push(transaction);
          } else {
            finalObj[date] = [transaction];
          }
        });

        // SetTransactions(finalObj);
        SetTransactions(r.data);
        SetTransactions2(r.data);
        SetProfitloss(totalpf);
        SetBrokerage(totalbrokerage);
        console.log(transaction);
        console.log("transaction top wala");
      }
      // console.log(r);
    });
  };
  const loadTransactionfilter = () => {
    console.log("inside the transaction fileter");
    setLoading(true);
    let data = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
      from: fromDate,
      to: toDate,
    };

    backend.loadTransactions(data).then((r) => {
      // refRBSheet.current.close();
      console.log("inside one");
      setTimeout(() => {
        setLoading(false);
        // refRBSheet.current.open();
      }, 500);

      if (r.error == "False") {
        console.log("inside two");
        console.log(r);
        SetTransactions(r.data);
        console.log(transaction);
        let finalObj = {};
        let totalbrokerage = 0;
        let totalpf = 0;
        r.data.forEach((transaction) => {
          const date = moment(transaction.date_created).format("DD-MMM-YY");
          totalbrokerage =
            parseFloat(totalbrokerage) + parseFloat(transaction.brokerage_amt);
          totalpf = parseFloat(totalpf) + parseFloat(transaction.profit_loss);
          if (finalObj[date]) {
            finalObj[date].push(transaction);
          } else {
            finalObj[date] = [transaction];
          }
        });

        SetTransactions(finalObj);
        SetTransactions(r.data);

        SetProfitloss(totalpf);
        SetBrokerage(totalbrokerage);
      }
      // console.log(r);
    });
  };
  console.log("position[0]");

  const tableRef = useRef(null);
  const handleKeyPress = useCallback((event) => {
    console.log(`key pressed: ${event.key}`);
    if (event.key === "ArrowUp") {
      if (
        transaction[transaction.indexOf(transactionSelected) - 2] !== undefined
      ) {
        setTransactionSelected(
          transaction[transaction.indexOf(transactionSelected) - 1]
        );
      }

      // let targetRowId = 0;
      // console.log("transaction", transaction[0]);
      // console.log(
      //   typeof transaction[transaction.indexOf(transactionSelected) - 1]
      // );
      // if (
      //   transaction[transaction.indexOf(transactionSelected) - 1] === undefined
      // ) {
      //   if (transaction[0] !== undefined) {
      //     targetRowId = transaction[0].id;
      //     console.log("Undefained!");
      //     setTransactionSelected(transaction[0]);
      //   }
      // }
      // if (
      //   transaction[transaction.indexOf(transactionSelected) - 1] !== undefined
      // ) {
      //   targetRowId = parseInt(
      //     transaction[transaction.indexOf(transactionSelected) - 1].id
      //   );
      //   console.log("Not Defained!");
      // }

      if (
        transaction[transaction.indexOf(transactionSelected) - 1] !== undefined
      ) {
        var index = transaction.findIndex(
          (x) =>
            x.id == transaction[transaction.indexOf(transactionSelected) - 1].id
        );
        if (transaction.length - index > 5) {
          if (
            transaction[transaction.indexOf(transactionSelected) - 5] !==
            undefined
          ) {
            var constdata = document.getElementById(
              "trans" +
                transaction[transaction.indexOf(transactionSelected) - 5].id
            );
            constdata.scrollIntoView();
          }
        }
      }
    }
    if (event.key === "ArrowDown") {
      console.log(transaction);
      console.log("position");

      if (transaction.indexOf(transactionSelected) === transaction.length - 1) {
        return false;
      } else {
        setTransactionSelected(
          transaction[transaction.indexOf(transactionSelected) + 1]
        );
      }

      if (
        transaction[transaction.indexOf(transactionSelected) + 1] !== undefined
      ) {
        var index = transaction.findIndex(
          (x) =>
            x.id == transaction[transaction.indexOf(transactionSelected) + 1].id
        );
        if (index > 5) {
          var constdata = document.getElementById(
            "trans" +
              transaction[transaction.indexOf(transactionSelected) - 4].id
          );
          constdata.scrollIntoView();
        }
      }
      // const targetRow = tableRef.current.querySelector(`tr:nth-child(${targetRowIndex + 1})`);
      // console.log(
      //   "transaction is also here",
      //   transaction[transaction.indexOf(transactionSelected) + 1].id
      // );
      let targetRowId = 0;
      console.log("transaction", transaction[0]);
      console.log(
        typeof transaction[transaction.indexOf(transactionSelected) + 1]
      );
      if (
        transaction[transaction.indexOf(transactionSelected) + 1] === undefined
      ) {
        if (transaction[0] !== undefined) {
          targetRowId = transaction[0].id;
          console.log("Undefained!");
          setTransactionSelected(transaction[0]);
        }
      }
      if (
        typeof transaction[transaction.indexOf(transactionSelected) + 1] !==
        "undefined"
      ) {
        targetRowId = parseInt(
          transaction[transaction.indexOf(transactionSelected) + 1].id
        );
        console.log("Not Defained!");
      }
      // if (targetRowId === undefined) {
      //   console.log(" null")
      // }
      // if (targetRowId !== undefined) {
      //   console.log("not null")
      // }

      if (typeof targetRowId === undefined) {
        targetRowId = 1;
      }
      const targetRow = document.querySelector("#divs" + targetRowId);

      // Scroll the table to bring the target row into view
      if (targetRow) {
        targetRow.scrollIntoView({ behavior: "smooth", block: "nearest" });

        console.log("inside the id");
      }
    }
  });
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);
  const onclickevent = useCallback((item) => {
    setTransactionSelected(item);
  });

  const filtersegement = (e) => {
    var data = [];
    if (e == "1" || e == 1) {
      data = transaction2.filter((x) => x.script_type == "fut");
      SetTransactions(data);
    }
    if (e == "2" || e == 2) {
      data = transaction2.filter((x) => x.script_type == "mcx");
      SetTransactions(data);
    }
    if (e == "3" || e == 3) {
      data = transaction2.filter((x) => x.script_type == "fo");
      SetTransactions(data);
    }
    if (e == "0" || e == 0) {
      data = transaction2;
      SetTransactions(data);
    }
  };

  return (
    <div className="Profile_box" style={{ fontSize: 13 }}>
      <div
        style={{
          height: 40,
          background: "black",
          display: "flex",
          color: "white",
          fontWeight: "bold",
          alignItems: "center",
          padding: 5,
          boxSizing: "border-box",
        }}
      >
        {/* <div style={{ width: 50 }}>
          <img style={{ height: 30 }} src={logo} alt="" />
        </div> */}
        <div style={{ flex: 1, display: "flex" }}>Transaction</div>
        <div className="tra_filter">
          <input
            onChange={(e) => setFromDate(e.target.value)}
            type="date"
            id="start"
            name="trip-start"
            value={fromDate}
            min="2023-01-01"
            max={moment().format("YYYY-MM-DD")}
            style={{
              height: 25,
              padding: 0,
              paddingLeft: 10,
              paddingRight: 10,
            }}
          />
          {/* <input
            type="date"
            name="from"
            
            value={fromDate}
            onChange={(e) => {
              setFromDate(e.target.value);
              console.log(fromDate);
            }}
            id=""
          /> */}
          <input
            type="date"
            id="start"
            name="trip-start"
            onChange={(e) => setToDate(e.target.value)}
            value={toDate}
            min="2023-01-01"
            max={moment().format("YYYY-MM-DD")}
            style={{
              height: 25,
              padding: 0,
              paddingLeft: 10,
              paddingRight: 10,
            }}
          />
          {/* <button className="Clear_btn_tra" onClick={()=>{setFromDate("");setToDate("")}}>Clear</button>
           */}
        </div>
        <div style={{ width: 60, marginRight: 10 }}>
          <button onClick={loadTransactionfilter}>FILTER</button>
        </div>
        <div style={{ width: 60, marginRight: 10 }}>
          <button
            onClick={() => {
              setFromDate(moment().format("YYYY-MM-DD"));
              setToDate(moment().format("YYYY-MM-DD"));
              loadTransaction();
            }}
          >
            CLEAR
          </button>
        </div>
        <div style={{ width: 60 }}>
          <button onClick={props.closeTransaction}>Close</button>
        </div>
      </div>

      <TransactionTopCard
        transactions={transaction}
        brokerage={brokerage}
        profitloss={profitloss}
        filtersegement={filtersegement}
      />
      <div
        style={{
          maxHeight: (window.innerHeight / 100) * 70,
          overflowY: "scroll",
        }}
      >
        <table className="tablenew">
          <thead>
            <tr
              style={{
                background: NewTheme.MainColor,

                height: 30,
                alignItems: "center",
                color: "white",
                fontWeight: "bold",
                borderStyle: "solid",
                borderColor: "black",
                borderWidth: 0,
                borderTopWidth: 1,
                fontSize: 12,
              }}
            >
              <th>EXCHANGE</th>
              <th>SYMBOL</th>
              <th>EXPIRY</th>
              <th>QTY</th>
              <th>B/S</th>
              <th>RATE</th>
              <th>P/L</th>
              <th>Brokerage</th>
              <th>TYPE</th>
              <th>Volume</th>
              <th>Date/Time</th>
            </tr>
          </thead>
          <tbody
            style={
              {
                // height: 30,
                // maxHeight: 30,
              }
            }
          >
            {transaction &&
              transaction.map((item, index) => (
                <Transactioncard
                  key={item.id + item.script_id}
                  PosClose={() => PosClose(item)}
                  data={item}
                  select={(i) => setTransactionSelected(item)}
                  profileSelected={transactionSelected}
                  total={transaction.length}
                />
              ))}

            {transaction.length > 0 ? null : <div>NO RECORDS FOUND</div>}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Transaction;
