import React, { useState } from "react";
import { NewTheme } from "../Theme/Theme";
import { useSelector } from "react-redux";

export default function TopCard(props) {
  const total = useSelector((state) => state.total);
  const [multiplier, setmultiplier] = useState(props.multiplier);

  var margin = 0;

  margin = parseFloat(props.balance) + parseFloat(total);

  margin = margin * props.multiplier;

  margin = parseFloat(margin) - parseFloat(props.limit);

  var balance = parseFloat(parseFloat(props.balance) + total).toFixed(1);

  const numDifferentiation = (val) => {
    if (val >= 10000000 || val <= -10000000) {
      val = (Math.abs(val) / 10000000).toFixed(2) + " Cr";
    } else if (val >= 100000 || val <= -100000) {
      val = (Math.abs(val) / 100000).toFixed(2) + " Lac";
    } else if (val >= 1000 || val <= -1000) {
      val = (Math.abs(val) / 1000).toFixed(2) + " K";
    }
    return (val < 0 ? "-" : "") + val;
  };
  return (
    <div
      style={{
        height: "auto",
        background: NewTheme.lightmain,
        display: "flex",
        fontWeight: "bold",
        fontSize: 13,
      }}
    >
      <div
        style={{
          flex: 3,
          borderStyle: "solid",
          borderWidth: 0,
          boxSizing: "border-box",
          padding: 5,
        }}
      ></div>{" "}
      <div
        style={{
          flex: 1,
          borderStyle: "solid",
          borderWidth: 0,
          boxSizing: "border-box",
          padding: 10,
          borderRightWidth: 0,
          borderLeftWidth: 1,
          borderColor: NewTheme.MainColor,
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>Live P/L</div>
          <div>{props.loading ? 0 : parseFloat(total).toFixed(2)}</div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>Booked P/L</div>
          <div>
            {" "}
            {props.loading
              ? 0
              : props.booked == null
              ? 0
              : parseFloat(props.booked).toFixed(2)}
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>Balance</div>
          <div>
            {" "}
            {props.balance == null ? 0 : parseFloat(balance).toFixed(2)}
          </div>
        </div>
      </div>
      <div
        style={{
          flex: 1,
          borderStyle: "solid",
          borderWidth: 0,
          borderLeftWidth: 1,
          boxSizing: "border-box",
          padding: 10,
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>Fut Margin</div>
          <div>
            {" "}
            {numDifferentiation(
              balance * props?.multiplier?.fut_multiplier - props.limit
            )}
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>Mcx Margin</div>
          <div>
            {" "}
            {numDifferentiation(
              balance * props?.multiplier?.mcx_multiplier - props.limit
            )}
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>Option Margin</div>
          <div>
            {" "}
            {numDifferentiation(
              balance * props?.multiplier?.fo_multiplier - props.limit
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
