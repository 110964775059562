// import AsyncStorage from '@react-native-async-storage/async-storage';

//52.220.3.7
const api_link = "http://3.1.114.161:10006";
const accesskay =
  "$2a$12$JiCb4MMsXIbV8v/qH1V3N..pPlCJ7IZe66EzhxQu21iWJ.ZU5y/mC";
const api_link_trade = "http://3.1.114.161:10002";
const access_key = "4c68cea7e58591b579fd074bcdaff740";

const access_key_php =
  "040ec1ee950ffc53291f6df0ffc30325-a1d41284cbad95a80f076eed39859381.77004ea213d5fc71acf74a8c9c6795fb";

const api_link2 = "https://iosapi.dreamtrade.biz/user";

const trade_link = "https://directtrade.dreamtrade.biz/";

export default class Backend {
  login(data) {
    return fetch(api_link2 + "/userlogin", {
      method: "POST",
      headers: {
        // accesskey: accesskay,
        "Content-Type": "application/json",
        // accept: 'application/json',
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_rules(data) {
    return fetch(api_link2 + "/load_rules", {
      method: "POST",
      headers: {
        // accesskey: accesskay,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  edit_pending(data) {
    return fetch(api_link2 + "/edit_pending", {
      method: "POST",
      headers: {
        // accesskey: accesskay,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  cancel_pending(data) {
    return fetch(api_link2 + "/cancel_pending", {
      method: "POST",
      headers: {
        // accesskey: accesskay,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  get_demo(data) {
    return fetch(api_link2 + "/get_demo", {
      method: "POST",
      headers: {
        // accesskey: accesskay,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  loadPosition(data) {
    return fetch(api_link2 + "/loadposition", {
      method: "POST",
      headers: {
        // accesskey: accesskay,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  sq_off_position(data) {
    return fetch(api_link2 + "/sq_off_position", {
      method: "POST",
      headers: {
        // accesskey: accesskay,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  loadTransactions(data) {
    return fetch(api_link2 + "/loadtransaction", {
      method: "POST",
      headers: {
        // accesskey: accesskay,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  loadprofile(data) {
    return fetch(api_link2 + "/loadprofile", {
      method: "POST",
      headers: {
        // accesskey: accesskay,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  loadledger(data) {
    return fetch(api_link2 + "/loadledger", {
      method: "POST",
      headers: {
        // accesskey: accesskay,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  loadrejection(data) {
    return fetch(api_link2 + "/loadrejection", {
      method: "POST",
      headers: {
        // accesskey: accesskay,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  changepassword(data) {
    return fetch(api_link2 + "/changepassword", {
      method: "POST",
      headers: {
        // accesskey: accesskay,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  loadpending(data) {
    return fetch(api_link2 + "/loadpending", {
      method: "POST",
      headers: {
        // accesskey: accesskay,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  loadwatchlist(data) {
    return fetch(api_link2 + "/loadwatchlist", {
      method: "POST",
      headers: {
        // accesskey: accesskay,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  searchscript(data) {
    return fetch(api_link2 + "/searchscript", {
      method: "POST",
      headers: {
        // accesskey: accesskay,
        "Content-Type": "application/json",
        // accept: 'application/json',
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  addscript(data) {
    return fetch(api_link2 + "/addscript", {
      method: "POST",
      headers: {
        // accesskey: accesskay,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  removescript(data) {
    return fetch(api_link2 + "/removescript", {
      method: "POST",
      headers: {
        // accesskey: accesskay,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  loadnews(data) {
    return fetch(api_link2 + "/loadnews", {
      method: "POST",
      headers: {
        // accesskey: accesskay,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  trade(data) {
    return fetch(trade_link + "directtrade", {
      method: "POST",
      headers: {
        accesskey: accesskay,
        authorization: "123",
        "Content-Type": "application/json",
        accept: "application/json",
        clienttoken: data.token,
      },
      body: JSON.stringify(data),
    }).then((response) => response.json());
  }

  pending(data) {
    return fetch(trade_link + "pending", {
      method: "POST",
      headers: {
        accesskey: accesskay,
        authorization: "123",
        "Content-Type": "application/json",
        accept: "application/json",
        clienttoken: data.token,
      },
      body: JSON.stringify(data),
    }).then((response) => response.json());
  }

  load_position_overview(data) {
    return fetch(api_link2 + "/loadpositionoverview", {
      method: "POST",
      // headers: {
      //   accesskey: accesskay,
      //   'Content-Type': 'application/json',
      //   accept: 'application/json',
      // },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  sqoffposition(data) {
    return fetch(trade_link + "sqoffposition", {
      method: "POST",
      headers: {
        // accesskey: accesskay,
        authorization: "123",
        "Content-Type": "application/json",
        accept: "application/json",
        clienttoken: data.token,
      },
      body: JSON.stringify(data),
    }).then((response) => response.json());
  }

  rearrangewatchlist(data) {
    return fetch(api_link2 + "/rearrangewatchlist", {
      method: "POST",
      headers: {
        accesskey: accesskay,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  // register(data) {
  //   return fetch(
  //     'http://apis.utrade.today/UserPhp/Routes/SignUp/dg_signup.php',
  //     {
  //       method: 'POST',
  //       body: JSON.stringify({
  //         sign_up_client: '1',
  //         data: data,
  //         access_key: access_key_php,
  //       }),
  //     },
  //   ).then(response => response.json());
  // }

  // sqOff_all(data) {
  //   return fetch('http://apis.utrade.today/UserPhp/user_api.php', {
  //     method: 'POST',
  //     body: JSON.stringify({
  //       sq_off_all: '1',
  //       access_key: access_key_php,
  //       data: data,
  //     }),
  //   })
  //     .then(response => response.json())
  //     .catch(err => console.log(err));
  // }

  // getDemo() {
  //   return fetch('http://apis.utrade.today/DemoPhp/add_demo.php', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       access_key: 'Demo>9083r702r86327r0626r923yr923078',
  //       get_demo_acc: '1',
  //     }),
  //   }).then(response => response.json());
  // }

  // loadTransactions_Date_wise(data) {
  //   return fetch('http://apis.bbt.market/UserPhp/user_api.php', {
  //     method: 'POST',
  //     body: JSON.stringify({
  //       load_transaction_datewise: '1',
  //       access_key: access_key_php,
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }

  // loadTransactions(data) {
  //   return fetch('http://apis.utrade.today/UserPhp/user_api.php', {
  //     method: 'POST',
  //     body: JSON.stringify({
  //       load_transaction_all: '1',
  //       access_key: access_key_php,
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }
  // load_position_transaction(data) {
  //   return fetch('http://apis.utrade.today/UserPhp/user_api.php', {
  //     method: 'POST',
  //     body: JSON.stringify({
  //       load_position_transaction: '1',
  //       access_key: access_key_php,
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }

  // findserver(data) {
  //   return fetch(api_link + '/findserver', {
  //     method: 'POST',
  //     headers: {
  //       accesskey: accesskay,
  //       'Content-Type': 'application/json',
  //       accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }
  // load_home(data) {
  //   return fetch(api_link + '/load_home', {
  //     method: 'POST',
  //     headers: {
  //       auth: data.token,
  //       id: data.id,
  //       accesskey: accesskay,
  //       'Content-Type': 'application/json',
  //       accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }
  // loadWatchlist(data) {
  //   return fetch(api_link + '/watchlist', {
  //     method: 'POST',
  //     headers: {
  //       auth: data.token,
  //       id: data.id,
  //       accesskey: accesskay,
  //       'Content-Type': 'application/json',
  //       accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }
  // watchlist_position(data) {
  //   return fetch(api_link + '/watchlistposistion', {
  //     method: 'POST',
  //     headers: {
  //       auth: data.token,
  //       id: data.id,
  //       accesskey: accesskay,
  //       'Content-Type': 'application/json',
  //       accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }
  // edit_Watchlist(data) {
  //   return fetch(api_link + '/watchlistedit', {
  //     method: 'POST',
  //     headers: {
  //       auth: data.token,
  //       id: data.id,
  //       accesskey: accesskay,
  //       'Content-Type': 'application/json',
  //       accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }
  // delete_script(data) {
  //   return fetch(api_link + '/watchlistremove', {
  //     method: 'POST',
  //     headers: {
  //       auth: data.token,
  //       id: data.id,
  //       accesskey: accesskay,
  //       'Content-Type': 'application/json',
  //       accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }
  // find_script(data) {
  //   return fetch(api_link + '/watchlistfind', {
  //     method: 'POST',
  //     headers: {
  //       auth: data.token,
  //       id: data.id,
  //       accesskey: accesskay,
  //       'Content-Type': 'application/json',
  //       accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }
  // add_script(data) {
  //   return fetch(api_link + '/watchlistadd', {
  //     method: 'POST',
  //     headers: {
  //       auth: data.token,
  //       id: data.id,
  //       accesskey: accesskay,
  //       'Content-Type': 'application/json',
  //       accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }

  // loadPending(data) {
  //   return fetch(api_link + '/loadpending', {
  //     method: 'POST',
  //     headers: {
  //       auth: data.token,
  //       id: data.id,
  //       accesskey: accesskay,
  //       'Content-Type': 'application/json',
  //       accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }

  // loadProfile(data) {
  //   return fetch(api_link + '/loadprofile', {
  //     method: 'POST',
  //     headers: {
  //       auth: data.token,
  //       id: data.id,
  //       accesskey: accesskay,
  //       'Content-Type': 'application/json',
  //       accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }

  // load_ledger(data) {
  //   return fetch(api_link + '/load_ledger', {
  //     method: 'POST',
  //     headers: {
  //       auth: data.token,
  //       id: data.id,
  //       accesskey: accesskay,
  //       'Content-Type': 'application/json',
  //       accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }

  // loadRejection(data) {
  //   return fetch(api_link + '/loadrejecting', {
  //     method: 'POST',
  //     headers: {
  //       auth: data.token,
  //       id: data.id,
  //       accesskey: accesskay,
  //       'Content-Type': 'application/json',
  //       accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }

  // loadMessage(data) {
  //   return fetch(api_link + '/loadnews', {
  //     method: 'POST',
  //     headers: {
  //       auth: data.token,
  //       id: data.id,
  //       accesskey: accesskay,
  //       'Content-Type': 'application/json',
  //       accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }

  // chnage_password(data) {
  //   return fetch(api_link + '/forgate', {
  //     method: 'POST',
  //     headers: {
  //       auth: data.token,
  //       id: data.id,
  //       accesskey: accesskay,
  //       'Content-Type': 'application/json',
  //       accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }

  // loadLoginHistory(data) {
  //   return fetch(api_link + '/loginhistory', {
  //     method: 'POST',
  //     headers: {
  //       auth: data.token,
  //       id: data.id,
  //       accesskey: accesskay,
  //       'Content-Type': 'application/json',
  //       accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }

  // cancelPending(data) {
  //   return fetch(api_link + '/cancelpending', {
  //     method: 'POST',
  //     headers: {
  //       auth: data.token,
  //       id: data.id,
  //       accesskey: accesskay,
  //       'Content-Type': 'application/json',
  //       accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }

  // trade_api(data) {
  //   return fetch(api_link_trade + '/trade', {
  //     method: 'POST',
  //     headers: {
  //       accesskey: accesskay,
  //       auth: data.token,
  //       id: data.id,
  //       'content-type': 'application/json',
  //       Accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       accesskey: access_key,
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }
  // pending_api(data) {
  //   return fetch(api_link_trade + '/pending', {
  //     method: 'POST',
  //     headers: {
  //       accesskey: accesskay,
  //       auth: data.token,
  //       id: data.id,
  //       'content-type': 'application/json',
  //       Accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       accesskey: access_key,
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }
  // pending_api_edit(data) {
  //   return fetch(api_link_trade + '/pending_edit', {
  //     method: 'POST',
  //     headers: {
  //       accesskey: accesskay,
  //       auth: data.token,
  //       id: data.id,
  //       'content-type': 'application/json',
  //       Accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       accesskey: access_key,
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }
  // SqOffPosition(data) {
  //   return fetch(api_link_trade + '/sq_all', {
  //     method: 'POST',
  //     headers: {
  //       accesskey: accesskay,
  //       auth: data.token,
  //       id: data.id,
  //       'content-type': 'application/json',
  //       Accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       accesskey: access_key,
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }
  // RollOverPosition(data) {
  //   return fetch(api_link + '/rollover', {
  //     method: 'POST',
  //     headers: {
  //       accesskey: accesskay,
  //       auth: data.token,
  //       id: data.id,
  //       'content-type': 'application/json',
  //       Accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       accesskey: access_key,
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }

  // getNotification(data) {
  //   return fetch(api_link + '/get_notification', {
  //     method: 'POST',
  //     headers: {
  //       accesskey: accesskay,
  //       auth: data.token,
  //       id: data.id,
  //       'content-type': 'application/json',
  //       Accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       accesskey: access_key,
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }
  // UpdateNotifications(data) {
  //   return fetch(api_link + '/update_notification', {
  //     method: 'POST',
  //     headers: {
  //       accesskey: accesskay,
  //       auth: data.token,
  //       id: data.id,

  //       'content-type': 'application/json',
  //       Accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       accesskey: access_key,
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }

  // cpassword(data) {
  //   return fetch(api_link + '/cpassword', {
  //     method: 'POST',
  //     headers: {
  //       accesskey: accesskay,
  //       auth: data.token,
  //       id: data.id,

  //       'content-type': 'application/json',
  //       Accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       accesskey: access_key,
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }
  // sq_all(data) {
  //   return fetch(api_link + '/sq_all', {
  //     method: 'POST',
  //     headers: {
  //       accesskey: accesskay,
  //       auth: data.token,
  //       id: data.id,

  //       'content-type': 'application/json',
  //       Accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       accesskey: access_key,
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }
  // sq_all_user(data) {
  //   return fetch(api_link + '/sq_all_user', {
  //     method: 'POST',
  //     headers: {
  //       accesskey: accesskay,
  //       auth: data.token,
  //       id: data.id,

  //       'content-type': 'application/json',
  //       Accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       accesskey: access_key,
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }

  // loadbank(data) {
  //   return fetch('http://apis.utrade.today/UserPhp/user_api.php', {
  //     method: 'POST',
  //     headers: {
  //       accesskey: access_key_php,
  //       auth: data.token,
  //       id: data.id,
  //       'content-type': 'application/json',
  //       Accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       load_bank: '1',
  //       access_key: access_key_php,
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }
  // withdraw_amt(data) {
  //   return fetch('http://apis.utrade.today/UserPhp/user_api.php', {
  //     method: 'POST',
  //     headers: {
  //       accesskey: access_key_php,
  //       auth: data.token,
  //       id: data.id,
  //       'content-type': 'application/json',
  //       Accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       create_withdraw: '1',
  //       access_key: access_key_php,
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }
  // loadDeposit(data) {
  //   return fetch('http://apis.utrade.today/UserPhp/user_api.php', {
  //     method: 'POST',
  //     headers: {
  //       accesskey: access_key_php,
  //       auth: data.token,
  //       id: data.id,
  //       'content-type': 'application/json',
  //       Accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       load_deposit: '1',
  //       access_key: access_key_php,
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }
  // loadWithdrawal(data) {
  //   return fetch('http://apis.utrade.today/UserPhp/user_api.php', {
  //     method: 'POST',
  //     headers: {
  //       accesskey: access_key_php,
  //       auth: data.token,
  //       id: data.id,
  //       'content-type': 'application/json',
  //       Accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       load_withdraw: '1',
  //       access_key: access_key_php,
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }
  // cancelWithdrawal(data) {
  //   return fetch('http://apis.utrade.today/UserPhp/user_api.php', {
  //     method: 'POST',
  //     headers: {
  //       accesskey: access_key_php,
  //       auth: data.token,
  //       id: data.id,
  //       'content-type': 'application/json',
  //       Accept: 'application/json',
  //     },
  //     body: JSON.stringify({
  //       cancel_withdraw: '1',
  //       access_key: access_key_php,
  //       data: data,
  //     }),
  //   }).then(response => response.json());
  // }
}
