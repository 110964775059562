import { React, useState, useEffect, useCallback } from "react";

import logo from "../logo.svg";
import Backend from "../Backend";
import './watchlist.css'
import { NewTheme } from "./Theme/Theme";
const backend = new Backend();

const Rejection = (props) => {
  const [loading, setLoading] = useState(false);
  const [rejection, setRejection] = useState([]);

  const [rejectionSelected, setRejectionSelected] = useState("");

  useEffect(() => {
    loadRejection();
  }, []);

  const loadRejection = () => {
    setLoading(true);
    let data = {
      // server: localStorage.getItem("server"),
      // id: localStorage.getItem("client"),
      // token: localStorage.getItem("token"),
      // ledger_id: localStorage.getItem("client"),
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
    };
    try {
      backend
        .loadrejection(data)
        .then((r) => {
          console.log("r");
          console.log(r);
          setLoading(false);
          // console.log(r);
          if (r.error == "False") {
            setRejection(r.data);
            // setPosition(r.position);
            console.log("HIhi me khud");
            console.log(rejection);
            console.log("__-___");
            // setPosition_Filter(r.position);
            // setBalance(r.balance);
            // setBooked(r.booked);
            // setBrokerage(r.brokerage);
            // setlimit(r.limit);
            // setmultiplier(r.multiplier);
            // props.clear_pf();
          }
          if (r.error == "True") {
            console.log("error hy bhai");
          } else {
            // alert(r.message);
            console.log("not found");
          }
        })
        .then((e) => {
          console.log("error here");
        })
        .catch((e) => {
          console.log("Failed to Fetch");
        });
    } catch(e) {
      console.log("Error",e);
    }
  };
  console.log("position[0]");

  const handleKeyPress = useCallback((event) => {
    console.log(`key pressed: ${event.key}`);
    if (event.key === "ArrowUp") {
      console.log(rejection);
      console.log("position");
      setRejectionSelected(rejection[rejection.indexOf(rejectionSelected) - 1]);
    }
    if (event.key === "ArrowDown") {
      console.log(rejection);
      console.log("position");
      setRejectionSelected(rejection[rejection.indexOf(rejectionSelected) + 1]);
    }
  });
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);
  const onclickevent = useCallback((item) => {
    setRejectionSelected(item);
  });

  console.log();
  return (
    <div
    className="Profile_box"
    style={{ background: NewTheme.lightmain, fontSize: 13 }}
  >
    <div
      id="SELCTFOCUS"
      style={{
        height: 30,
        background: "black",
        display: "flex",
        color: "white",
        fontWeight: "bold",
        alignItems: "center",
        padding: 5,
        boxSizing: "border-box",
      }}
    >
      {/* <div style={{ width: 50 }}>
        <img style={{ height: 25 }} src={logo} alt="" />
      </div> */}
      <div style={{ flex: 1, display: "flex" }}>REJECTION LOGS</div>
      <div style={{ width: 60 }}>
        <button onClick={props.closeRejection}>Close</button>
      </div>
    </div>
      <div
        style={{
          maxHeight: (window.innerHeight / 100) * 90,
          overflowY: "scroll",
        }}
      >
        <table className="tablenew">
          <thead>
            <tr  style={{
              background: NewTheme.MainColor,
              height: 30,
              alignItems: "center",
              color: "white",
              fontWeight: "bold",
              borderStyle: "solid",
              borderColor: "black",
              borderWidth: 0,
              borderTopWidth: 1,
              fontSize: 12,
            }}>
              <th>SYMBOL</th>
              <th>TYPE</th>
              <th>QTY</th>
              <th>MESSAGE</th>
              <th>DATE</th>
            </tr>
          </thead>
          <tbody
            // style={{
            //   height: 20 + "!important",
            //   overflowY: "scroll",
            // }}

            // style={{
            //   "height: 10 + !important, overflow: scroll"
            // // height: 10;  overFlow: "scroll"
            // }}
          >
            {rejection &&
              rejection.map((item, index) => (
                <tr
                  id={item.id}
                  onClick={() => onclickevent(item)}
                  style={{
                    backgroundColor: item === rejectionSelected ? "#fae7cd" : "",
                  }}
                >
                  <td>{item.symbol}</td>
                  <td>{item.type ==0?"BUY":"SELL"}</td>
                  <td>{item.qty}</td>
                  <td>{JSON.parse(item.rejection_array)["msg"]}</td>
                  <td>{item.date_created}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Rejection;
