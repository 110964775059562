import React from "react";
import { NewTheme } from "../../Theme/Theme";
import { useSelector } from "react-redux";
// import logo from "../../logo.svg";

export default function TopCardPos(props) {
  const total = useSelector((state) => state.total);

  // alert(total);
  var margin = 0;

  margin = parseFloat(props.balance) + parseFloat(total);

  margin = margin * props.multiplier;

  margin = parseFloat(margin) - parseFloat(props.limit);

  var balance = parseFloat(parseFloat(props.balance) + total).toFixed(1);

  const numDifferentiation = (val) => {
    if (val >= 10000000 || val <= -10000000) {
      val = (Math.abs(val) / 10000000).toFixed(2) + " Cr";
    } else if (val >= 100000 || val <= -100000) {
      val = (Math.abs(val) / 100000).toFixed(2) + " Lac";
    } else if (val >= 1000 || val <= -1000) {
      val = (Math.abs(val) / 1000).toFixed(2) + " K";
    }
    return (val < 0 ? "-" : "") + val;
  };

  return (
    <div
      style={{
        height: 170,
        // background: "red",
        zIndex: 999,
        width: "90%",
        marginTop: 20,
        display: "flex",
        flexDirection: "column",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: NewTheme.MainColor,
      }}
    >
      <div
        style={{
          width: "100%",
          flex: 1,
          background: "white",
          borderStyle: "solid",
          borderWidth: 0,
          borderBottomWidth: 1,

          display: "flex",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderStyle: "solid",
            borderWidth: 0,
            borderRightWidth: 1,
            height: "80%",
            alignSelf: "center",
          }}
        >
          <div style={{ fontWeight: "bold", fontSize: 11 }}>BOOKED</div>
          <div
            style={{
              fontWeight: "bold",
              fontSize: 16,
              color: props.booked >= 0 ? NewTheme.MainColor : NewTheme.redcolor,
            }}
          >
            {props.loading
              ? 0
              : props.booked == null
              ? 0
              : parseFloat(props.booked).toFixed(2)}
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderStyle: "solid",
            borderWidth: 0,
          }}
        >
          <div style={{ fontWeight: "bold", fontSize: 11 }}>BALANCE</div>
          <div
            style={{
              fontWeight: "bold",
              fontSize: 16,
            }}
          >
            {props.balance == null ? 0 : balance}
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          flex: 1,
          background: "white",
          borderStyle: "solid",
          borderWidth: 0,
          borderBottomWidth: 1,

          display: "flex",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderStyle: "solid",
            borderWidth: 0,
            borderRightWidth: 1,
            height: "80%",
            alignSelf: "center",
          }}
        >
          <div style={{ fontWeight: "bold", fontSize: 11 }}>FUT</div>
          <div
            style={{
              fontWeight: "bold",
              fontSize: 16,
            }}
          >
            {numDifferentiation(
              balance * props?.multiplier?.fut_multiplier - props.limit
            )}
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderStyle: "solid",
            borderWidth: 0,
            borderRightWidth: 1,
            height: "80%",
            alignSelf: "center",
          }}
        >
          <div style={{ fontWeight: "bold", fontSize: 11 }}>MCX</div>
          <div
            style={{
              fontWeight: "bold",
              fontSize: 16,
            }}
          >
            {numDifferentiation(
              balance * props?.multiplier?.mcx_multiplier - props.limit
            )}
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderStyle: "solid",
            borderWidth: 0,
            // borderRightWidth: 1,
          }}
        >
          <div style={{ fontWeight: "bold", fontSize: 11 }}>OPTION</div>
          <div
            style={{
              fontWeight: "bold",
              fontSize: 16,
            }}
          >
            {numDifferentiation(
              balance * props?.multiplier?.fo_multiplier - props.limit
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          flex: 1,
          background: "white",
          borderStyle: "solid",
          borderWidth: 0,
          //   borderBottomWidth: 1,

          display: "flex",
        }}
      >
        <div
          style={{
            flex: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderStyle: "solid",
            borderWidth: 0,
            borderRightWidth: 1,
            height: "80%",
            alignSelf: "center",
          }}
        >
          {localStorage.getItem("server").toUpperCase() == "TEST" ? (
            <div style={{ fontSize: 12, fontWeight: "bold", color: "red" }}>
              DEMO ACCOUNT
            </div>
          ) : null}
          <div style={{ fontWeight: "bold", fontSize: 11 }}>
            LIVE PROFIT LOSS
          </div>
          <div
            style={{
              fontWeight: "bold",
              fontSize: 20,
              color: total >= 0 ? NewTheme.greencolor : NewTheme.redcolor,
            }}
          >
            {props.loading ? 0 : parseFloat(total).toFixed(2)}
          </div>
        </div>

        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderStyle: "solid",
            borderWidth: 0,
            // borderRightWidth: 1,
          }}
        >
          <button
            onClick={() => props.sqoffall()}
            style={{
              height: 40,
              borderRadius: 5,
              background: NewTheme.MainColor,
              color: "white",
              fontWeight: "bold",
              borderWidth: 0,
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            SQ-OFF ALL
          </button>
        </div>
      </div>
    </div>
    // <div className="over_box">
    //   <div className="over_box_top">
    //     <button className="sqoff_btn">Sq Off All</button>
    //     <div className="detail_over_box">
    //       <p className="ob_live_text">Live P/L</p>
    //       <p className="ob_live_value">{"multiplier"} </p>
    //       <p className="ob_book_text">book P/L</p>
    //       <p className="ob_book_value">0 {"booked"} </p>
    //     </div>
    //   </div>
    //   <div className="over_box_bottom">
    //     <div className="balance_bar">
    //       <img src={logo} className="balance_bar_icon" alt="" />
    //       <p className="balance_bar_text">Balance</p>
    //       <p className="balance_bar_value">{parseFloat(balance).toFixed(1)}</p>
    //     </div>
    //     <div className="margin_bar">
    //       <img src={logo} className="margin_bar_icon" alt="" />
    //       <p className="margin_bar_text">margin</p>
    //       <p className="margin_bar_value">{"limit"}</p>
    //     </div>
    //   </div>
    // </div>
  );
}
