import { React, useState } from "react";
// import logo from '../logo.svg'
import logo from "../../Images/logo.png";
import "./Login.css";

import Backend from "../../Backend";
import { InputText } from "primereact/inputtext";
import { NewTheme } from "../../Theme/Theme";
const backend = new Backend();

const Login = () => {
  const [server, setserver] = useState("Hello");
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [demo, setDemo] = useState(false);

  const [imageExists, setImageExists] = useState(true);

  const getDemo = () => {
    setDemo(true);

    backend.get_demo().then((r) => {
      // setLoading(false);
      if (r.error == "False") {
        setTimeout(() => {
          login_demo(r.data.server, r.data.username, r.data.username);
          // setdemoscreen(false)
        }, 2500);

        setTimeout(() => {
          setDemo(false);
        }, 3000);
      } else {
        alert(r.message);
      }
    });
  };

  const login_demo = (a, b, c) => {
    // setLoading(true);
    let data = {
      un: b,
      pw: c,
      sr: a,
      fcm: "",
      ip: "",
      device: "webios",
      version: localStorage.getItem("version"),

      // fcm: fcmtoken,
      // ip: ip,
    };
    backend.login(data).then((r) => {
      console.log(r);

      // setLoading(false);
      if (r.error == "False") {
        localStorage.setItem("token", r.jwt);
        localStorage.setItem("rulesheet", "0");
        localStorage.setItem("server", r.sv);
        localStorage.setItem("client", r.users_id);
        localStorage.setItem("client_details", JSON.stringify(r.users));
        localStorage.setItem("IsLoggedIn", "1");

        localStorage.setItem("IsLoggedIn", "1");
        window.location = "/";
      } else {
        alert(r.message);
      }
    });
  };

  const login = () => {
    if (server == "") {
      window.alert("Enter Server");
    } else if (username == "") {
      window.alert("Enter Username");
    } else if (password == "") {
      window.alert("Enter Password");
    } else {
      // setLoading(true);
      let data = {
        un: username,
        pw: password,
        sr: server,
        // fcm: fcmtoken,
        // ip: ip,
        device: "webios",
        version: localStorage.getItem("version"),
      };
      backend.login(data).then((r) => {
        console.log(r);

        // setLoading(false);
        if (r.error == "False") {
          localStorage.setItem("token", r.jwt);
          localStorage.setItem("rulesheet", "0");
          // localStorage.setItem('rules', JSON.stringify(r.rules));
          localStorage.setItem("server", r.sv);
          localStorage.setItem("client", r.users_id);
          localStorage.setItem("client_details", JSON.stringify(r.users));
          localStorage.setItem("IsLoggedIn", "1");
          // props.token(r.jwt);
          // props.id(r.users_id);
          // props.server(r.sv);
          // props.isLogin(1);
          localStorage.setItem("IsLoggedIn", "1");
          window.location = "/";
        } else {
          alert(r.message);
        }
      });
    }
  };

  return (
    <div className="Login_Container">
      <div className="internal_login">
        <img src={logo} alt="" className="login_logo" />
        <div className="login_div">
          <div className="login_title">Server</div>

          {demo ? (
            <div
              style={{
                position: "fixed",
                height: "100%",
                width: "100%",
                top: 0,
                left: 0,
                background: "white",
                zIndex: 99999,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              PLEASE WAIT WE ARE CREATING DEMO ACCOUNT FOR YOU
            </div>
          ) : null}

          <div style={{ position: "relative" }}>
            <InputText
              style={{
                borderWidth: 0,
                padding: 0,
                height: 25,
              }}
              placeholder="ENTER SERVER"
              onChange={(e) => {
                setserver(e.target.value);
                setImageExists(true);
              }}
            />
            <div style={{ position: "absolute", top: -17, right: 0 }}>
              <img
                onError={() => setImageExists(false)}
                src={
                  imageExists
                    ? "https://img.dreamtrade.biz/" +
                      server.toLowerCase() +
                      ".gif?id=" +
                      new Date()
                    : logo
                }
                style={{ height: 40, width: 40 }}
              />
            </div>
          </div>
        </div>
        <div className="login_div">
          <div className="login_title">User Name</div>

          <InputText
            style={{ borderWidth: 0, padding: 0, height: 25 }}
            placeholder="Enter Username"
            onChange={(e) => setusername(e.target.value)}
          />
        </div>

        <div className="login_div">
          <div className="login_title">Password</div>

          <InputText
            type="password"
            style={{ borderWidth: 0, padding: 0, height: 25 }}
            placeholder="Enter your password"
            onChange={(e) => setpassword(e.target.value)}
          />
        </div>
        <button
          style={{ background: NewTheme.MainColor }}
          onClick={() => login()}
          className="login_btn"
        >
          Login
        </button>
        <p className="login_demo_text">
          Want To Login With Demo Account?{" "}
          <span
            onClick={getDemo}
            style={{
              color: NewTheme.MainColor,
              fontWeight: "bold",
            }}
          >
            Click Here
          </span>
        </p>
      </div>
    </div>
  );
};

export default Login;
