import { React, useState, useEffect } from "react";

import Backend from "../Backend";

import moment from "moment";

import logo from "../Images/Images/logo.png";
import ruppee from "../Images/Images/rupee.png";
import database from "../Images/Images/database.png";
import profile from "../Images/Images/profile-user.png";
import bank from "../Images/Images/bank.png";
import money from "../Images/Images/money.png";
import stock from "../Images/Images/stock-market.png";
import gold from "../Images/Images/gold-ingot.png";
import candle from "../Images/Images/candlestick.png";

const backend = new Backend();
const About = (props) => {

  const [loading, setLoading] = useState(false);
  const [profiledata, setProfiledata] = useState([]);
  const [brokerages, setBrokerages] = useState([]);
  const [multiplier, setMultiplier] = useState([]);

  useEffect(() => {
    loadprofile();
  }, []);
  const loadprofile = () => {
    setLoading(true);
    let data = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
    };

    backend.loadprofile(data).then((r) => {
      // alert(JSON.stringify(r))
      setTimeout(() => {
        setLoading(false);
        // refRBSheet.current.open();
      }, 500);
      // console.log(r);

      if (r.error == "False") {
        setProfiledata(r.data);
        setBrokerages(JSON.parse(r.data.brokerages));
        setMultiplier(JSON.parse(r.data.multiplier));
      }
      // console.log(r);
    });
  };

  return (
    <div className="AboutContainer">
      {/* <div className="Profile_header">
        <img src={logo} alt="" />
        <p>Account</p>
        <button onClick={props.closeAbout}>Close</button>
      </div> */}
      <div
      id="SELCTFOCUS"
      style={{
        height: 30,
        background: "black",
        display: "flex",
        color: "white",
        fontWeight: "bold",
        alignItems: "center",
        padding: 5,
        boxSizing: "border-box",
      }}
    >
      {/* <div style={{ width: 50 }}>
        <img style={{ height: 25 }} src={logo} alt="" />
      </div> */}
      <div style={{ flex: 1, display: "flex" }}>USER PROFILE</div>
      <div style={{ width: 60 }}>
        <button onClick={props.closeAbout}>Close</button>
      </div>
    </div>
      <div className="internal_about">
        {/* <div className="back_btn">
          <img src={logo} alt="" />
          <p className="back_text">Back</p>
        </div> */}
        <div className="left_box">
          <div className="col1">
            <div className="row1">
              <p className="box_title">Summary</p>
              <div className="item_box">
                <img src={ruppee} alt="" srcset="" />
                <p>Balance</p>
                <p>{parseFloat(profiledata.deposit).toFixed(2)}</p>
              </div>
              <div className="item_box">
                <img src={database} alt="" srcset="" />
                <p>Server</p>
                <p>{profiledata.server}</p>
              </div>

              <div className="item_box">
                <img src={profile} alt="" srcset="" />
                <p>USERNAME</p>
                <p>{profiledata.username}</p>
              </div>
            </div>
            <div className="row2">
              <div className="box_title">Account Details</div>
              <div className="item_box">
                <img src={profile} alt="" srcset="" />
                <p>A/C Date</p>
                <p>{moment(profiledata.date_created).format("DD-MMM-YY")}</p>
              </div>
              <div className="item_box">
                <img src={bank} alt="" srcset="" />
                <p>Total Limit</p>
                <p>{parseFloat(profiledata.total_limit).toFixed(0)}</p>
              </div>
              <div className="item_box">
                <img src={money} alt="" srcset="" />
                <p>Margin Blocked</p>
                <p>{parseFloat(profiledata.blocked_limit).toFixed(0)}</p>
              </div>
            </div>
          </div>
          <div className="col2">
            <div className="row1">
              <div className="box_title">Brokerage Details</div>
              <div className="item_box">
                <img src={stock} alt="" srcset="" />
                <p>Futures</p>
                <p>{brokerages.fut_brokerage * 100000 + "/CR"}</p>
              </div>
              <div className="item_box">
                <img src={gold} alt="" srcset="" />
                <p>MCX</p>
                <p>{brokerages.mcx_brokerage + "/LOT"}</p>
              </div>
              <div className="item_box">
                <img src={candle} alt="" srcset="" />
                <p>Options</p>
                <p>{brokerages.fo_brokerage + "/LOT"}</p>
              </div>
            </div>
            <div className="row2">
              <div className="box_title">Leverage Details</div>
              <div className="item_box">
                <img src={stock} alt="" srcset="" />
                <p>Futures</p>
                <p>{"X" + multiplier.fut_multiplier}</p>
              </div>
              <div className="item_box">
                <img src={gold} alt="" srcset="" />
                <p>MCX</p>
                <p>{"X" + multiplier.mcx_multiplier}</p>
              </div>
              <div className="item_box">
                <img src={candle} alt="" srcset="" />
                <p>Options</p>
                <p>{"X" + multiplier.fo_multiplier}</p>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default About;
