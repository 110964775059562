import { React, useState, useEffect, useCallback } from "react";
// import useWindowSize from 'usehooks-ts'

// import "../css/Profile.scss";
import logo from "../logo.svg";
import Backend from "../Backend";


import moment from 'moment';
import { NewTheme } from "./Theme/Theme";
import './watchlist.css'
import { queryByLabelText } from "@testing-library/react";
import PendingModel from "./PendingModel/PendingModel";

const backend = new Backend();

const Pending = (props) => {
  const [pending, SetPending] = useState([]);
  const [loading, setLoading] = useState(false);
  const [transaction,SetTransactions] = useState("")
  const [executed,SetExecuted] = useState("")
  const [rejected,Setrejected] = useState("")
  const [curruntstate,SetCurrentstate] = useState("")
  const [pendingSelected, setPendingSelected] = useState("");
  const [editmodel, setEditModel] = useState(false);
  

  useEffect(() => {
    loadpending();
  }, []);



  const CanclePending=(i) =>{
    if(i.status == 0){

    
var test = window.confirm("Are You Sure?","Want To Cancle Limit")
if(test){
  let data = {
    sv: localStorage.getItem("server"),
    id: localStorage.getItem("client"),
    jwt: localStorage.getItem("token"),
    pending_id: i.id,
  };

  backend.cancel_pending(data).then(r => {
    setTimeout(() => {
      setLoading(false);
      // refRBSheet.current.open();
    }, 500);
    // console.log(r);

    if (r.error == 'False') {
      window.alert( r.message);
      loadpending()
      // props.closesheet();
    } else {
      window.alert(r.message);
    }
  });
}
    }
  }



  const loadpending = () => {
    setLoading(true);
    let data = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
      filter: 'THISMONTH',
    };

    backend
      .loadpending(data)
      .then(r => {
        console.log("r")
        console.log(r)
        setTimeout(() => {
          setLoading(false);
          // refRBSheet.current.open();
        }, 500);
        // console.log(r);

        if (r.error == 'False') {
          let finalObj = {};
          let pending = 0;
          let executed = 0;
          let rejected = 0;
          SetTransactions(r.data);
          r.data.forEach(transaction => {
            const date = moment(transaction.date_created).format('DD-MM-YY');

            if (transaction.status == 0) {
              pending += 1;
            }

            if (transaction.status == 1) {
              executed += 1;
            }
            if (transaction.status == 2) {
              rejected += 1;
            }

            if (finalObj[date]) {
              finalObj[date].push(transaction);
            } else {
              finalObj[date] = [transaction];
            }
          });

       
          SetPending(pending);
          SetExecuted(executed);
          Setrejected(rejected);
          SetCurrentstate(3);
        }
        // console.log(r);
      })
      .catch(err => {
        setLoading(false);
      });
  };




 
  const handleKeyPress = useCallback((event) => {
    console.log(`key pressed: ${event.key}`);
    if (event.key === "ArrowUp") {

      if (transaction[transaction.indexOf(pendingSelected) - 1] !== undefined) {
       
      setPendingSelected(transaction[transaction.indexOf(pendingSelected) - 1]);
      }
    }
    if (event.key === "Enter") {
      if(!editmodel){
        if(pendingSelected !="" && pendingSelected !=undefined){
          CanclePending(pendingSelected)
        }
      }

  
   

    }
    if (event.key === "ArrowDown") {
   

      if (transaction.indexOf(pendingSelected) != transaction.length-1){
        setPendingSelected(transaction[transaction.indexOf(pendingSelected) + 1]);
      }
      
    }
  });
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const onclickevent = useCallback((item) => {
    setPendingSelected(item);
  });

  return (
    <div
    className="Profile_box"
    style={{ background: NewTheme.lightmain, fontSize: 13,position:'relative' }}
  >
    {editmodel?
    <PendingModel selected ={pendingSelected} close={()=>{
      loadpending()
      setEditModel(false)
      }} />:null}
    <div
      id="SELCTFOCUS"
      style={{
        height: 30,
        background: "black",
        display: "flex",
        color: "white",
        fontWeight: "bold",
        alignItems: "cs",
        padding: 5,
        boxSizing: "border-box",
      }}
    >
      {/* <div style={{ width: 50 }}>
        <img style={{ height: 25 }} src={logo} alt="" />
      </div> */}
      <div style={{ flex: 1, display: "flex" }}>PENDING</div>
      <div style={{ width: 60 }}>
        <button onClick={props.closePending}>Close</button>
      </div>
    </div>
    <div
        style={{
          maxHeight: (window.innerHeight / 100) * 90,
          overflowY: "scroll",
        }}
      >
        <table className="tablenew">
          <thead>
            <tr  style={{
                background: NewTheme.MainColor,

                height: 30,
                alignItems: "center",
                color: "white",
                fontWeight: "bold",
                borderStyle: "solid",
                borderColor: "black",
                borderWidth: 0,
                borderTopWidth: 1,
                fontSize: 12,
              }}>
              <th>SYMBOL</th>
              <th>EXPIRY</th>
              <th>Limit Type</th>
              <th>BUY/SELL</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>SL/TP</th>
              <th>Status</th>
              <th>Details</th>
              <th>Process Time</th>
              <th>Time</th>
              <th>Edit</th>
              <th>Cancel</th>
  
            </tr>
          </thead>
          <tbody
            style={{
              height: 20 + "!important",
              overflowY: "scroll",
            }}

            // style={{
            //   "height: 10 + !important, overflow: scroll"
            // // height: 10;  overFlow: "scroll"
            // }}
          >
            {transaction &&
              transaction.map((item, index) => (
                <tr
                  id={item.id}
                  onClick={() => onclickevent(item)}
                  style={{
                    backgroundColor: item === pendingSelected ? "#fae7cd" : "",
                  }}
                >
                  <td style={{fontWeight:'bold'}}>{item.symbol}</td>
                  <td>{moment(item.expiry_date).format("DD-MMM")}</td>
                  <td>{item.highlow_mid==0 ?"H/L":'MID'}</td>
                  <td style={{color:item.buy_sell==0?NewTheme.greencolor:NewTheme.redcolor,fontWeight:'bold'}}>{item.buy_sell==0 ?"BUY":'SELL'}</td>
                  <td>{item.qty}</td>
                  <td>{parseFloat(item.price).toFixed(2)}</td>
                  <td>{item.limit_type == 0?'SL':'TP'}</td>
                  <td style={{fontWeight:'bold',color:item.status == 0?"gray":item.status==1?NewTheme.greencolor:NewTheme.redcolor}}>{item.status == 0?"PENDING":item.status==1?"COMPLETED":'REJECTED'}</td>
                  <td>{item.process_msg}</td>
                  <td>{moment(item.time_buy).format("hh:mm:ss A")}</td>
                  <td>{moment(item.date_created).format("DD-MMM hh:mm:ss A")}</td>
                  <td>{item.status == 0?<button onClick={()=>{
                    setEditModel(true)
                    setPendingSelected(item)
                  }} style={{padding:5,paddingLeft:20,paddingRight:20,color:NewTheme.MainColor,fontWeight:'bold',background:'white',borderWidth:1,borderColor:NewTheme.MainColor}}>Modify</button>:null}</td>
                  <td> 
                    {item.status == 0?<button onClick={()=>CanclePending(item)} style={{padding:5,paddingLeft:20,paddingRight:20,color:NewTheme.MainColor,fontWeight:'bold',background:'white',borderWidth:1,borderColor:NewTheme.MainColor}}>Cancel</button>:null}
                  </td>
                  {/* <td
        style={{
          display: "flex",
          flexDirection:'row',
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          style={{
            height: 25,
            fontSize: 13,
            paddingLeft: 15,
            paddingRight: 15,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            background: NewTheme.MainColor,
            color: "white",
            borderRadius: 5,
            borderStyle: "hidden",
          }}
           onClick={()=>EditPending()}
        >
          Cancle
        </button>
      </td>
          <td
        style={{
          // display: "flex",
          // justifyContent: "center",
          // flexDirection:'row',
          // alignItems: "center",
          // alignSelf:'center'
        }}
      >
        <button
          style={{
            height: 25,
            fontSize: 13,
            paddingLeft: 15,
            paddingRight: 15,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            background: NewTheme.MainColor,
            color: "white",
            borderRadius: 5,
            borderStyle: "hidden",
          }}
           onClick={()=>EditPending()}
        >
          EDIT
        </button>
      </td> */}

      
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Pending;
