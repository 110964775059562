import logo from "./logo.svg";
import { Route, Routes, BrowserRouter } from "react-router-dom";

import "./App.css";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Watchlist from "./pages/Watchlist";
import Drag from "./pages/Drag";
import Resize from "./pages/Resize";

import io from "socket.io-client";
import SocketContext from "./SocketContext";
import About from "./pages/About";
import { useEffect, useState } from "react";

import logo2 from "./Images/Images/logo.png";
import { useWindowSize } from "usehooks-ts";

var socket = io("https://emit.dreamtrade.biz");
var versioncode = 6;
import(`./resource/env/${process.env.REACT_APP_NAME}/env.scss`);
if (
  localStorage.getItem("version") == null ||
  localStorage.getItem("version") < versioncode
) {
  localStorage.setItem("version", versioncode);
  location.reload(true);
  alert("AUTO UPDATED");
}
function App() {
  const [loadscreen, setLoadScreen] = useState(true);
  const { height, width } = useWindowSize();
  useEffect(() => {
    setTimeout(() => {
      setLoadScreen(false);
    }, 200);
  }, []);
  return (
    <div className="App">
      {loadscreen ? (
        <div
          style={{
            height: height,
            width: width,
            zIndex: 99,
            position: "fixed",
            background: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={logo2} style={{ height: 300, width: 300 }} />
        </div>
      ) : null}
      <SocketContext.Provider value={socket}>
        <BrowserRouter>
          {localStorage.IsLoggedIn === "1" ? (
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/" element={<Watchlist />} />
              <Route path="/drag" element={<Drag />} />

              <Route path="/resize" element={<Resize />} />
              <Route path="/about" element={<About />} />

              <Route path="*" element={<Watchlist />} />
            </Routes>
          ) : (
            <Routes>
              <Route path="/login" element={<Login />} />

              <Route path="*" element={<Login />} />
            </Routes>
          )}
        </BrowserRouter>
      </SocketContext.Provider>
    </div>
  );
}

export default App;
