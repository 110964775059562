import {
  React,
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from "react";
import SocketContext from "../SocketContext";
import moment from "moment";
import AnimtextWatch from "./AnimtextWatch";

const WatchItem = (props) => {
  const i = props.item;
  // console.log(typeof i);
  // console.log("i");
  // console.log(typeof i.symbol_display);
  // console.log("hello");
  // console.log(props.list);
  const socket = useContext(SocketContext);
  const [chng, setchng] = useState(0);
  const [percent, setpercent] = useState(0);
  const [ask, setask] = useState(0);
  const [askhigh, setaskhigh] = useState(false);
  const [bid, setbid] = useState(0);
  const [bidhigh, setbidhigh] = useState(false);
  const [high2, sethigh2] = useState(0);
  const [low2, setlow2] = useState(0);
  const [ltp, setltp] = useState(0);
  const [ticktime, SetTicktime] = useState(0);
  const [open, setopen] = useState(0);
  const [close, setclose] = useState(0);
  // const [trade,settrade] = useState("")
  // const
  // setTimeout(() => {
  //   console.log("Helo instdet hte time out");
  // }, 1000);
  // setInterval(() => {
  //   console.log("the time inteval")
  // }, 10000);
  useEffect(() => {
    // loadProfile();
    console.log("Tick time");
    console.log("i");
    socket.emit("giverate", i.script_id);
    socket.on("trade" + i.script_id, trade);
    socket.on("bidask" + i.script_id, bidask);
    return () => {
      socket.off("trade" + i.script_id, trade);
      socket.off("bidask" + i.script_id, bidask);
    };
  }, []);

  var trade = (msg) => {

    if(msg ==null){
      return false
    }
    // alert(msg.Bid)
    // console.log(msg.Bid)

    // SetTicktime(msg.Timestamp);
    if (bid !== msg.Bid) {
      //   if( parseFloat(bidnew)  < parseFloat(msg.Bid) ){
      //     // bidUp=1

      //    }else if( parseFloat(bidnew)  > parseFloat(msg.Bid) ){
      //     //  bidUp=2

      //    }
      console.log("-----");
      console.log(bid);
      console.log(msg.Bid);
      console.log(typeof bid);
      console.log(typeof msg.Bid);
      if (parseFloat(bid) < parseFloat(msg.Bid)) {
        setbidhigh(true);
      }
      if (parseFloat(bid) >= parseFloat(msg.Bid)) {
        setbidhigh(false);
      }
      console.log(bidhigh);

      setbid(msg.Bid);

      //   bidnew = msg.Bid
    }
    if (ask !== msg.Ask) {
      //   if( parseFloat(asknew)  < parseFloat(msg.Ask) ){
      // //    askUp=1

      //   }else if( parseFloat(asknew)  > parseFloat(msg.Ask) ){
      //     // askUp=2

      //   }
      console.log("_-_-_-_");
      console.log(ask);
      console.log(msg.Ask);
      console.log(typeof ask);
      console.log(typeof msg.Ask);
      if (parseFloat(ask) < parseFloat(msg.Ask)) {
        setaskhigh(true);
      }
      if (parseFloat(ask) >= parseFloat(msg.Ask)) {
        setaskhigh(false);
      }
      console.log(askhigh);
      setask(msg.Ask);
      //   asknew =msg.Ask
    }
    if (high2 !== msg.High) {
      sethigh2(msg.High);
    }
    if (low2 !== msg.Low) {
      setlow2(msg.Low);
    }
    if (msg.LTP - msg.Previous_Close !== chng) {
      setchng(msg.LTP - msg.Previous_Close);
      setpercent(((msg.LTP - msg.Previous_Close) / msg.Previous_Close) * 100);
    }
    if (ltp !== msg.LTP) {
      setltp(msg.LTP);
    }

    if (open !== msg.Open) {
      setopen(msg.Open);
    }
    if (close !== msg.Previous_Close) {
      setclose(msg.Previous_Close);
    }
  };

  var bidask = (msg) => {
    // return false
    // SetTicktime(msg.Timestamp);
    if (bid !== msg.Bid) {
      //       if( parseFloat(bidnew)  < parseFloat(msg.Bid) ){
      //         // bidUp=1
      // //
      //        }else if( parseFloat(bidnew)  > parseFloat(msg.Bid) ){
      //         //  bidUp=2

      //        }
      //   bidnew = msg.Bid

      // setBidopsite(true);
      setbid(msg.Bid);
      // setTimeout(() => {
      //   setBidopsite(false);
      // }, 200);
    }
    if (ask !== msg.Ask) {
      //   if( parseFloat(asknew)  < parseFloat(msg.Ask) ){

      //     // askUp=1
      //   }else if( parseFloat(asknew)  > parseFloat(msg.Ask) ){
      // //    askUp=2

      //   }
      //   asknew = msg.Ask
      // setAskopsite(true);
      setask(msg.Ask);

      // setTimeout(() => {
      //   setAskopsite(false);
      // }, 200);
    }
  };
  
  return (
    <>
      <tr 
        className={`table_row WatchItem ${props.isDragging ? "dragging" : ""}`}
        style={{
          backgroundColor: props.item === props.selected ? "#f3e4d1" : "",
          // backgroundColor: props.item === props.dragColor ? "blue" : "",
         
        }}
        onClick={() => {
          props.onclickevet(props.item);
        }}
        // onClick={style={backgroundColor:'green'}}
        id={props.item}
        onDragStart={(e) => props.dragStart(e, props.index)}
        onDragEnter={(e) => props.dragEnter(e, props.index)}
        onDragEnd={(e) => {
          props.drop(e);
        }}
        key={props.index}
        draggable
        // onClick={() => { props.onclickevet(props.item) }}
        // id={props.item}
        // onDragStart={(e) => props.dragStart(e, props.item)}
        // onDragEnter={(e) => props.dragEnter(e, props.item)}
        // onDragEnd={(e) => props.drop(e)}
        // key={props.item}
        // draggable
      >
        <td style={{fontWeight:'bold',}}>{"     "}{i.symbol_display}</td>
        <td>{moment(i.expiry_date).format("DD-MMM")}</td>
        <td
      style={{padding:0,width:130}}
          onClick={()=>{props.buy(false);props.sell(true);props.setvalue()}}
        >
          <AnimtextWatch value={bid}/>
        </td>
        <td
    style={{padding:0,width:130}}
          onClick={()=>{props.buy(true);props.sell(false);props.setvalue()}}
        >
         <AnimtextWatch value={ask}/>  
        </td>
         <td style={{width:120}}>{parseFloat(ltp).toFixed(2)}</td>
         <td style={{width:120}}>{parseFloat(chng).toFixed(2)}</td>
         <td>{parseFloat(high2).toFixed(2)}</td>
         <td>{parseFloat(low2).toFixed(2)}</td>
         <td>{parseFloat(open).toFixed(2)}</td>
         <td>{parseFloat(close).toFixed(2)}</td>
        <td>
          <button
            className="watch_remove_btn"
            onClick={() => {
              props.removeHandler(i.id);
            }}
          >
            REMOVE
          </button>
        </td>
      </tr>
    </>
  );
};

export default WatchItem;
