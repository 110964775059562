import React, { useEffect, useState } from "react";
import { NewTheme } from "./Theme/Theme";
import Backend from "../Backend";

const backend = new Backend();

export default function Marquee() {
  const [news, setNews] = useState([]);
  const [count, SetCount] = useState(0);

  useEffect(() => {
    loadnews();
  }, []);

  const loadnews = () => {
    // setLoading(true);
    let data = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
    };

    backend.loadnews(data).then(async (r) => {
      console.log(r);
      if (r.error == "False") {
        setNews(r.data);
        // const newdata = localStorage.getItem("setid");
        // const finaldata = await r.data.filter((x) => x.id > newdata);
        // SetCount(finaldata.length);
      }
    });
  };

  return news?.length > 0 ? (
    <marquee scrollamount={8}>
      <div style={{ display: "flex" }}>
        {news?.map((i, t) => (
          <div style={{ color: "black", marginLeft: 20 }}>
            {t + 1}.
            <span style={{ color: NewTheme.MainColor, fontWeight: "bold" }}>
              {i.news_type}
            </span>{" "}
            <span style={{ color: "black" }}>{i.news}</span>
          </div>
        ))}
      </div>
    </marquee>
  ) : null;
}
