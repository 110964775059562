import {
  React,
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from "react";

import SocketContext from "../SocketContext";
import moment from "moment";

import Backend from "../Backend";
import { NewTheme } from "./Theme/Theme";
const backend = new Backend();

const Buy = (props) => {
  console.log(props.selected);
  console.log("props.slectd");

  const i = props.selected;
  const socket = useContext(SocketContext);

  const [chng, setchng] = useState(0);
  const [percent, setpercent] = useState(0);
  const [ask, setask] = useState(0);
  const [bid, setbid] = useState(0);
  const [high2, sethigh2] = useState(0);
  const [low2, setlow2] = useState(0);
  const [ltp, setltp] = useState(0);

  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState("");
  // const [loading, setLoading] = useState(false);
  const [buysell, setBuysell] = useState(props.type);

  const [option, setOption] = useState(0);
  const [atPrice, setAtPrice] = useState(0);



  const [qtypos, setQtypos] = useState(0);
  const [buysellpos, setBuysellpos] = useState(0);





  const [limit, setlimit] = useState(true);
  useEffect(() => {
    load_position_overview()

    var selector = document.getElementById("dropdown")
    setTimeout(() => {
      selector.focus()
    }, 150);
    // loadProfile();
    console.log(i);
    socket.emit("giverate", i.script_id);
    socket.on("trade" + i.script_id, trade);
    socket.on("bidask" + i.script_id, bidask);
    return () => {
      socket.off("trade" + i.script_id, trade);
      socket.off("bidask" + i.script_id, bidask);
    };
  }, []);

  const [qty, setQty] = useState(0);
  const onQtyHandler = (e) => {
    setQty(e.target.value);
  };



  const load_position_overview = () => {
    let newdata = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
      script_id:i.script_id,
      segment: i.script_type,
    };


    backend.loadpositionoverview(newdata).then(r => {

   
      // console.log(r);
      if (r.error == 'False') {
        setBuysellpos(r.buy_sell);
        setQtypos(r.qty);
        // setIdpos(r.pos_id);
      }
      // console.log(r);
    });
  };

  const BuyFunction = () => {

    if (!loading) {
      if (qty <= 0) {
        window.alert("Qty Require Greater Then 0");
      } else {
        setLoading(true);
        let sendData = {
          m_code: i.script_id,
          order: qty,
          ud: buysell ==0?"0":'1',
          segi: i.script_type,
          token: localStorage.getItem("token"),
        };

        backend
          .trade(sendData)
          .then((r) => {
            setTimeout(() => {
              setLoading(false);
            }, 500);
            if (r.success == "True") {
              load_position_overview();
             
              window.alert(r.msg);
              setQty("")
              load_position_overview()
            } else {
              window.alert(r.msg);
            }
          })
          .catch((err) => {
            setTimeout(() => {
              setLoading(false);
            }, 500);
            console.log(err);
            // Alert.alert('', err);
          });
      }
    }
  };

  const BuyPending = () => {
    if (!loading) {
      if (qty <= 0) {
        window.alert("Qty Require Greater Then 0");
      } else if (atPrice <= 0) {
        window.alert("At Price Must Be Greater Then 0");
      } else {
        setLoading(true);
        let sendData = {
          m_code: i.script_id,
          order: qty,
          ud: buysell ==0?"0":'1',
          segi: i.script_type,
          at_price: atPrice,
          token: localStorage.getItem("token"),
        };

        backend
          .pending(sendData)
          .then((r) => {
            setTimeout(() => {
              setLoading(false);
            }, 500);
            // console.log(r);
            if (r.success == "True") {
              window.alert(r.msg);
              setQty("")
              load_position_overview()
            } else {
              window.alert(r.msg);
            }
          })
          .catch((err) => {
            setTimeout(() => {
              setLoading(false);
            }, 500);
            console.log(err);
            // Alert.alert('', err);
          });
      }
    }
  };

  const trade = (msg) => {
    // if (Ismouted) {
    if (msg == null) {
    } else {
      if (bid !== msg.Bid) {
        setbid(msg.Bid);
      }
      if (ask !== msg.Ask) {
        setask(msg.Ask);
      }
      if (high2 !== msg.High) {
        sethigh2(msg.High);
      }
      if (low2 !== msg.Low) {
        setlow2(msg.Low);
      }
      if (msg.LTP - msg.Previous_Close !== chng) {
        setchng(msg.LTP - msg.Previous_Close);
        setpercent(((msg.LTP - msg.Previous_Close) / msg.Previous_Close) * 100);
      }
      if (ltp !== msg.LTP) {
        setltp(msg.LTP);
      }
      if (time !== msg.Timestamp) {
        setTime(msg.Timestamp);
      }
      // }
    }
  };

  const bidask = (msg) => {
    // if (Ismouted) {
    if (bid !== msg.Bid) {
      setbid(msg.Bid);
    }
    if (ask !== msg.Ask) {
      setask(msg.Ask);
    }

    if (time !== msg.Timestamp) {
      setTime(msg.Timestamp);
    }
    //     }
  };

  const handleKeyPress = useCallback((event) => {
    console.log(`key pressed: ${event.key}`);
    if (event.key === "Enter") {
      if (option == 0) {
        BuyFunction();
      } else {
        BuyPending();
      }
    }
    if (event.key === "Escape") {
      props.onCancelHandler()
    }
    if (event.key === "-") {
      event.preventDefault()
    }
    if (event.key === "+") {
      event.preventDefault()
    }
    if (event.key === "+") {
      setBuysell(0)
    }
    if (event.key === "-") {
   setBuysell(1)
    }
  });

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);
  const color = buysell == 0?NewTheme.greencolor:NewTheme.redcolor

  return (
   <div style={{position:'fixed',height:'100%',width:'100%',background:'rgba(0,0,0,0.2)',bottom:0}}>
    <div style={{height:150,position:'fixed',background:color,width:'70%',bottom:10,left:'15%',display:'flex'}}>

<div style={{width:70 ,display:'flex',justifyContent:'center',alignItems:'center',rotate:'-90deg',color:'white',fontWeight:'bold',fontSize:30}}>
  {buysell==0?"BUY":"SELL"}
  </div>
<div style={{flex:3}}>
<div style={{height:60,width:'100%',background:'white',borderStyle:'solid',borderWidth:5,boxSizing:'border-box',height:'100%',borderColor:color,display:'flex',flexDirection:'row'}}>
 
  <div style={{flex:6,display:'flex',flexDirection:'column'}}>
    <div style={{flex:1,display:'flex',justifyContent:'flex-start',alignItems:'center',fontWeight:'bold',paddingLeft:15}}>

      <div style={{color:'black'}}>{i.symbol_display}</div>
      <div style={{fontSize:12,marginLeft:10}}>{moment(i.expiry_date).format("DD-MMM")}</div>
    </div>
    <div style={{flex:2,borderStyle:'solid',borderWidth:0,borderTopWidth:1,display:'flex',justifyContent:'center',alignItems:'center'}}>
      <div style={{flex:1}}>


<div style={{fontSize:11,fontWeight:'bold'}}>ORDER TYPE</div>
<select
            style={{width:'80%'}}
            type="text"
           value={option}
          id="dropdown"
            onChange={(e)=>setOption(e.target.value)}
          >
            <option value={0}>Market</option>
            <option value={1}>Pending</option>
          </select>
</div>

<div style={{flex:1}}>
<div style={{fontSize:11,fontWeight:'bold'}}>QTY</div>
          <input
            className="detail_text qty"
            type="number"
            onChange={onQtyHandler}
            value={qty}
            style={{width:'80%'}}
          />
</div>
<div style={{flex:1}}>
<div style={{fontSize:11,fontWeight:'bold'}}>PRICE</div>
<input
            disabled={option == 0}
            className="detail_text qty"
            type="number"
            onChange={(e) => setAtPrice(e.target.value)}
            value={atPrice}
            style={{width:'80%'}}
          />
</div>


    </div>
    <div style={{flex:2,borderStyle:'solid',borderWidth:0,borderTopWidth:1,display:'flex',justifyContent:'center',alignItems:'center'}}>
      
    <div style={{flex:1}}>

    <button
       style={{width:'60%',borderStyle:'hidden',padding:5,background:NewTheme.MainColor,color:'white',fontSize:14,fontWeight:'bold',borderRadius:5}}
            className="submit_btn"
            type="submit"
            onClick={option == 0 ? BuyFunction : BuyPending}
          >
            Submit
          </button>
</div>
<div style={{flex:1}}>


          <button
          style={{width:'60%',borderStyle:'hidden',padding:5,background:NewTheme.redcolor,color:'white',fontSize:14,fontWeight:'bold',borderRadius:5}}
            className="create_btn"
            type="submit"
            onClick={props.onCancelHandler}
          >
            Cancel
          </button>
     
</div>

    </div>


  </div>
  <div style={{flex:2,borderStyle:'solid',borderWidth:0,borderLeftWidth:0,display:'flex',flexDirection:'column',borderColor:color}}>

  <div style={{flex:1,display:'flex',justifyContent:'flex-end',alignItems:'center',borderStyle:'solid',borderWidth:0,borderBottomWidth:1,fontSize:11,fontWeight:'bold',paddingRight:10}}>

POSITION QTY: <div style={{color:buysellpos ==0?NewTheme.greencolor:NewTheme.redcolor,fontSize:16,paddingLeft:10}}>{buysellpos ==0?"":"-"}{qtypos}{i.script_type =="fut"? "" :" LOT"}</div>
</div>

<div style={{flex:2,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0,borderLeftWidth:1}}>
<div style={{flex:1,borderStyle:'solid',height:'100%',display:"flex",flexDirection:'column',justifyContent:'center',borderWidth:0,borderRightWidth:1,borderColor:color}}>
<div style={{fontSize:12}}>BID</div>
<div style={{fontWeight:'bold'}}>{bid}</div>
</div>
<div style={{flex:1}}>
<div style={{fontSize:12}}>ASK</div>
<div style={{fontWeight:'bold'}}>{ask}</div>
</div>

</div>
<div style={{flex:2,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:0,borderTopWidth:1,borderColor:color,borderLeftWidth:1}}>
<div style={{flex:1,borderStyle:'solid',height:'100%',display:"flex",flexDirection:'column',justifyContent:'center',borderWidth:0,borderRightWidth:1,borderColor:color}}>
<div style={{fontSize:12}}>HIGH</div>
<div style={{fontWeight:'bold'}}>{high2}</div>
</div>
<div style={{flex:1}}>
<div style={{fontSize:12}}>LOW</div>
<div style={{fontWeight:'bold'}}>{low2}</div>
</div>

</div>
  </div>
</div>



</div>
<div style={{flex:1,background:color,display:'flex',flexDirection:'column',fontWeight:'bold',color:'white',paddingLeft:10,paddingRight:10,justifyContent:'center',padding:20,fontSize:15}}>
<div style={{height:'40%',borderStyle:'solid',borderWidth:0,borderBottomWidth:1,marginBottom:5,fontSize:14}}>

<div style={{display:'flex',justifyContent:'space-between'}}>
<div>LTP</div>
<div>{ltp}</div>
</div>

<div style={{display:'flex',justifyContent:'space-between'}}>
<div>CHANGE</div>
<div>{parseFloat(chng).toFixed(2)}({parseFloat(percent).toFixed(2)}%)</div>
</div>
</div>
<div style={{display:'flex',justifyContent:'space-between'}}>
<div>QTY PER ORDER</div>
<div>{i.punch_qty}</div>
</div>
<div style={{display:'flex',justifyContent:'space-between'}}>
<div>TOTAL QTY</div>
<div>{i.total_qty}</div>
</div>
<div style={{display:'flex',justifyContent:'space-between'}}>
<div>{i.script_type == "fut"?"MIN QTY":"LOT SIZE"}</div>
<div>{i.script_type == "fut"?i.min_qty:i.lot_size}</div>
</div>



</div>
</div>
   </div>
  );
};

export default Buy;
