import { React, useState, useEffect, useCallback, createRef } from "react";
import "./SiTp.css";
import logo from "../../logo.svg";

import Backend from "../../Backend";
import moment from "moment";
import SltpCard from "./SltpCard";
import { NewTheme } from "../../Theme/Theme";
import { useWindowSize } from "@uidotdev/usehooks";
import PendingSheet from "./PendingSheet";
const backend = new Backend();

const SiTp = () => {
  const [pending, SetPending] = useState([]);
  const [loading, setLoading] = useState(false);
  const [transaction, SetTransactions] = useState([]);
  const [transaction2, SetTransactions2] = useState([]);
  const [executed, SetExecuted] = useState("");
  const [rejected, Setrejected] = useState("");
  const [datasheet, Setdatasheet] = useState({});
  const [curruntstate, SetCurrentstate] = useState(3);
  const [pendingSelected, setPendingSelected] = useState("");
  const { height } = useWindowSize();
  const reftop = createRef(null);
  const reftop2 = createRef(null);

  const [filterStatus, setFilterStatus] = useState(false);
  var heights = 0;
  useEffect(() => {
    loadpending();
  }, []);

  const loadpending = () => {
    setLoading(true);
    let data = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
      filter: "THISMONTH",
    };

    backend
      .loadpending(data)
      .then((r) => {
        console.log("r");
        console.log(r.data, "POSITON ");
        setTimeout(() => {
          setLoading(false);
          // refRBSheet.current.open();
        }, 500);
        // console.log(r);

        if (r.error == "False") {
          let finalObj = {};
          let pending = 0;
          let executed = 0;
          let rejected = 0;
          SetTransactions(r.data);
          SetTransactions2(r.data);
          r.data.forEach((transaction) => {
            // const date = moment(transaction.date_created).format("DD-MM-YY");

            if (transaction.status == 0) {
              pending += 1;
            }

            if (transaction.status == 1) {
              executed += 1;
            }
            if (transaction.status == 2) {
              rejected += 1;
            }
          });

          // SetTransactions(r.data);
          SetPending(pending);
          SetExecuted(executed);
          Setrejected(rejected);
          SetCurrentstate(3);
        }
        // console.log(r);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const [selectTab, setSelectTab] = useState("all");

  return (
    <div style={{ overflowx: "hidden", width: "100%" }}>
      <div
        style={{
          position: "fixed",
          width: "100%",
          background: NewTheme.MainColor,
          height: 80,
          top: 0,
          zIndex: -1,
        }}
      ></div>
      <div
        ref={reftop}
        id="newtop"
        style={{
          width: "90%",
          marginTop: 30,
          padding: 10,
          borderRadius: 5,
          display: "flex",
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: NewTheme.MainColor,
          paddingTop: 20,
          paddingBottom: 20,
          background: "white",
          marginLeft: "5%",
          height: 70,
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderStyle: "solid",
            borderWidth: 0,
            borderRightWidth: 1,
          }}
        >
          <div style={{ fontWeight: "bold", color: "black" }}>Pending</div>
          <div style={{ fontWeight: "bold", color: "gray" }}>{pending}</div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderStyle: "solid",
            borderWidth: 0,
            borderRightWidth: 1,
          }}
        >
          <div style={{ fontWeight: "bold", color: "black" }}>Executed</div>
          <div style={{ fontWeight: "bold", color: NewTheme.greencolor }}>
            {executed}
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ fontWeight: "bold", color: "black" }}>Rejected</div>
          <div style={{ fontWeight: "bold", color: NewTheme.redcolor }}>
            {rejected}
          </div>
        </div>
      </div>
      <div
        id="newtop2"
        style={{
          height: 50,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 20,
          fontSize: 14,
          fontWeight: "bold",
          color: NewTheme.MainColor,
          borderStyle: "solid",
          borderWidth: 0,
          borderBottomWidth: 1,
        }}
      >
        <div
          style={{
            flex: 1,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              borderStyle: "solid",
              borderWidth: 0,
              paddingLeft: 10,
              paddingRight: 10,
              paddingBottom: 2,
              borderBottomWidth: curruntstate == 3 ? 2 : 0,
            }}
            onClick={() => {
              SetCurrentstate(3);
              SetTransactions(transaction2);
              // var filtered = transaction2.filter(x=>x.status )
            }}
          >
            ALL
          </div>
        </div>
        <div
          style={{
            flex: 1,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              borderStyle: "solid",
              borderWidth: 0,
              paddingLeft: 10,
              paddingRight: 10,
              paddingBottom: 2,
              borderBottomWidth: curruntstate == 0 ? 2 : 0,
            }}
            onClick={() => {
              SetCurrentstate(0);
              var filtered = transaction2.filter((x) => x.status == 0);
              SetTransactions(filtered);
            }}
          >
            Pending
          </div>
        </div>
        <div
          style={{
            flex: 1,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              borderStyle: "solid",
              borderWidth: 0,
              paddingLeft: 10,
              paddingRight: 10,
              paddingBottom: 2,
              borderBottomWidth: curruntstate == 1 ? 2 : 0,
            }}
            onClick={() => {
              SetCurrentstate(1);
              var filtered = transaction2.filter((x) => x.status == 1);
              SetTransactions(filtered);
            }}
          >
            Executed
          </div>
        </div>
        <div
          style={{
            flex: 1,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              borderStyle: "solid",
              borderWidth: 0,
              paddingLeft: 10,
              paddingRight: 10,
              paddingBottom: 2,
              borderBottomWidth: curruntstate == 2 ? 2 : 0,
            }}
            onClick={() => {
              SetCurrentstate(2);
              var filtered = transaction2.filter((x) => x.status == 2);
              SetTransactions(filtered);
            }}
          >
            Rejected
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: height - 235,
          overflowY: "scroll",
        }}
      >
        {transaction.map((i, t) => (
          <SltpCard
            data={i}
            opensheet={(a) => {
              if (a.status == 0) {
                setFilterStatus(true);
                Setdatasheet(a);
              }
            }}
          />
        ))}

        <div style={{ height: 50 }}></div>
      </div>

      {filterStatus ? (
        <>
          <div
            style={{
              height: height - 400,
              background: "rgba(0,0,0,0.3)",
              position: "fixed",
              top: 0,
              width: "100%",
              padding: 10,
              left: 0,
              // zIndex: -1,
            }}
          ></div>
          <div
            style={{
              height: 400,
              background: "white",
              position: "fixed",
              bottom: 50,
              left: 0,
              width: "100%",
              padding: 10,
              zIndex: 999,
            }}
          >
            <PendingSheet
              data={datasheet}
              closesheet={() => {
                setFilterStatus(false);
                loadpending();
              }}
            />
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default SiTp;
