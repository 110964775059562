import React, { useState, useEffect } from "react";
import { NewTheme } from "../Theme/Theme";

const AnimText = ({ value }) => {
  const [backgroundColor, setBackgroundColor] = useState("lightgray");
  const [color, setColor] = useState("black");
  const [previousValue, setPreviousValue] = useState(value);

  useEffect(() => {
    let timeout;

    if (value > previousValue) {
      setBackgroundColor(NewTheme.greencolor);
      setColor("white");
    } else if (value < previousValue) {
      setBackgroundColor(NewTheme.redcolor);
      setColor("white");
    }

    timeout = setTimeout(() => {
      setBackgroundColor("lightgray");
      setColor("black");
    }, 2000);

    setPreviousValue(value);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [value]);

  return (
    <div
      style={{
        height: 35,
        borderWidth: 0,
        borderRightStyle: "solid",
        borderRightWidth: 0,
        borderRightColor: "lightgray",
        padding: 0,
      }}
    >
      <div
        style={{
          backgroundColor,
          height: "100%",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          padding: 0,
        }}
      >
        {/* Your content here */}
        <span
          style={{
            fontSize: 17,
            padding: 0,
            fontWeight: "bold",
            textAlign: "center",
            color,
          }}
        >
          {value}
        </span>
      </div>
    </div>
  );
};

export default AnimText;
