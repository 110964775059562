import React, { useState } from "react";
import { NewTheme } from "../../Theme/Theme";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import moment from "moment";
import Backend from "../../Backend";

const backend = new Backend();

export default function Searchcard(props) {
  const watchlist = JSON.parse(localStorage.getItem("watchlist"));
  const [data, setdata] = useState(props.data);
  const [loading, setLoading] = useState(false);
  const [found, setFound] = useState(
    watchlist.filter((x) => x.script_id == data.script_id)
  );

  const addscript = () => {
    setLoading(true);
    let datasend = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
      script: data,
      index: localStorage.getItem("watchselect"),
    };

    backend.addscript(datasend).then((r) => {
      setTimeout(() => {
        setLoading(false);
        // refRBSheet.current.open();
      }, 500);

      //   console.log("r.data",r.data)
      if (r.error == "False") {
        // SetFind(1);
        setFound([""]);
        var dataset = data;
        dataset.id = r.message[0];
        setdata(dataset);

        // setSearchData(r.data);
      } else {
        alert(JSON.stringify(r));
      }
      // console.log(r);
    });
  };

  const removescript = () => {
    setLoading(true);
    let datasend = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
      removeid: data.id,
    };

    backend.removescript(datasend).then((r) => {
      setTimeout(() => {
        setLoading(false);
        setFound([]);
        // refRBSheet.current.open();
      }, 500);

      if (r.error == "False") {
        // setSearchData(r.data);
      }
      // console.log(r);
    });
  };

  return found.length > 0 ? (
    <div
      key={data.script_id}
      style={{
        display: "flex",
        width: "100%",
        padding: 5,
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
      }}
    >
      <div style={{ width: 55 }}>
        <div
          style={{
            borderStyle: "solid",
            borderWidth: 1,
            height: 40,
            width: 40,
            borderRadius: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bolder",
            color: NewTheme.MainColor,
          }}
        >
          {data.symbol_display.charAt(0)}
        </div>
      </div>

      <div style={{ flex: 1, alignSelf: "center", fontWeight: "bold" }}>
        {data.symbol_display}
        <div style={{ fontSize: 11, color: "gray" }}>
          {moment(data.expiry_date).format("DD-MMM")}
        </div>
      </div>
      <div
        style={{
          width: 60,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={found.length > 0 ? removescript : addscript}
      >
        {found.length > 0 ? (
          <FaMinusCircle color={NewTheme.redcolor} size={22} />
        ) : (
          <FaPlusCircle color={NewTheme.greencolor} size={22} />
        )}
      </div>
    </div>
  ) : null;
}
