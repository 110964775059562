import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { createStore } from "redux";
import Reducer from "./Reducer/Reducer";
import A from "./src2/App";

const root = ReactDOM.createRoot(document.getElementById("root"));

const store = createStore(Reducer);
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

root.render(
  <React.StrictMode>
    <Provider store={store}>{isBrowser ? <App /> : <A />}</Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
