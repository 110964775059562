// Light theme colors
export const lightColors = {
  background: '#FFFFFF',
  primary: '#ebedf6',
  text: '#000000',
  error: '#D32F2F',
  buy: '#1da750',
  sell: '#ff3d32',
  active: '#0393d0',
  deactive: 'rgba(0,0,0,0.5)',
};

export const NewTheme = {
  MainColor: '#12877a',
  greencolor: '#019c6b',
  redcolor: '#df1524',
  lightmain: '#e8fcfa',
  newredtext: '#DC143C',
};

// Dark theme colors
export const darkColors = {
  background: '#000000',
  primary: '#14161b',
  text: '#ffffff',
  error: '#D32F2F',
  buy: '#1da750',
  sell: '#ff3d32',
  active: '#0393d0',
  deactive: '#d3d3d3',
};
