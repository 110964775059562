import { React, useState, useEffect, useCallback } from "react";
import "./Ledger.css";
import logo from "../../logo.svg";

import minus from "../../Images/minus.png";
import play from "../../Images/play.png";
import Backend from "../../Backend";
import moment from "moment";
import { NewTheme } from "../../Theme/Theme";
import { useWindowSize } from "@uidotdev/usehooks";
import LedgerCard from "./LedgerCard";

const backend = new Backend();

const Ledger = () => {
  const [ledger, setLedger] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ledgerdata, setLedgerdata] = useState("");
  const [ledgerSelected, setledgerSelected] = useState("");
  const { height } = useWindowSize();

  useEffect(() => {
    loadPosition();
  }, []);

  const loadPosition = () => {
    setLoading(true);
    let data = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
    };
    try {
      backend.loadledger(data).then((r) => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        console.log("r");
        console.log(r);
        if (r.error == "False") {
          let finalObj = {};
          r.data.forEach((transaction) => {
            const date = moment(transaction.date_created).format("DD-MM-YY");
            if (finalObj[date]) {
              finalObj[date].push(transaction);
            } else {
              finalObj[date] = [transaction];
            }
          });
          // setLedgerdata(r.data);
          setLedgerdata(finalObj);
        }
        // console.log(r);
      });
    } catch {
      console.log("Something Wents Wrong");
    }
  };
  console.log("position[0]");

  console.log();
  return (
    <div
      className="Ledger_Container"
      style={{ background: "white", paddingTop: 10 }}
    >
      <div
        className="top_bar"
        style={{
          position: "fixed",
          background: "white",
          top: 0,
          paddingTop: 30,
          width: "100%",
          paddingBottom: 30,
        }}
        onClick={() => (window.location = "/profile")}
      >
        <img src={play} className="top_back_icon" alt="" />
        <p className="title_text">Ledger Account</p>
      </div>

      <div
        style={{
          width: "100%",
          height: height - 30,
          overflowY: "scroll",
          marginTop: 50,
        }}
      >
        {Object.keys(ledgerdata).map((i, t) => (
          <div>
            <div
              style={{
                width: "100%",
                background: NewTheme.lightmain,
                borderStyle: "solid",
                borderWidth: 0,
                borderBottomWidth: 1,
                borderTopWidth: t == 0 ? 1 : 0,
                paddingLeft: 10,
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              {i}
            </div>
            {ledgerdata[i].map((item, index) => (
              <LedgerCard data={item} />
            ))}
          </div>
        ))}
        {/* {ledgerdata &&
          ledgerdata.map((item, index) =>)} */}
        <div style={{ background: "white", height: 70 }}></div>
      </div>
    </div>
  );
};

export default Ledger;
