import React from "react";
import { NewTheme } from "../Theme/Theme";

export default function LedgerCard(props) {
  const item = props.data;
  return (
    <tr
      id={item.id}
      onClick={() => props.onclickevent(item)}
      style={{
        backgroundColor: item === props.ledgerSelected ? "#fae7cd" : "",
      }}
    >
      <th
        style={{
          color: item.in_out == 0 ? NewTheme.greencolor : NewTheme.redcolor,
          fontWeight: "bold",
        }}
      >
        {item.in_out == 0 ? "" : "-"}
        {item.amount}
      </th>

      <td>{item.remarks}</td>
      <td
        style={{
          color: item.in_out == 0 ? NewTheme.greencolor : NewTheme.redcolor,
          fontWeight: "bold",
        }}
      >
        {item.in_out == 0 ? "Deposit" : "Withdraw"}
      </td>
      <td>{item.new_balance}</td>
      <td>{item.old_balance}</td>
      <td>{item.date_created}</td>
    </tr>
  );
}
