import React, { useState } from "react";
import "../css/Login.scss";
import logo from "../logo.svg";
import Backend from "../Backend";
const backend = new Backend();

const Login = () => {

  const logoutHandler = () => {
    localStorage.removeItem("IsLoggedIn");
    localStorage.removeItem("token");
    localStorage.removeItem("rules");
    localStorage.removeItem("server");
    localStorage.removeItem("client");
    window.location = "/";
  };

  return (
    <div className="LoginContainer">
      <div className="illustrationbox">
        <img className="illustration" src={logo} alt="" />
      </div>
      <div className="loginbox">
        <img className="logo" src={logo} alt="" srcset="" />

        <div className="loginitem">
          <input
            type="password"
            name=""
            id=""
            placeholder="Are You Sure want to logout"
          />
        </div>
        {/* <p className='forgot_text'>Forgot Password</p> */}
        <button className="submit_btn" onClick={logoutHandler} type="submit">
          Logout
        </button>
        <p className="bottom_text">
          Login with demo<span>Demo Login</span>
        </p>
      </div>
    </div>
  );
};

export default Login;
