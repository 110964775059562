import moment from "moment";
import React from "react";
import { NewTheme } from "../Theme/Theme";

export default function Transactioncard(props) {
  const i = props.data;
  return (
    <tr
      id={"trans" + i.id}
      onClick={() => props.select(i)}
      style={{ background: i === props.profileSelected ? "#fae7cd" : "white" }}
    >
      <td>
        {i.script_type == "fut"
          ? "NSEFO"
          : i.script_type == "mcx"
          ? "MCX"
          : "OPTION"}
      </td>
      <td> {i.symbol}</td>
      <td> {moment(i.expiry_date).format("DD-MMM")}</td>
      <td
        style={{
          color: i.buy_sell == 0 ? NewTheme.greencolor : NewTheme.redcolor,
          fontWeight: "bold",
        }}
      >
        {i.buy_sell == 0 ? i.qty : "-" + i.qty}
      </td>
      <td
        style={{
          fontWeight: "bold",
          color: i.buy_sell == 0 ? NewTheme.greencolor : NewTheme.redcolor,
        }}
      >
        {i.buy_sell == 0 ? "BUY" : "SELL"}
      </td>
      <td>{i.rate}</td>
      <td>{i.profit_loss}</td>
      <td>{i.brokerage_amt}</td>
      <td>{i.trade_msg}</td>
      <td>{i.volume}</td>
      <td>{moment(i.date_created).format("DD-MM-YY hh:mm:ss A")}</td>
    </tr>
    // <div
    //   key={parseInt(item.id)}
    //   data-id={parseInt(item.id)}
    //   id={"divs" + parseInt(item.id)}
    //   style={{
    //     height: 40,
    //     justifyContent: "center",
    //     display: "flex",
    //     alignItems: "center",
    //     backgroundColor: item === props.transactionSelected ? "#fae7cd" : "",
    //     cursor: item === props.transactionSelected ? "auto" : "",
    //     borderStyle: "solid",
    //     borderWidth: 0,
    //     borderBottomWidth: 1,
    //     borderColor: "lightgray",
    //   }}
    // >
    //   <div
    //     style={{
    //       flex: 2,
    //       fontWeight: "bold",
    //       borderStyle: "solid",
    //       borderWidth: 0,
    //       borderRightWidth: 1,
    //     }}
    //   >
    //     {item.symbol}
    //     <span style={{ marginLeft: 5, fontSize: 13, fontWeight: "normal" }}>
    //       {moment(item.expiry_date).format("DD-MMM")}
    //     </span>
    //   </div>
    //   <div
    //     style={{
    //       flex: 1,
    //       fontWeight: "bold",
    //       borderStyle: "solid",
    //       borderWidth: 0,
    //       borderRightWidth: 1,
    //     }}
    //   >
    //     {item.buy_sell == 0 ? "BUY" : "SELL"}
    //   </div>
    //   <div
    //     style={{
    //       flex: 1,
    //       fontWeight: "bold",
    //       borderStyle: "solid",
    //       borderWidth: 0,
    //       borderRightWidth: 1,
    //     }}
    //   >
    //     {item.qty}
    //   </div>
    //   <div
    //     style={{
    //       flex: 1,
    //       fontWeight: "bold",
    //       borderStyle: "solid",
    //       borderWidth: 0,
    //       borderRightWidth: 1,
    //     }}
    //   >
    //     {item.rate}
    //   </div>
    //   <div
    //     style={{
    //       flex: 1,
    //       fontWeight: "bold",
    //       borderStyle: "solid",
    //       borderWidth: 0,
    //       borderRightWidth: 1,
    //     }}
    //   >
    //     {item.brokerage_amt}
    //   </div>
    //   <div
    //     style={{
    //       flex: 1,
    //       fontWeight: "bold",
    //       borderStyle: "solid",
    //       borderWidth: 0,
    //       borderRightWidth: 1,
    //       color: item.profit_loss >= 0 ? "green" : "red",
    //     }}
    //   >
    //     {item.profit_loss}
    //   </div>
    //   <div
    //     style={{
    //       flex: 1,
    //       fontWeight: "bold",
    //       borderStyle: "solid",
    //       borderWidth: 0,
    //       borderRightWidth: 1,
    //     }}
    //   >
    //     {item.trade_msg}
    //   </div>
    //   <div
    //     style={{
    //       flex: 2,
    //       fontWeight: "bold",
    //       borderStyle: "solid",
    //       borderWidth: 0,
    //       borderRightWidth: 1,
    //     }}
    //   >
    //     {moment(item.date_created).format("DD-MMM HH:mm:ss")}
    //   </div>
    // </div>
  );
}
