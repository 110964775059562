import React, { Component, useState } from "react";
import ReactModal from "react-modal-resizable-draggable";

const Resize = () => {
  // class Resize extends Component {

  // constructor() {
  //     super();

  //     this.state = {
  //         modalIsOpen: false
  //     };

  //     this.openModal = this.openModal.bind(this);
  //     this.closeModal = this.closeModal.bind(this);
  // }

  const [modalIsOpen, setmodalIsOpen] = useState(false);

  const openModal = () => {
    setmodalIsOpen(true);
  };
  const closeModal = () => {
    setmodalIsOpen(false);
  };
  // openModal() {
  //     this.setState({modalIsOpen: true});
  // }
  // closeModal() {
  //     this.setState({modalIsOpen: false});
  // }



  // render() {
  return (
    <div className="Resize">
      <button onClick={openModal}>Open modal</button>
      <ReactModal
        initWidth={800}
        initHeight={400}
        onFocus={(e) => console.log("Modal is clicked")}
        className={"my-modal-custom-class"}
        onRequestClose={ closeModal}
        isOpen={modalIsOpen}
      >
        <h3>My Modal</h3>
        <div className="body">
          <p>This is the modal&apos;s body.</p>
        </div>
        <button onClick={closeModal}>Close modal</button>
      </ReactModal>
    </div>
  );
};
// }

export default Resize;
