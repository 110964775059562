import React from "react";
import { useWindowSize } from "usehooks-ts";
import BillCard from "./BillCard";

export default function Bill(props) {
  const { height } = useWindowSize();
  return (
    <div className="AboutContainer">
      {/* <div className="Profile_header">
        <img src={logo} alt="" />
        <p>Account</p>
        <button onClick={props.closeAbout}>Close</button>
      </div> */}
      <div
        id="SELCTFOCUS"
        style={{
          height: 30,
          background: "black",
          display: "flex",
          color: "white",
          fontWeight: "bold",
          alignItems: "center",
          padding: 5,
          boxSizing: "border-box",
        }}
      >
        {/* <div style={{ width: 50 }}>
        <img style={{ height: 25 }} src={logo} alt="" />
      </div> */}
        <div style={{ flex: 1, display: "flex" }}>BILL DOWNLOAD</div>
        <div style={{ width: 60 }}>
          <button onClick={props.closebill}>Close</button>
        </div>
      </div>
      <div
        className="internal_about"
        style={{
          display: "flex",
          flexDirection: "column",
          padding: 10,
          boxSizing: "border-box",
        }}
      >
        <BillCard data={0} />
        <BillCard data={1} />
        <BillCard data={2} />
        <BillCard data={3} />
      </div>
    </div>
  );
}
