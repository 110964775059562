import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { NewTheme } from "../Theme/Theme";
import SocketContext from "../../SocketContext";
import { connect } from "react-redux";
import AnimText from "./AnimText";

function PositionCard(props) {
  const i = props.data;

  const socket = useContext(SocketContext);

  const [ask, setask] = useState(0);
  const [bid, setbid] = useState(0);
  const [pf, setpf] = useState(0);
  const [btotal, setBtotal] = useState(0);

  const sockettrade = (msg) => {
    if (bid !== msg.Bid) {
      setbid(msg.Bid);
      if (i.buy_sell == 0) {
        let Profitloss = 0;
        let brokerage_total = 0;

        if (msg.Bid > 0) {
          Profitloss =
            parseFloat(parseFloat(msg.Bid) - parseFloat(i.rate)) *
            parseFloat(i.qty);
          Profitloss = Profitloss; //single side
          if (i.segment == 0 || (i.segment == 1 && i.brokerage < 1)) {
            Profitloss =
              Profitloss - (i.brokerage * i.qty * parseFloat(msg.Bid)) / 100;
            brokerage_total = parseFloat(
              (i.brokerage * i.qty * parseFloat(msg.Bid)) / 100
            );
          } else {
            Profitloss = Profitloss - i.brokerage;
            brokerage_total = i.brokerage;
          }
        } else {
          Profitloss = 0;
        }

        setpf(Profitloss);
        setBtotal(brokerage_total);
      }
    } else {
      setbid(msg.LTP);

      if (i.buy_sell == 0) {
        let Profitloss = 0;
        let brokerage_total = 0;
        if (msg.Bid > 0) {
          Profitloss =
            parseFloat(parseFloat(msg.LTP) - parseFloat(i.rate)) *
            parseFloat(i.qty);

          Profitloss = Profitloss; //single side

          if (i.segment == 0 || (i.segment == 1 && i.brokerage < 1)) {
            Profitloss =
              Profitloss - (i.brokerage * i.qty * parseFloat(msg.LTP)) / 100;

            brokerage_total = parseFloat(
              (i.brokerage * i.qty * parseFloat(msg.LTP)) / 100
            );
          } else {
            Profitloss = Profitloss - i.brokerage;
            brokerage_total = i.brokerage;
          }
        } else {
          Profitloss = 0;
        }

        setpf(Profitloss);
        setBtotal(brokerage_total);
      }
    }

    if (ask !== msg.Ask) {
      setask(msg.Ask);

      if (i.buy_sell == 1) {
        let Profitloss2 = 0;
        let brokerage_total = 0;
        if (parseFloat(msg.Ask) > 0) {
          Profitloss2 = (i.rate - msg.Ask) * i.qty;

          Profitloss2 = Profitloss2; //single side

          if (i.segment == 0 || (i.segment == 1 && i.brokerage < 1)) {
            Profitloss2 =
              Profitloss2 - (i.brokerage * i.qty * parseFloat(msg.Ask)) / 100;

            brokerage_total = parseFloat(
              (i.brokerage * i.qty * parseFloat(msg.Ask)) / 100
            );
          } else {
            Profitloss2 = Profitloss2; //single side
            brokerage_total = i.brokerage;
          }
        } else {
          Profitloss2 = 0;
        }
        setpf(Profitloss2);
        setBtotal(brokerage_total);
      }
    } else {
      setask(msg.LTP);
      if (i.buy_sell == 1) {
        let Profitloss2 = 0;
        let brokerage_total = 0;
        if (parseFloat(msg.LTP) > 0) {
          Profitloss2 = (i.rate - msg.LTP) * i.qty;

          Profitloss2 = Profitloss2; //single side

          if (i.segment == 0) {
            Profitloss2 =
              Profitloss2 - (i.brokerage * i.qty * parseFloat(msg.LTP)) / 100;

            brokerage_total = parseFloat(
              (i.brokerage * i.qty * parseFloat(msg.LTP)) / 100
            );
          } else {
            Profitloss2 = Profitloss2; //single side
            brokerage_total = i.brokerage;
          }
        } else {
          Profitloss2 = 0;
        }
        setpf(Profitloss2);
        setBtotal(brokerage_total);
      }
    }
  };
  const socketbidask = (msg) => {
    let brokerage_total = 0;
    if (bid !== msg.Bid) {
      setbid(msg.Bid);
      if (i.buy_sell == 0) {
        let Profitloss3 = 0;
        Profitloss3 = (msg.Bid - i.rate) * i.qty;

        Profitloss3 = Profitloss3; //single side

        if (i.segment == 0 || (i.segment == 1 && i.brokerage < 1)) {
          Profitloss3 =
            Profitloss3 - (i.brokerage * i.qty * parseFloat(msg.Bid)) / 100;

          brokerage_total = parseFloat(
            (i.brokerage * i.qty * parseFloat(msg.Bid)) / 100
          );
        } else {
          Profitloss3 = Profitloss3; //single side
          brokerage_total = i.brokerage;
        }

        setpf(Profitloss3);
      }
    }
    if (ask !== msg.Ask) {
      setask(msg.Ask);
      if (i.buy_sell == 1) {
        let Profitloss4 = 0;
        Profitloss4 = (i.rate - msg.Ask) * i.qty;

        Profitloss4 = Profitloss4; //single side

        if (i.segment == 0 || (i.segment == 1 && i.brokerage < 1)) {
          Profitloss4 =
            Profitloss4 - (i.brokerage * i.qty * parseFloat(msg.Ask)) / 100;

          brokerage_total = parseFloat(
            (i.brokerage * i.qty * parseFloat(msg.Ask)) / 100
          );
        } else {
          Profitloss4 = Profitloss4 - i.brokerage; //single side
          brokerage_total = i.brokerage;
        }

        setpf(Profitloss4);
        setBtotal(brokerage_total);
      }
    }
  };

  useEffect(() => {
    socket.emit("giverate", i.script_id);

    socket.on("trade" + i.script_id, sockettrade);

    socket.on("bidask" + i.script_id, socketbidask);

    return () => {
      socket.off("trade" + i.script_id, sockettrade);

      socket.off("bidask" + i.script_id, socketbidask);
    };
  }, []);
  props.calc_pf(i.script_id, pf, btotal, props.total);

  return (
    <tr
      style={{
        background: i === props.profileSelected ? "#fae7cd" : "white",
      }}
    >
      <td> {i.segment == 0 ? "NSEFO" : i.segment == 1 ? "MCX" : "OPTION"}</td>
      <td> {i.symbol}</td>
      <td> {moment(i.expiry_date).format("DD-MMM")}</td>
      <td> {i.buy_sell == 0 ? i.qty : "0"}</td>
      <td style={{ fontWeight: "bold" }}> {i.buy_sell == 0 ? i.rate : "0"}</td>
      <td> {i.buy_sell == 1 ? i.qty : "0"}</td>
      <td style={{ fontWeight: "bold" }}> {i.buy_sell == 1 ? i.rate : "0"}</td>
      <td
        style={{
          color: i.buy_sell == 0 ? NewTheme.greencolor : NewTheme.redcolor,
          fontWeight: "bold",
        }}
      >
        {i.buy_sell == 0 ? i.qty : "-" + i.qty}
      </td>
      <td style={{ padding: 0, height: "100%", minWidth: 75 }}>
        <AnimText value={i.buy_sell == 0 ? bid : ask} />
      </td>
      <td
        style={{
          fontWeight: "bold",
          color: pf >= 0 ? NewTheme.greencolor : NewTheme.redcolor,
        }}
      >
        {parseFloat(pf).toFixed(2)}
      </td>
      <td> {moment(i.date_created).format("DD-MMM hh:mm A")}</td>
      <td
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          style={{
            height: 25,
            fontSize: 13,
            paddingLeft: 15,
            paddingRight: 15,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            background: NewTheme.MainColor,
            color: "white",
            borderRadius: 5,
            borderStyle: "hidden",
          }}
          onClick={() => props.PosClose()}
        >
          Close
        </button>
      </td>
    </tr>
  );
}

const MapStateToProps = (state) => {
  return {
    theme: state.theme,
  };
};

const MapStateToDispatch = (dispatch) => {
  return {
    calc_pf: (r, h, b, total) => {
      dispatch({
        type: "PF",
        script_id: r,
        pf: h,
        btotal: b,
        total: total,
      });
    },
    selected: (a) => dispatch({ type: "WATCHLIST_SELECT", payload: a }),
  };
};

export default connect(MapStateToProps, MapStateToDispatch)(PositionCard);
