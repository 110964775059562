import {
  React,
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from "react";

import SocketContext from "../SocketContext";
import moment from "moment";

import Backend from "../Backend";
const backend = new Backend();

const Buy = (props) => {
  console.log(props.selected);
  console.log("props.slectd");

  const i = props.selected;
  const socket = useContext(SocketContext);

  const [chng, setchng] = useState(0);
  const [percent, setpercent] = useState(0);
  const [ask, setask] = useState(0);
  const [bid, setbid] = useState(0);
  const [high2, sethigh2] = useState(0);
  const [low2, setlow2] = useState(0);
  const [ltp, setltp] = useState(0);
  const [ticktime, SetTicktime] = useState(0);
  const [open, setopen] = useState(0);
  const [close, setclose] = useState(0);

  const [qtypos, setQtypos] = useState(0);
  const [idpos, setIdpos] = useState(0);
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState("");
  // const [loading, setLoading] = useState(false);
  const [buysell, setBuysell] = useState(2);

  const [option, setOption] = useState(0);
  const [atPrice, setAtPrice] = useState(0);
  const [limit, setlimit] = useState(true);
  useEffect(() => {
    // loadProfile();
    console.log(i);
    socket.emit("giverate", i.script_id);
    socket.on("trade" + i.script_id, trade);
    socket.on("bidask" + i.script_id, bidask);
    return () => {
      socket.off("trade" + i.script_id, trade);
      socket.off("bidask" + i.script_id, bidask);
    };
  }, []);

  const [qty, setQty] = useState(0);
  const onQtyHandler = (e) => {
    setQty(e.target.value);
  };

  const onSubmitHandler = () => {
    let data = {
      server: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      token: localStorage.getItem("token"),
      script_id: i.script_id,
      script_type: i.script_type,
      qty_order: i.script_type == "fut" ? qty : qty * i.lot_size,
      type: 0,
      symbol: i.symbol_display,
    };

    console.log(data);
    console.log("data");

    try {
      backend
        .trade_api(data)
        .then((r) => {
          // setLoading(false);
          console.log(r);
          if (r.error == "False") {
            // setPosition(r.position);
            // setPosition(r.position);
            console.log("successfully");
            console.log(r);

            // setPosition_Filter(r.position);
            // setBalance(r.balance);
            // setBooked(r.booked);
            // setBrokerage(r.brokerage);
            // setlimit(r.limit);
            // setmultiplier(r.multiplier);
            // props.clear_pf();
          }
          if (r.error == "True") {
            console.log("error hy bhai");
          } else {
            // alert(r.message);
            console.log("not found");
          }
        })
        .then((e) => {
          console.log("error here");
        })
        .catch((e) => {
          console.log("Failed to Fetch");
        });
    } catch {
      console.log("Something Wents Wrong");
    }
  };

  // new one from client

  const load_position_overview = () => {
    let newdata = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
      script_id: i.script_id,
      segment: i.script_type,
    };

    backend.load_position_overview(newdata).then((r) => {
      // console.log(r);
      if (r.error == "False") {
        setBuysell(r.buy_sell);
        setQtypos(r.qty);
        setIdpos(r.pos_id);
      }
      // console.log(r);
    });
  };

  // const PosClose = () => {
  //   if (!loading) {
  //     if (qtypos <= 0) {
  //       Alert.alert('', 'Qty Require Greater Then 0');
  //     } else {
  //       setLoading(true);
  //       let sendData = {
  //         m_code: data.script_id,
  //         order: qtypos,
  //         ud: buysell == 1 ? '0' : '1',
  //         segi: data.script_type,
  //         token: props.token,
  //       };

  //       backend
  //         .sqoffposition(sendData)
  //         .then(r => {
  //           setTimeout(() => {
  //             setLoading(false);
  //           }, 500);
  //           if (r.success == 'True') {
  //             load_position_overview();
  //             Alert.alert('', r.msg);
  //           } else {
  //             Alert.alert('', r.msg);
  //           }
  //         })
  //         .catch(err => {
  //           setTimeout(() => {
  //             setLoading(false);
  //           }, 500);
  //           console.log(err);
  //           // Alert.alert('', err);
  //         });
  //     }
  //   }
  // };

  const BuyFunction = () => {
    if (!loading) {
      if (qty <= 0) {
        Window.alert("Qty Require Greater Then 0");
      } else {
        setLoading(true);
        let sendData = {
          m_code: i.script_id,
          order: qty,
          ud: "1",
          segi: i.script_type,
          token: localStorage.getItem("token"),
        };

        backend
          .trade(sendData)
          .then((r) => {
            setTimeout(() => {
              setLoading(false);
            }, 500);
            if (r.success == "True") {
              // load_position_overview();
              window.alert(r.msg);
            } else {
              window.alert(r.msg);
            }
          })
          .catch((err) => {
            setTimeout(() => {
              setLoading(false);
            }, 500);
            console.log(err);
            // Alert.alert('', err);
          });
      }
    }
  };

  const BuyPending = () => {
    if (!loading) {
      if (qty <= 0) {
        window.alert("Qty Require Greater Then 0");
      } else if (atPrice <= 0) {
        window.alert("At Price Must Be Greater Then 0");
      } else {
        setLoading(true);
        let sendData = {
          m_code: i.script_id,
          order: qty,
          ud: "1",
          segi: i.script_type,
          at_price: atPrice,
          token: localStorage.getItem("token"),
        };

        backend
          .pending(sendData)
          .then((r) => {
            setTimeout(() => {
              setLoading(false);
            }, 500);
            // console.log(r);
            if (r.success == "True") {
              window.alert(r.msg);
            } else {
              window.alert(r.msg);
            }
          })
          .catch((err) => {
            setTimeout(() => {
              setLoading(false);
            }, 500);
            console.log(err);
            // Alert.alert('', err);
          });
      }
    }
  };

  const trade = (msg) => {
    // if (Ismouted) {
    if (msg == null) {
    } else {
      if (bid !== msg.Bid) {
        setbid(msg.Bid);
      }
      if (ask !== msg.Ask) {
        setask(msg.Ask);
      }
      if (high2 !== msg.High) {
        sethigh2(msg.High);
      }
      if (low2 !== msg.Low) {
        setlow2(msg.Low);
      }
      if (msg.LTP - msg.Previous_Close !== chng) {
        setchng(msg.LTP - msg.Previous_Close);
        setpercent(((msg.LTP - msg.Previous_Close) / msg.Previous_Close) * 100);
      }
      if (ltp !== msg.LTP) {
        setltp(msg.LTP);
      }
      if (time !== msg.Timestamp) {
        setTime(msg.Timestamp);
      }
      // }
    }
  };

  const bidask = (msg) => {
    // if (Ismouted) {
    if (bid !== msg.Bid) {
      setbid(msg.Bid);
    }
    if (ask !== msg.Ask) {
      setask(msg.Ask);
    }

    if (time !== msg.Timestamp) {
      setTime(msg.Timestamp);
    }
    //     }
  };

  const handleKeyPress = useCallback((event) => {
    console.log(`key pressed: ${event.key}`);
    if (event.key === "Enter") {
      if (option == 0) {
        BuyFunction();
      } else {
        BuyPending();
      }
    }
  });

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <>
      <div className="buy_sell_box" id="buy_box">
        <div className="buy_sell sell_border" style={{background:'white'}}>
          <div className="text sell_text">
            <p>SELL</p>
          </div>
          <div className="rightbox">
            <div className="topborder">
              <div className="display_name">
                <div className="inside_name">{i.symbol_display}</div>
                <div className="inside_date">
                  {moment(i.expiry_date).format("DD-MMM")}
                </div>
              </div>

              <div className="high_low_box">
                <div className="hl_box">
                  <div className="hl_title">HIGH</div>
                  <div className="hl_text">{parseFloat(high2).toFixed(2)}</div>
                </div>

                <div className="hl_box">
                  <div className="hl_title">LOW</div>
                  <div className="hl_text">{parseFloat(low2).toFixed(2)}</div>
                </div>
              </div>
            </div>
            <div className="mid_box">
              <div className="detail_item">
                <p className="detail_head"  style={{ width: 120 }}>Order Type</p>
                <select
                  className="detail_text order_type "
                  type="text"
                  value={option}
                  autoFocus={true}
                  onChange={(e)=>setOption(e.target.value)}
                >
                  <option value={0}>Market</option>
                  <option value={1}>Pending</option>
                </select>
              </div>
              <div className="detail_item">
                <p className="detail_head">Qty</p>
                <input
                  className="detail_text qty"
                  type="number"
                  onChange={onQtyHandler}
                  value={qty}
                />
              </div>
              <div className="detail_item">
                <p className="detail_head">Price</p>
                <input
                  disabled={option == 0}
                  className="detail_text qty"
                  type="number"
                  onChange={(e) => setAtPrice(e.target.value)}
                  value={atPrice}
                />
              </div>

              <div className="bid_ask_box"> 
                <div className="ba_inside">
                  <div className="ba_title">BID</div>
                  <div className="ba_text">{parseFloat(bid).toFixed(2)}</div>
                </div>

                <div className="ba_inside">
                  <div className="ba_title">ASK</div>
                  <div className="ba_text">{parseFloat(ask).toFixed(2)}</div>
                </div>
              </div>
              {/* <div style={{flex:0.71,boxSizing:'border-box',padding:3 ,borderStyle:'solid',borderWidth:0,borderLeftWidth:1,borderColor:'black'}}>
                <div style={{fontWeight:'bold',fontSize:12}}>BID</div>
                <div style={{color:'gray',fontWeight:'bold',fontSize:15}}>{parseFloat(bid).toFixed(2)}
                </div>
                
                </div> */}
              {/* 
                <div style={{flex:1,boxSizing:'border-box',padding:3 }}>
                <div style={{fontWeight:'bold',fontSize:12}}>ASK</div>
                <div style={{color:'gray',fontWeight:'bold',fontSize:15}}>{parseFloat(ask).toFixed(2)}
                </div>
                
                </div> */}
            </div>
            <div className="lower_bar">
              <div className="detail_item buttons">
                <button
                  className="submit_btn"
                  type="submit"
                  onClick={onSubmitHandler}
                >
                  Submit
                </button>
              </div>

              <div className="detail_item buttons">
                <button
                  className="create_btn"
                  type="submit"
                  onClick={props.onCancelHandler}
                >
                  Cancel
                </button>
              </div>

              <div
                style={{ flex: 1, width: "35%" }}
                className="detail_item buttons"
              >
                {/* <button
                className="submit_btn"
                type="submit"
                onClick={onSubmitHandler}
              >
                CLOSE
              </button> */}
              </div>
            </div>
            {/* <div className="details">
       
              <div className="detail_item">
                <p className="detail_head">Order Type</p>
                <select className="detail_text order_type" type="text">
                  {limit ? (
                    <>
                      <option>Market</option>
                      <option>Pending</option>
                    </>
                  ) : (
                    <>
                      <option>Market</option>
                      <option>Limit</option>
                      <option>Stock</option>
                    </>
                  )}
                </select>
              </div>
      
              <div className="detail_item">
                <p className="detail_head">Qty</p>
                <input
                  className="detail_text qty"
                  type="number"
                  onChange={onQtyHandler}
                  value={qty}
                />
              </div>
              <div className="detail_item">
                <p className="detail_head">Client</p>
                <input
                  className="detail_text client"
                  type="text"
                  disabled
                  value={JSON.parse(localStorage.client_details).name}
                />
              </div>
            </div> */}
            {/* <div className="buttons">
              <button
                className="submit_btn"
                type="submit"
                onClick={onSubmitHandler}
              >
                Submit
              </button>
              <button
                className="create_btn"
                type="submit"
                onClick={props.onCancelHandler}
              >
                Cancel
              </button>
            </div> */}
          </div>
          <div className="rightmost lower rightmost_sell">
            <div className="inside_lower">
              <div className="lower_title">
                {i.script_type == "fut" ? "MIN QTY" : "LOT SIZE"}
              </div>
              <div className="lower_text">
                {i.script_type == "fut" ? i.min_qty : i.lot_size}
              </div>
            </div>

            <div className="inside_lower">
              <div className="lower_title">QTY/ORDER</div>
              <div className="lower_text">{i.punch_qty}</div>
            </div>

            <div className="inside_lower">
              <div className="lower_title">TOTAL QTY</div>
              <div className="lower_text">{i.total_qty}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Buy;
