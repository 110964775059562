import { React, useState, useEffect, useCallback } from "react";
// import useWindowSize from 'usehooks-ts'

import logo from "../logo.svg";
import Backend from "../Backend";
import moment from "moment";

const backend = new Backend();

const Account = (props) => {
  const [ledger, setLedger] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ledgerdata, setLedgerdata] = useState("");
  const [ledgerSelected, setledgerSelected] = useState("");

  useEffect(() => {
    loadPosition();
  }, []);

  const loadPosition = () => {
    setLoading(true);
    let data = {
      // server: localStorage.getItem("server"),
      // id: localStorage.getItem("client"),
      // token: localStorage.getItem("token"),
      // ledger_id: localStorage.getItem("client"),
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
    };
    try {
      // backend
      //   .load_ledger(data)
      //   .then((r) => {
      //     setLoading(false);
      //     console.log(r);
      //     if (r.error == "False") {
      //       setLedger(r.ledger);
      //       // setPosition(r.position);
      //       console.log("HIhi me khud");
      //       console.log(ledger);
      //       console.log("__-___");
      //       // setPosition_Filter(r.position);
      //       // setBalance(r.balance);
      //       // setBooked(r.booked);
      //       // setBrokerage(r.brokerage);
      //       // setlimit(r.limit);
      //       // setmultiplier(r.multiplier);
      //       // props.clear_pf();
      //     }
      //     if (r.error == "True") {
      //       console.log("error hy bhai");
      //     } else {
      //       // alert(r.message);
      //       console.log("not found");
      //     }
      //   })
      //   .then((e) => {
      //     console.log("error here");
      //   })
      //   .catch((e) => {
      //     console.log("Failed to Fetch");
      //   });
      backend.loadledger(data).then((r) => {
        setTimeout(() => {
          setLoading(false);
          // refRBSheet.current.open();
        }, 1000);
        console.log("r");
        console.log(r);
        if (r.error == "False") {
          let finalObj = {};
          r.data.forEach((transaction) => {
            const date = moment(transaction.date_created).format("DD-MM-YY");
            if (finalObj[date]) {
              finalObj[date].push(transaction);
            } else {
              finalObj[date] = [transaction];
            }
          });
          // console.log(typeof(finalObj))
          // console.log(typeof(r.data))
          // setLedgerdata(finalObj);
          setLedgerdata(r.data);
        }
        // console.log(r);
      });
    } catch {
      console.log("Something Wents Wrong");
    }
  };
  console.log("position[0]");

  const handleKeyPress = useCallback((event) => {
    console.log(`key pressed: ${event.key}`);
    if (event.key === "ArrowUp") {
      console.log(ledger);
      console.log("position");
      setledgerSelected(ledger[ledger.indexOf(ledgerSelected) - 1]);
    }
    if (event.key === "ArrowDown") {
      console.log(ledger);
      console.log("position");
      setledgerSelected(ledger[ledger.indexOf(ledgerSelected) + 1]);
    }
  });
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);
  const onclickevent = useCallback((item) => {
    setledgerSelected(item);
  });

  console.log();
  return (
    <div className="Profile_box">
      <div className="Profile_header">
        <img src={logo} alt="" />
        <p>Ledger</p>
        <button onClick={props.closeAbout}>Close</button>
      </div>
      <div className="tableFixHead">
        <table className="table">
          <thead>
            <tr className="table_head">
              <th>AMOUNT</th>
              <th>REMARKS</th>
              <th>DEPOSIT / WITHDRAW</th>
              <th>NEW BALENCE</th>
              <th>OLD BALENCE</th>
              <th>DATE</th>
            </tr>
          </thead>
          <tbody
            style={{
              height: 20 + "!important",
              overflowY: "scroll",
            }}

            // style={{
            //   "height: 10 + !important, overflow: scroll"
            // // height: 10;  overFlow: "scroll"
            // }}
          >
            {ledgerdata &&
              ledgerdata.map((item, index) => (
                <tr
                  id={item.id}
                  onClick={() => onclickevent(item)}
                  style={{
                    backgroundColor: item === ledgerSelected ? "#fae7cd" : "",
                  }}
                >
                  <th>{item.amount}</th>

                  <td>{item.remarks}</td>
                  <td>{item.in_out == 0 ? "Deposit" : "Withdraw"}</td>
                  <td>{item.new_balance}</td>
                  <td>{item.old_balance}</td>
                  <td>{item.date_created}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Account;
