import React, { useState } from "react";
import logo from "../../logo.svg";
import "./Navigation.css";
import Trades from "../trades/Trades";
import Watchlist from "../watchlist/Watchlist";
import Position from "../position/Position";
import Profile from "../profile/Profile";
import Account from "../account/Account";
import SiTp from "../sitp/SiTp";

import bookmark from "../../Images/bookmark.png";
import briefcase from "../../Images/briefcase.png";
import trading from "../../Images/trading.png";
import order_history from "../../Images/order-history.png";
import profile from "../../Images/profile-user.png";
import { NewTheme } from "../../Theme/Theme";

const Navigation = (props) => {
  const navScreen = props.navScreen;

  // const [navScreen, setNavScreen] = useState(props.navScreen);

  return (
    <div
      style={{
        position: "fixed",
        height: 65,
        width: "100%",
        background: "white",
        bottom: 0,
        display: "flex",
        borderStyle: "solid",
        borderWidth: 0,
        borderTopWidth: 1,
        borderColor: "lightgray",
        zIndex: 99999,
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          // justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
          marginTop: 5,
        }}
        onClick={() => {
          // setNavScreen("profile");
          window.location = "/sitp";
        }}
      >
        <div
          style={{
            background: navScreen === "sitp" ? NewTheme.MainColor : "white",
            position: navScreen === "sitp" ? "absolute" : "unset",
            top: -25,
            borderRadius: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={briefcase}
            style={{
              height: 25,
              padding: 10,
              boxSizing: "content-box",
              width: 25,
              filter: navScreen === "sitp" ? "invert(1)" : "unset",
              marginTop: navScreen === "sitp" ? 0 : -10,
            }}
          />
        </div>

        <div
          style={{
            fontWeight: "bold",
            position: "absolute",
            bottom: navScreen === "sitp" ? 20 : 15,
            fontSize: 12,
            color: navScreen === "sitp" ? NewTheme.MainColor : "black",
          }}
        >
          SL/TP
        </div>
      </div>

      <div
        style={{
          flex: 1,
          display: "flex",
          // justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
          marginTop: 5,
        }}
        onClick={() => {
          // setNavScreen("profile");
          window.location = "/position";
        }}
      >
        <div
          style={{
            background: navScreen === "position" ? NewTheme.MainColor : "white",
            position: navScreen === "position" ? "absolute" : "unset",
            top: -25,
            borderRadius: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={briefcase}
            style={{
              height: 25,
              padding: 10,
              boxSizing: "content-box",
              width: 25,
              filter: navScreen === "position" ? "invert(1)" : "unset",
              marginTop: navScreen === "position" ? 0 : -10,
            }}
          />
        </div>

        <div
          style={{
            fontWeight: "bold",
            position: "absolute",
            bottom: navScreen === "position" ? 20 : 15,
            fontSize: 12,
            color: navScreen === "position" ? NewTheme.MainColor : "black",
          }}
        >
          Position
        </div>
      </div>

      <div
        style={{
          flex: 1,
          display: "flex",
          // justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
          marginTop: 5,
        }}
        onClick={() => {
          // setNavScreen("profile");
          window.location = "/";
        }}
      >
        <div
          style={{
            background:
              navScreen === "watchlist" ? NewTheme.MainColor : "white",
            position: navScreen === "watchlist" ? "absolute" : "unset",
            top: -25,
            borderRadius: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={bookmark}
            style={{
              height: 25,
              padding: 10,
              boxSizing: "content-box",
              width: 25,
              filter: navScreen === "watchlist" ? "invert(1)" : "unset",
              marginTop: navScreen === "watchlist" ? 0 : -10,
            }}
          />
        </div>

        <div
          style={{
            fontWeight: "bold",
            position: "absolute",
            bottom: navScreen === "watchlist" ? 20 : 15,
            fontSize: 12,
            color: navScreen === "watchlist" ? NewTheme.MainColor : "black",
          }}
        >
          WatchList
        </div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          // justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
          marginTop: 5,
        }}
        onClick={() => {
          // setNavScreen("profile");
          window.location = "/trades";
        }}
      >
        <div
          style={{
            background: navScreen === "trades" ? NewTheme.MainColor : "white",
            position: navScreen === "trades" ? "absolute" : "unset",
            top: -25,
            borderRadius: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={order_history}
            style={{
              height: 25,
              padding: 10,
              boxSizing: "content-box",
              width: 25,
              filter: navScreen === "trades" ? "invert(1)" : "unset",
              marginTop: navScreen === "trades" ? 0 : -10,
            }}
          />
        </div>

        <div
          style={{
            fontWeight: "bold",
            position: "absolute",
            bottom: navScreen === "trades" ? 20 : 15,
            fontSize: 12,
            color: navScreen === "trades" ? NewTheme.MainColor : "black",
          }}
        >
          Trades
        </div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          // justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
          marginTop: 5,
        }}
        onClick={() => {
          // setNavScreen("profile");
          window.location = "/profile";
        }}
      >
        <div
          style={{
            background: navScreen === "profile" ? NewTheme.MainColor : "white",
            position: navScreen === "profile" ? "absolute" : "unset",
            top: -25,
            borderRadius: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={profile}
            style={{
              height: 25,
              padding: 10,
              boxSizing: "content-box",
              width: 25,
              filter: navScreen === "profile" ? "invert(1)" : "unset",
              marginTop: navScreen === "profile" ? 0 : -10,
            }}
          />
        </div>

        <div
          style={{
            fontWeight: "bold",
            position: "absolute",
            bottom: navScreen === "profile" ? 20 : 15,
            fontSize: 12,
            color: navScreen === "profile" ? NewTheme.MainColor : "black",
          }}
        >
          Profile
        </div>
      </div>
    </div>
    // <>
    //   <div
    //     id="helper"
    //     className="Nav_Container"
    //     style={{
    //       overflowY: "hidden",
    //       position: "fixed",
    //       bottom: 0,
    //       height: 80,
    //     }}
    //   >
    //     <div
    //       className="nav_div"
    //       onClick={() => {
    //         // setNavScreen("sitp");
    //         window.location = "/sitp";
    //       }}
    //     >
    //       <div
    //         style={{ marginTop: -50 }}
    //         className={navScreen === "sitp" ? "nav_selected" : ""}
    //       >
    //         <img
    //           src={trading}
    //           className="nav_img"
    //           style={{
    //             height: 20,
    //             width: 20,

    //             filter: navScreen === "sitp" ? "invert(1)" : "unset",
    //           }}
    //           alt=""
    //         />
    //       </div>
    //       <p
    //         className="nav_text"
    //         style={{
    //           color: navScreen === "sitp" ? "#14877a" : "",
    //         }}
    //       >
    //         Sl/Tp
    //       </p>
    //     </div>

    //     <div
    //       className="nav_div"
    //       onClick={() => {
    //         // setNavScreen("position");
    //         window.location = "/position";
    //       }}
    //     >
    //       <div className={navScreen === "position" ? "nav_selected" : ""}>
    //         <img
    //           src={briefcase}
    //           style={{
    //             height: 20,
    //             width: 20,
    //             filter: navScreen === "position" ? "invert(1)" : "unset",
    //           }}
    //         />
    //       </div>
    //       <p
    //         className="nav_text"
    //         style={{
    //           color: navScreen === "position" ? "#14877a" : "",
    //         }}
    //       >
    //         Position
    //       </p>
    //     </div>
    //     <div
    //       className="nav_div"
    //       onClick={() => {
    //         // setNavScreen("watchlist");
    //         window.location = "/";
    //       }}
    //     >
    //       <div className={navScreen === "watchlist" ? "nav_selected" : ""}>
    //         <img
    //           src={bookmark}
    //           // className="nav_img"
    //           alt=""
    //           style={{
    //             height: 20,
    //             width: 20,
    //             filter: navScreen === "watchlist" ? "invert(1)" : "unset",
    //           }}
    //         />
    //       </div>
    //       <p
    //         className="nav_text"
    //         style={{
    //           color: navScreen === "watchlist" ? "#14877a" : "",
    //           marginTop: -5,
    //           marginBottom: 5,
    //         }}
    //       >
    //         Watchlist
    //       </p>
    //     </div>
    //     <div
    //       className="nav_div"
    //       onClick={() => {
    //         // setNavScreen("trades");
    //         //   return(
    //         //     // <Trades/>
    //         //   )
    //         window.location = "/trades";
    //       }}
    //     >
    //       <div className={navScreen === "trades" ? "nav_selected" : ""}>
    //         <img
    //           src={order_history}
    //           style={{
    //             height: 20,
    //             width: 20,
    //             filter: navScreen === "trades" ? "invert(1)" : "unset",
    //           }}
    //         />
    //       </div>
    //       <p
    //         className="nav_text "
    //         style={{
    //           color: navScreen === "trades" ? "#14877a" : "",
    //         }}
    //       >
    //         Trades
    //       </p>
    //     </div>
    //     <div
    //       className="nav_div"
    //       onClick={() => {
    //         // setNavScreen("profile");
    //         window.location = "/profile";
    //       }}
    //     >
    //       <div className={navScreen === "profile" ? "nav_selected" : ""}>
    //         <img
    //           src={profile}
    //           style={{
    //             height: 20,
    //             width: 20,
    //             filter: navScreen === "profile" ? "invert(1)" : "unset",
    //           }}
    //           alt=""
    //         />
    //       </div>
    //       <p
    //         className="nav_text"
    //         style={{
    //           color: navScreen === "profile" ? "#14877a" : "",
    //         }}
    //       >
    //         Profile
    //       </p>
    //     </div>
    //   </div>
    //   {/* navigation  */}
    //   {/* {navScreen == "trades" ? <Trades /> : ""}
    //   {navScreen == "position" ? <Position /> : ""}
    //   {navScreen == "watchlist" ? <Watchlist /> : ""}
    //   {navScreen == "sitp" ? <SiTp /> : ""}
    //   {navScreen == "profile" ? <Profile /> : ""} */}
    // </>
  );
};

export default Navigation;
