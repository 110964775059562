import React from "react";
import logo from "../../Images/down-arrow.png";
import { FaCircleArrowDown } from "react-icons/fa6";
import { NewTheme } from "../../Theme/Theme";
import moment from "moment";

export default function SltpCard(props) {
  const i = props.data;
  return (
    <div
      onClick={() => props.opensheet(i)}
      id={i.id}
      style={{
        width: "100%",
        // background: "green",
        // height: 60,
        padding: 0,
        margin: 0,
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderColor: "lightgray",
        display: "flex",
        paddingTop: 5,
      }}
    >
      <div
        style={{
          width: 70,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FaCircleArrowDown
          size={24}
          style={{
            color: i.buy_sell == "0" ? NewTheme.greencolor : NewTheme.redcolor,
            transform: i.buy_sell == "0" ? "rotate(225deg)" : "rotate(45deg)",
          }}
        />
        <div style={{ fontWeight: "bold", fontSize: 12 }}>{i.qty}</div>
      </div>
      <div
        style={{
          flex: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          //   alignItems: "center",
        }}
      >
        <div style={{ fontWeight: "bold", fontSize: 15, display: "flex" }}>
          {i.symbol}
          <div style={{ fontSize: 11, color: "gray", paddingLeft: 5 }}>
            {moment(i.expiry_date).format("DD-MMM")}
          </div>
        </div>
        <div
          style={{
            fontWeight: "bold",
            fontSize: 15,
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ fontWeight: "bold", fontSize: 13 }}>
            {i.buy_sell == "0" ? "BUY" : "SELL"}
          </div>
          <FaCircleArrowDown
            size={13}
            style={{
              color:
                i.buy_sell == "0" ? NewTheme.greencolor : NewTheme.redcolor,
              transform: "rotate(-90deg)",
              marginLeft: 5,
            }}
          />
          <div style={{ fontWeight: "bold", fontSize: 13, marginLeft: 5 }}>
            {parseFloat(i.price)}
          </div>
        </div>

        <div style={{ fontWeight: "bold", fontSize: 11, color: "gray" }}>
          {i.process_msg}
          <span style={{ color: "rgba(0,0,0,0" }}> hh</span>
        </div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          padding: 5,
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            color:
              i.status == 0
                ? "gray"
                : i.status == 2
                ? NewTheme.redcolor
                : NewTheme.greencolor,
            fontSize: 14,
          }}
        >
          {i.status == 0 ? "Pending" : i.status == 1 ? "Executed" : "Rejected"}
        </div>
        <div style={{ fontWeight: "bold", color: "gray", fontSize: 11 }}>
          {moment(i.date_created).format("HH:mm:ss A")}
        </div>
      </div>
    </div>
    // <div className="data_item" style={{ borderTopWidth: 1 }}>
    //   <div className="data_lot_part">
    //     {/* <p className="lot_text">607090</p> */}
    //     <FaCircleArrowDown
    //       size={24}
    //       style={{ color: "green", transform: "rotate(225deg)" }}
    //     />
    //     <p className="l_text">-8.0 LOT</p>
    //   </div>
    //   <div className="data_script_part">
    //     <p className="script_text">
    //       Gold <span>05-Dec</span>
    //     </p>
    //     <p className="rate_text">B: 607090 C:09876</p>
    //     <p className="expiry_text">20-09-09 7:09:09</p>
    //   </div>
    //   <div className="data_value_part">
    //     <p className="value_text">607090</p>
    //     <p className="brok_text">Brok: -90.00</p>
    //     <p className="add_text">ADD</p>
    //   </div>
    // </div>
  );
}
