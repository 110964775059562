import { React, useCallback, useEffect, useState, useRef } from "react";

// import "../css/Watchlist.scss";
// import logo from "../logo.svg";
import logo from "../Images/Images/logo.png";
import watchlist from "../Images/Images/bookmark.png";

import position from "../Images/Images/briefcase.png";

import trades from "../Images/Images/order-history.png";
import sitp from "../Images/Images/trading.png";
import profile from "../Images/Images/profile-user.png";
import logout from "../Images/Images/logout.png";
import bill from "../Images/Images/invoice.png";
import rejection from "../Images/Images/palm-of-hand.png";
import ledger from "../Images/Images/profit.png";

import Backend from "../Backend";
import WatchItem from "./WatchItem";
import Buy from "./Buy";
import Sell from "./Sell";
import './watchlist.css'

// import { useSpring, animated } from 'react-spring';
// const DraggedItem = ({ item, drag }) => {
//   const styles = useSpring({
//     transform: drag ? 'scale(1.1)' : 'scale(1)',
//   });

import ReactModal from "react-modal-resizable-draggable";

import Transaction from './Transaction/Transaction'
// import Ledger from "./Ledger";
import Pending from "./Pending";
import Rejection from "./Rejection";
import Account from "./Account";
import About from "./About";
import Position from "./Position/Position";
import moment from "moment";
import Ledger from "./Ledger/Ledger";
import { NewTheme } from "./Theme/Theme";
import { useWindowSize } from "usehooks-ts";
import Bill from "./Bill";
import Marquee from "./Marquee";

// import (`../resource/env/${process.env.REACT_APP_NAME}/env.scss`);

const backend = new Backend();

const Watchlist = () => {


  const {width,height} = useWindowSize()
  var [buyState, setbuyState] = useState(false);
  var [sellState, setsellState] = useState(false);
  // seach forcuc
  var [focusSearch, setFocusSearch] = useState(false);

  var [searchState, setsearchState] = useState("");
  const [search, setSearch] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [focusMode, setFocusMode] = useState("list");


  const [scriptselect, setscriptselect] = useState("");
  const [list, setList] = useState(
    typeof localStorage.localSearch === "undefined"
      ? []
      : JSON.parse(localStorage.localSearch)
  );

  if (
    localStorage.IsLoggedIn !== "1" ||
    typeof localStorage.IsLoggedIn === "undefined"
  ) {
    window.location = "/login";
  }
  // if (typeof localStorage.localSearch !== "undefined"){
  // setList(JSON.parse(localStorage.localSearch))
  // }

  // if (localStorage.localSearch==null)
  // console.log(localStorage.localSearch)
  // useEffect(() => {
  //   try {
  //     setList(JSON.parse(localStorage.localSearch))
  //   } catch {
  //     setList([])
  //   }
  // })

  // JSON.parse(localStorage.localSearch)
  const [selected, setSelected] = useState(list[0]);
  // const [selected, setSelected] = useState(list[0])

  const [searchSelected, setSearchSelected] = useState("");



  const clearwatchlist =()=>{
    list.map(i=>{
      removescript(i)
    })
    
  }
  const onSearchHandler = (e) => {
    console.log("searching");
    setSearch(e.target.value);
    setFocusMode("search");
    setSelected("");
    // console.log(search)
    // console.log("search")
    if (search.length > 1) {
      console.log(e);
      let data = {
        // server: localStorage.getItem("server"),
        sv: localStorage.getItem("server"),
        id: localStorage.getItem("client"),
        // token: localStorage.getItem("token"),
        jwt: localStorage.getItem("token"),
        // name: search,
        filter: search,
      };
      try {
        backend
          // .find_script(data)
          .searchscript(data)
          .then((r) => {
            console.log(r);
            if (r.error == "False") {
              console.log("Innside");
              console.log(r);
              if (r.datas.length > 0) {
                var setdata = JSON.parse(localStorage.getItem("localSearch"))
                if(setdata ==null){
                  setdata=[]
                }
                var newdata = []
                r.datas.map((i)=>{

                  var found = setdata.filter(x=>x.id == i.id)
                  if(found.length == 0){
                    newdata.push(i)
                  }
                 
                })
                // console.log("not null");
                setSearchList(newdata);
                setSearchSelected(newdata[0].id);
                console.log("Hello", r.datas[0].id);
              }
              if (r.datas.length === 0) {
                setSearchSelected("");
                console.log("inside zero");
                setSearchList("");
                // setSelected("")
              }
            }
          })
          .then((e) => {
            console.log("Error here");
            console.log(e);
          })
          .catch((e) => {
            console.log("Failed to Fetch");
          });
      } catch {
        console.log("farig");
      }
    }
    if (search.length <= 2) {
      console.log("Ab to kam hy");
      setSearchList("");
      setSelected("");
      setSearchSelected("");
    }
  };

  const onCancelHandler = () => {
    console.log("Now we pressed the cancel");
    setsellState(false);
    setbuyState(false);
    setFocusMode("list");
    document.getElementById("search_box").blur();
    setSearch("");
  };
  const handleKeyPress = useCallback((event) => {
    if (event.key === "+") {
 
      if(transactionOpen || profileOpen||ledgerOpen||aboutOpen ||pendingOpen||rejectionOpen){
        return false
      }
      if(buyState || sellState){
        return false
      }
      if(focusMode =="list"){
        if(selected){
          setbuyState(true);
          setsellState(false);
          console.log(buyState);
        }
      }else{
        event.preventDefault()
      }
     
    
    }
    
     if(event.key=="Delete"){
      if(transactionOpen || profileOpen||ledgerOpen||aboutOpen ||pendingOpen||rejectionOpen){
        return false
      }
      if(buyState || sellState){
        return false
      }
      if(focusMode =="list"){
        if(selected){
        removescript(selected)
          // removeHandler(selected.id)

        }}
     }
    
    if (event.key === "-") {
      if(transactionOpen || profileOpen||ledgerOpen||aboutOpen ||pendingOpen||rejectionOpen){
        return false
      }
      if(buyState || sellState){
        return false
      }
      console.log("now a pressed");
      if(focusMode =="list"){
      if(selected){
      setsellState(true);
      setbuyState(false);
      }else{
        event.preventDefault()
      }
    }
      console.log(sellState);
    }
    if (event.key === "Escape") {
      if(transactionOpen || profileOpen||ledgerOpen||aboutOpen ||pendingOpen||rejectionOpen){
        return false
      }
      if(buyState || sellState){
        return false
      }
      console.log("now a pressed");
      setsellState(false);
      setbuyState(false);
      setFocusMode("list");
      document.getElementById("search_box").blur();
      setSearch("");

      // console.log(sellState)
      // document.getElementById('search_box').blur()
    }
    if (event.key === "s" && event.ctrlKey) {
      event.preventDefault()
      console.log("now f pressed");

      document.getElementById("search_box").focus();
      setSelected("");
    }
    if (event.key === "ArrowUp") {
      if(transactionOpen || profileOpen||ledgerOpen||aboutOpen ||pendingOpen||rejectionOpen){
        return false
      }
      if(buyState || sellState){
        return false
      }
      if (!sellState && !buyState && !modalIsOpen) {
        if (focusMode === "list" && focusSearch === false) {
       
          setSelected(list[list.indexOf(selected) - 1]);
          if (typeof selected === "undefined") {
            setSelected(list[list.length - 1]);
          }
        }
        if (focusMode === "search") {
         
          // else
          try {
            
            var addit = searchList.filter(function (item) {
              return parseInt(item.id) === parseInt(searchSelected);
            });
            for (let index = 0; index < searchList.length; index++) {
              const element = searchList[index];
              if (addit[0] === element) {
                setscriptselect(searchList[index - 1])
                setSearchSelected(searchList[index - 1].id);
              }
            }
          } catch {
            if (searchList.length > 0) {
              setSearchSelected(searchList[0].id);
              setscriptselect(searchList[0])
            }
          }
        }
      }
      // console.log("Arrow is down")
    }
    if (event.key === "ArrowDown") {
       if(transactionOpen || profileOpen||ledgerOpen||aboutOpen ||pendingOpen||rejectionOpen){
        return false
      }
      if(buyState || sellState){
        return false
      }
      if (!sellState && !buyState && !modalIsOpen) {
        if (focusMode === "list" && focusSearch === false) {
         
          setSelected(list[list.indexOf(selected) + 1]);
          
        }

        // if (focusMode === "search") {
        //   console.log("down search");
        //   try {
        //     console.log("Down");
        //     var addit = searchList.filter(function (item) {
        //       console.log("item ", item);
        //       return parseInt(item.id) === parseInt(searchSelected);
        //     });
        //     for (let index = 0; index < searchList.length; index++) {
        //       const element = searchList[index];
        //       // console.log(index);
        //       // console.log(element);
        //       console.log("down search 1", searchList[index + 1].id);
        //       console.log("down 98", addit[0]);
        //       console.log("down 98 addit", addit);
        //       console.log("down element", element);
        //       console.log("down 99", searchList[index + 1].id);
        //       if (addit[0] === element) {
        //         console.log("This one");
        //         setSearchSelected(searchList[index + 1].id);
        //         console.log("down search 3", searchList[index + 1].id);
        //       }
        //       if (addit[0] !== element) {
        //         console.log("This one");
        //         setSearchSelected(searchList[index + 1].id);
        //         console.log("down search 3", searchList[index + 1].id);
        //       }
        //     }
        //   } catch {
        //     if (searchList.length > 0) {
        //       setSearchSelected(searchList[0].id);
        //     }
        //   }
        //   console.log("searchSelected");
        //   console.log(searchSelected);
        // }
        if (focusMode === "search") {
        
          // else
          try {
            console.log("Down");
            var addit = searchList.filter(function (item) {
              return parseInt(item.id) === parseInt(searchSelected);
            });
            for (let index = 0; index < searchList.length; index++) {
              const element = searchList[index];
              if (addit[0] === element) {
                setSearchSelected(searchList[index + 1].id);
                setscriptselect(searchList[index + 1])
              }
            }
          } catch {
            if (searchList.length > 0) {
              setSearchSelected(searchList[0].id);
              setscriptselect(searchList[0])
            }
          }
        }
      }
    }
    if (event.key === "E" && event.ctrlKey) {
      ondragable();
    }
    if (event.key === "Enter") {

      if(transactionOpen || profileOpen||ledgerOpen||aboutOpen ||pendingOpen||rejectionOpen){
        return false
      }
      if(buyState || sellState){
        return false
      }


      // ondragable()
    
      if (focusMode === "search") {
        setFocusMode("list");

      
          if(scriptselect ==""){
            addscript(searchList[0])
            
          }else{
            addscript(scriptselect)
            
          }
        

     
          // var found = searchList.filter(x=>x.id = searchSelected)

          // addscript(found[0])
        // if (localStorage.getItem("localSearch") !== null) {
        //   var localitem = JSON.parse(localStorage.localSearch);
        //   var found = JSON.parse(localStorage.localSearch).filter(function (
        //     item
        //   ) {
        //     return parseInt(item.id) === parseInt(searchSelected);
        //   });
        //   if (found.length < 1) {
        //     var addit = searchList.filter(function (item) {
        //       return parseInt(item.id) === parseInt(searchSelected);
        //     });
        //     // a.push(addit)
        //     a = JSON.parse(localStorage.getItem("localSearch")) || [];
        //     a.push(addit[0]);
        //     localStorage.setItem("localSearch", JSON.stringify(a));
        //     setList(JSON.parse(localStorage.localSearch));
        //     // localStorage.setItem("localSearch", JSON.stringify(addit[0]));
        //   }
        // } else {
        //   var addit = searchList.filter(function (item) {
        //     return parseInt(item.id) === parseInt(searchSelected);
        //   });
        //   // a.push(JSON.parse(localStorage.getItem('localSearch')));
        //   // localStorage.setItem("localSearch", JSON.stringify(addit[0]));
        //   localStorage.setItem("localSearch", JSON.stringify(addit));
        //   setList(JSON.parse(localStorage.localSearch));
        // }
      }
    }
    if (event.key === "F6" && event.altKey) {
      event.preventDefault()
      // ondragable();
      openProfile();
    }
    if (event.key === "F8") {
      event.preventDefault()
      openTransaction();
    }
    if (event.key === "F3") {
      event.preventDefault()
      openPending();
    }
    if (event.key === "l" && event.altKey) {
      openLedger();
    }
    if (event.key === "r" && event.altKey) {
      openRejection();
    }
    if (event.key === "p" && event.altKey) {
      openAbout();
    }
  });

useEffect(()=>{

  loadwatchlist()
},[])

  useEffect(() => {
   
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  // drag and drop feature
  const dragItem = useRef();
  const dragOverItem = useRef();
  // console.log( "json"+JSON.parse(localStorage.localSearch))

  const [isDragging, setIsDragging] = useState(false);

  const [dragColor, setDragColor] = useState("");
  const dragStart = (e, position) => {
    setIsDragging(true);

    console.log(e);
    // return false;
    if (dragable) {
      dragItem.current = position;
      setDragColor(list[list.indexOf(e.target.id)]);
      setSelected(list[list.indexOf(e.target.id)]);
      console.log("target" + e.target.innerHTML);
      console.log("Start here" + e.target.id);
      setSelected(list[list.indexOf(e.target.id)]);
    }
  };


  const loadwatchlist =()=>{
    let data = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
      filter: '4',
    };

    backend.loadwatchlist(data).then(r => {
      // setTimeout(() => {
      //   setLoading(false);
      //   // refRBSheet.current.open();
      // }, 50);
      // console.log(r);

      if (r.error == 'False') {
        // props.setData(r.data);
        localStorage.setItem('localSearch', JSON.stringify(r.data));
        setList(r.data);
      }
      // console.log(r);
    });


  }

  const dragEnter = (e, position) => {
    console.log(e);
    setIsDragging(false);

    // return false;

    if (dragable) {
      dragOverItem.current = position;

      console.log(e.target.innerHTML);
      // console.log("not selected")
    }
  };
  const [changelist, setchangelist] = useState(false);
  const drop = (e) => {
    console.log(e);
    // return false;

    if (dragable) {
      const copyListItems = [...list];
      const dragItemContent = copyListItems[dragItem.current];
      copyListItems.splice(dragItem.current, 1);
      copyListItems.splice(dragOverItem.current, 0, dragItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      setList(copyListItems);
    
      setchangelist(true);
      savewatchlist(copyListItems)

     // localStorage.setItem("localSearch", JSON.stringify(copyListItems));


    }
  };

  const savewatchlist = (listed) => {

  // listed.map((i,t)=>{
  //   listed[t].series=t+1
  // })

    let data = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
      datas: listed,
    };

    backend
      .rearrangewatchlist(data)
      .then(r => {
       
        console.log(r)
     

        // if (r.error == 'False') {
        //   window.alert(r.message);

        //   //code here
        // } else {
        //   window.alert(r.message);
        // }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const onclickevet = useCallback((item) => {
    if (focusMode !== "search") {
      // console.log("heree" + item);
      console.log("focusmode");
      // console.log(document.getElementById("item"))
      // console.log(typeof item);
      // console.log(item);
      // console.log(selected);
      // console.log(typeof selected);
      setSelected(item);
    }
  });
  const [dragable, setDragable] = useState(true);
  const ondragable = () => {
    if (dragable) {
      setDragable(false);
    }
    if (!dragable) {
      setDragable(true);
    }
  };
  // const onnotdragable = () => {
  //   setDragable(false)
  // }
  var a = [];
  const removeHandler = (e) => {
    console.log("inside the remove Handler");
    console.log("Remove ID", e);

    var removeIt = list.filter(function (item) {
      console.log("find", item.id);
      return parseInt(item.id) === parseInt(e);
    });
    console.log("REmove It");
    console.log(removeIt);
    console.log("List");
    console.log(list);
    let listToDelete = [e];
    for (var i = 0; i < list.length; i++) {
      var obj = list[i];
      console.log("obj");
      console.log(obj);
      if (listToDelete.indexOf(obj.id) !== -1) {
        list.splice(i, 1);
        console.log("todelete");
        // console.log(list);
      }
    }

    // var b = JSON.parse(localStorage.getItem("localSearch")) || [];
    // // b.pop(removeIt[0]);
    localStorage.setItem("localSearch", JSON.stringify(list));
    setList(JSON.parse(localStorage.localSearch));

    // var listUpdate = list.filter(function(item){
    //   console.log("find",item.id)
    //   if (parseInt(item.id) === parseInt(e)){

    //   }
    // })
  };


  const removescript = (i) => {

    let data = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
      removeid: i.id,
    };

    backend.removescript(data).then(r => {
   

      if (r.error == 'False') {
       loadwatchlist()
        // setSearchData(r.data);
      }
      // console.log(r);
    });
  };

  const addscript = (datas) => {

    var found = list.filter(x=>x.script_id == datas.script_id)
    if(found.length>0){
      return false
    }
 
   let data = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
      script: datas,
      index: '4',
    };

    backend.addscript(data).then(r => {
   

console.log(r)
      if (r.error == 'False') {
        setSearchList([])
        loadwatchlist()
        // SetFind(1);
        // setSearchData(r.data);
      }
      // console.log(r);
    });
  };



  const addSearchHandler = (e,index,items) => {
   
if(localStorage.getItem('localSearch')==null || JSON.parse(localStorage.getItem('localSearch')).length ==0){
  var newdata = []
  newdata.push(items)
  localStorage.setItem("localSearch",JSON.stringify(newdata))
  setList(newdata)
  setSearchList([])
  addscript(items)



}else{
  var searchdata = JSON.parse(localStorage.getItem('localSearch'))
  var found = searchdata.filter(x=>x.id == items.id)

  // alert(found.length)
  // return false

  if(found.length>0){
    alert("Already Added")
    setSearchList([])
  }else{
    searchdata.push(items)
    localStorage.setItem("localSearch",JSON.stringify(searchdata))
    setList(searchdata)
    setSearchList([])


  }
}
// localStorage.removeItem('localSearch')
    // if(localStorage.getItem("localSearch"))

    // console.log("inside the eeeel");
    // setFocusMode("list");
    // console.log(" e.target.id");
    // console.log(items.id);
    // console.log(" e.target.id");
    // var addit = searchList.filter(function (item) {
    //   return parseInt(item.id) === parseInt(items.id);
    // });
    // console.log("addit");
    // console.log(addit);
    // console.log("search list");
    // console.log(searchList);

    // if (
    //   localStorage.getItem("localSearch") !== null
    //   //  && localStorage.getItem("localSearch")!=='[null]' && localStorage.getItem("localSearch")!==NaN
    // ) {
    //   var found = JSON.parse(localStorage.localSearch).filter(function (item) {
    //     return parseInt(item.id) === parseInt(items.id);
    //   });
    //   if (found.length != 0) {
    //     var addit = searchList.filter(function (item) {
    //       return parseInt(item.id) === parseInt(items.id);
    //     });
    //     // a.push(addit)
    //     a = JSON.parse(localStorage.getItem("localSearch")) || [];
    //     a.push(addit[0]);
    //     localStorage.setItem("localSearch", JSON.stringify(a));
    //     setList(JSON.parse(localStorage.localSearch));
    //     // localStorage.setItem("localSearch", JSON.stringify(addit[0]));
    //   }
    // } else {
    //   var addit = searchList.filter(function (item) {
    //     return parseInt(item.id) === parseInt(items.id);
    //   });
    
    //   // a.push(JSON.parse(localStorage.getItem('localSearch')));
    //   // localStorage.setItem("localSearch", JSON.stringify(addit[0]));
    //   localStorage.setItem("localSearch", JSON.stringify(addit));
    //   setList(JSON.parse(localStorage.localSearch));
    // }
  };
  const onTableClickHandler = () => {
    setFocusMode("list");
  };

  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [profileOpen, setprofileOpen] = useState(false);

  const openProfile = () => {
    setmodalIsOpen(true);
    setprofileOpen(true);
  };
  const closeProfile = () => {
    setmodalIsOpen(false);
    setprofileOpen(false);
  };

  const [transactionOpen, settransactionOpen] = useState(false);

  const openTransaction = () => {
    setmodalIsOpen(true);
    settransactionOpen(true);
  };
  const closeTransaction = () => {
    setmodalIsOpen(false);
    settransactionOpen(false);
  };

  const [ledgerOpen, setLedgerOpen] = useState(false);

  const openLedger = () => {
    setmodalIsOpen(true);
    setLedgerOpen(true);
  };
  const closeLedger = () => {
    setmodalIsOpen(false);
    setLedgerOpen(false);
  };

  const [aboutOpen, setAboutOpen] = useState(false);

  const openAbout = () => {
    setmodalIsOpen(true);
    setAboutOpen(true);
  };
  const closeAbout = () => {
    setmodalIsOpen(false);
    setAboutOpen(false);
  };

  const [pendingOpen, setPendingOpen] = useState(false);
  const [billopen, setbillopen] = useState(false);

  const openPending = () => {
    setmodalIsOpen(true);
    setPendingOpen(true);
  };
  const closePending = () => {
    setmodalIsOpen(false);
    setPendingOpen(false);
  };

  const openbillmodel = () => {
    setmodalIsOpen(true);
    setbillopen(true);
  };
  const closebillmodel = () => {
    setmodalIsOpen(false);
    setbillopen(false);
  };
  const [rejectionOpen, setRejectionOpen] = useState(false);

  const openRejection = () => {
    setmodalIsOpen(true);
    setRejectionOpen(true);
  };
  const closeRejection = () => {
    setmodalIsOpen(false);
    setRejectionOpen(false);
  };

  const logoutHandler = () => {
    // window.confirm("Are You Sure.");
    var txt;
    if (window.confirm("Are You Sure Want To Logout!")) {
      txt = "You pressed OK!";

      var myItem = localStorage.getItem('version');
localStorage.clear();
localStorage.setItem('version',myItem);

      // localStorage.removeItem("IsLoggedIn");
      // localStorage.removeItem("token");
      // localStorage.removeItem("rules");
      // localStorage.removeItem("server");
      // localStorage.removeItem("client");
      window.location = "/";
    } else {
      txt = "You pressed Cancel!";
    }

    // localStorage.removeItem("IsLoggedIn");
    // localStorage.removeItem("token");
    // localStorage.removeItem("rules");
    // localStorage.removeItem("server");
    // localStorage.removeItem("client");
    // window.location = "/";
  };

  return (
    <div
      className="DashbordContainer"
      id="DashbordContainer"
     
      // style={{
      //   overflow:modalIsOpen? "hidden":""
      // }}
    >
    
      <div
        className="header"
        style={{
          pointerEvents: buyState || sellState || modalIsOpen ? "none" : "",
          // overFlow: modalIsOpen ? "hidden": ""
          // color: isActive ? 'white' : '',
          /* pointer-events: none; */
        }}
      >
        <div className="top_bar">
          <div
            className="left"
            onClick={() => {
              window.location = "/";
            }}
          >
            <img className="logo" src={logo} alt="" />
            <p>DreamTrade</p>
          </div>
          <div className="mid">
            <div>
              <img className="logo" src={watchlist} alt="" />
              <p>WatchList</p>
            </div>
            <div onClick={openProfile}>
              <img className="logo" src={position} alt="" />
              <p>Position</p>
            </div>
            <div onClick={openTransaction}>
              <img className="logo" src={trades} alt="" />
              <p>Transaction</p>
            </div>
            <div onClick={openPending}>
              <img className="logo" src={sitp} alt="" />
              <p>Pending</p>
            </div>
            <div onClick={openLedger}>
              <img className="logo" src={ledger} alt="" />
              <p>Ledger</p>
            </div>
            <div onClick={openRejection}>
              <img className="logo" src={rejection} alt="" />
              <p>Rejection</p>
            </div>
            <div onClick={openAbout}>
              <img className="logo" src={profile} alt="" />
              <p>{JSON.parse(localStorage.getItem('client_details')).username}</p>
            </div>
            <div onClick={logoutHandler}>
              <img className="logo" src={logout} alt="" />
              <p>Logout</p>
            </div>
            <div onClick={openbillmodel}>
              <img className="logo" src={bill} alt="" />
              <p>Bill</p>
            </div>
          </div>
          {/* <div className="right">
            <div>
              <p>19600.00</p>
              <span>56.00</span>
              <p>Your trade</p>
            </div>
            <div>
              <p>19600.00</p>
              <span>56.00</span>
              <p>Your trade</p>
            </div>
          </div> */}
        </div>
        <div className="top_bar">
          <input
            className="search_box"
            onFocus={() => {
              setFocusSearch(true);
            }}
            onBlur={() => {
              setFocusSearch(false);
            }}
            id="search_box"
            type="text"
            onChange={onSearchHandler}
            value={search}
            placeholder="Search Here"
            style={{position:'relative'}}
          />
          {/* <button className="drag_btn" onClick={ondragable}>
            {dragable ? "Lock Script" : "Re Arrange"} {dragable}
          </button> */}
          <button className="drag_btn" onClick={clearwatchlist} style={{marginLeft:10}}>
           Clear Watchlist
          </button>
        </div>
      </div>
      <div
        className="bottom_box"
        style={{
          pointerEvents: buyState || sellState ? "none" : "",
          margin:0,
          marginTop:5,
          overflowY:'scroll',
          height: window.innerHeight-130,
          
          // color: isActive ? 'white' : '',
          /* pointer-events: none; */
        }}
      >
       <Marquee/>
        <table className="tablenew"  onClick={onTableClickHandler}>
          <thead>
          <tr    
          style={{
              background: NewTheme.MainColor,
              height: 30,
              alignItems: "center",
              color: "white",
              fontWeight: "bold",
              borderStyle: "solid",
              borderColor: "black",
              borderWidth: 0,
              borderTopWidth: 1,
              fontSize: 12,
            }}>
            <th>SYMBOL</th>
            <th >EXPIRY</th>
            <th >BID</th>
            <th >ASK</th>
            <th >LTP</th>
            <th >CHNG</th>
            <th >HIGH</th>
            <th >LOW</th>
            <th >OPEN</th>
            <th >CLOSE</th>
            <th >REMOVE</th>
          </tr>
          </thead>
<tbody>
          {list &&
            list.map((item, index) => (
              // <div >
                <WatchItem

                buy={(e)=>setbuyState(e)}
                sell={(e)=>setsellState(e)}
                setvalue={()=>setSelected(item)}
                  // style={{}}
                  key={item.id}
                  item={list[index]}
                  // item={item}
                  index={index}
                  selected={selected}
                  onclickevet={onclickevet}
                  dragEnter={dragEnter}
                  dragStart={dragStart}
                  drop={drop}
                  focusMode={focusMode}
                  changelist={changelist}
                  setchangelist={setchangelist}
                  removeHandler={()=>removescript(item)}
                  isDragging={isDragging}
                />
              // </div>
            ))}
            </tbody>
        </table>

        {list.length ==0 ? "No ITEMS WATCHLIST":null}
        <div className="search_list" style={{position:'absolute',left:10,width:'20%',background:'lightgray'}}>
          {focusMode === "search" && searchList.length > 0 && search.length > 2
            ? searchList &&
              searchList.map((item, index) => (
                <div
                  className="search_item"
                  style={{
                    display: 'flex',
                    background: 'white',
                    fontWeight:'bold',
                    backgroundColor:
                      parseInt(item.id) === parseInt(searchSelected)
                        ? "#fae7cd"
                        : "white",
                    // color: isActive ? 'white' : '',
                  }}
                  id={item.id}
                  value={item}
                  onClick={(e) => addscript(item)}
                >
                  {item.symbol_display}<div style={{fontSize:11,paddingLeft:10,fontWeight:'bold',color:'gray'}}>{moment(item.expiry_date).format("DD-MMM")}</div>
                </div>
              ))
            : ""}
        </div>
      </div>

      {/* buy state */}

      {
        // this.state.
        buyState ? (
          <Buy type={0} selected={selected} onCancelHandler={onCancelHandler} />
        ) : (
          <></>
        )
      }
      {
        // this.state.
        sellState ? (
          <Buy type ={1} selected={selected} onCancelHandler={onCancelHandler} />
        ) : (
          <></>
        )
      }

 
      <ReactModal
        onFocus={(e) => console.log("Modal is clicked")}
        initWidth={(window.innerWidth / 100) * 90}
        initHeight={(window.innerHeight / 100) * 90}
        left={(window.innerWidth / 100) * 5}
        top={(window.innerHeight / 100) * 5}
     
        disableMove={true}
        disableResize={true}
        onRequestClose={closeProfile}
        isOpen={profileOpen}
      

        // initWidth={1000}
        // initHeight={400}
        // onFocus={(e) => console.log("Modal is clicked")}
        // className={"my-modal-custom-class"}
        // onRequestClose={closeProfile}
        // isOpen={profileOpen}
      >
    <Position closeProfile={closeProfile}/>
      </ReactModal>
      <ReactModal
        onFocus={(e) => console.log("Modal is clicked")}
        initWidth={(window.innerWidth / 100) * 90}
        initHeight={(window.innerHeight / 100) * 90}
        left={(window.innerWidth / 100) * 5}
        top={(window.innerHeight / 100) * 5}
        disableMove={true}
        disableResize={true}
        // initWidth={1000}
        // initHeight={400}
        // onFocus={(e) => console.log("Modal is clicked")}
        // className={"my-modal-custom-class"}
        onRequestClose={closeTransaction}
        isOpen={transactionOpen}
      >
        <Transaction closeTransaction={closeTransaction} />
      </ReactModal>
      <ReactModal
        onFocus={(e) => console.log("Modal is clicked")}
        initWidth={(window.innerWidth / 100) * 90}
        initHeight={(window.innerHeight / 100) * 90}
        left={(window.innerWidth / 100) * 5}
        top={(window.innerHeight / 100) * 5}
        disableMove={true}
        disableResize={true}
        // onRequestClose={closeProfile}
        // isOpen={profileOpen}

        // initWidth={1000}
        // initHeight={400}
        // onFocus={(e) => console.log("Modal is clicked")}
        // className={"my-modal-custom-class"}
        onRequestClose={closeLedger}
        isOpen={ledgerOpen}
      >
        <Ledger closeLedger={closeLedger} />
      </ReactModal>

      <ReactModal
        onFocus={(e) => console.log("Modal is clicked")}
        // initWidth={(window.innerWidth / 100) * 50}
        // initHeight={(window.innerHeight / 100) * 50}
        initWidth={(window.innerWidth / 100) * 90}
        initHeight={(window.innerHeight / 100) * 90}
        left={(window.innerWidth / 100) * 5}
        top={(window.innerHeight / 100) * 5}
        disableMove={true}
        disableResize={true}
        // onRequestClose={closeProfile}
        // isOpen={profileOpen}

        // initWidth={1000}
        // initHeight={400}
        // onFocus={(e) => console.log("Modal is clicked")}
        // className={"my-modal-custom-class"}
        onRequestClose={closeAbout}
        isOpen={aboutOpen}
      >
        <About closeAbout={closeAbout} />
      </ReactModal>

      <ReactModal
        onFocus={(e) => console.log("Modal is clicked")}
        // initWidth={(window.innerWidth / 100) * 50}
        // initHeight={(window.innerHeight / 100) * 50}
        initWidth={(window.innerWidth / 100) * 90}
        initHeight={(window.innerHeight / 100) * 90}
        left={(window.innerWidth / 100) * 5}
        top={(window.innerHeight / 100) * 5}
        disableMove={true}
        disableResize={true}
        // onRequestClose={closeProfile}
        // isOpen={profileOpen}

        // initWidth={1000}
        // initHeight={400}
        // onFocus={(e) => console.log("Modal is clicked")}
        // className={"my-modal-custom-class"}
        onRequestClose={closebillmodel}
        isOpen={billopen}
      >
      <Bill closebill={closebillmodel} />
      </ReactModal>


      {/* <ReactModal
        onFocus={(e) => console.log("Modal is clicked")}
        // initWidth={(window.innerWidth / 100) * 50}
        // initHeight={(window.innerHeight / 100) * 50}
        initWidth={(window.innerWidth / 100) * 90}
        initHeight={(window.innerHeight / 100) * 90}
        left={(window.innerWidth / 100) * 5}
        top={(window.innerHeight / 100) * 5}
        disableMove={true}
        disableResize={true}
        // onRequestClose={closeProfile}
        // isOpen={profileOpen}

        // initWidth={1000}
        // initHeight={400}
        // onFocus={(e) => console.log("Modal is clicked")}
        // className={"my-modal-custom-class"}
        onRequestClose={closebillmodel}
        isOpen={billopen}
      >
        <Bill closeAbout={closebillmodel} />
      </ReactModal> */}

      <ReactModal
        onFocus={(e) => console.log("Modal is clicked")}
        initWidth={(window.innerWidth / 100) * 90}
        initHeight={(window.innerHeight / 100) * 90}
        left={(window.innerWidth / 100) * 5}
        top={(window.innerHeight / 100) * 5}
        disableMove={true}
        disableResize={true}
        // initWidth={1000}
        // initHeight={400}
        // onFocus={(e) => console.log("Modal is clicked")}
        // className={"my-modal-custom-class"}
        onRequestClose={closePending}
        isOpen={pendingOpen}
      >
        <Pending closePending={closePending} />
      </ReactModal>
      <ReactModal
        onFocus={(e) => console.log("Modal is clicked")}
        initWidth={(window.innerWidth / 100) * 90}
        initHeight={(window.innerHeight / 100) * 90}
        left={(window.innerWidth / 100) * 5}
        top={(window.innerHeight / 100) * 5}
        disableMove={true}
        disableResize={true}
        // initWidth={1000}
        // initHeight={400}
        // onFocus={(e) => console.log("Modal is clicked")}
        // className={"my-modal-custom-class"}
        onRequestClose={closeRejection}
        isOpen={rejectionOpen}
      >
        <Rejection closeRejection={closeRejection} />
      </ReactModal>
    </div>
  );
};

export default Watchlist;
