// import React from "react";
// import moment from "moment";

// export default function BillCard(props) {
//   const getWeekDates = (weekOffset) => {
//     const today = moment();
//     const startOfWeek = moment().startOf("week").subtract(weekOffset, "weeks");
//     const endOfWeek = moment().endOf("week").subtract(weekOffset, "weeks");

//     return {
//       start: startOfWeek.format("YYYY-MM-DD"),
//       end: endOfWeek.format("YYYY-MM-DD"),
//     };
//   };

//   let weekDates;

//   if (props.data === 0) {
//     weekDates = getWeekDates(0);
//   } else if (props.data === 1) {
//     weekDates = getWeekDates(1);
//   } else if (props.data === 2) {
//     weekDates = getWeekDates(2);
//   } else if (props.data === 3) {
//     weekDates = getWeekDates(3);
//   } else {
//     // Handle other cases or provide a default behavior
//     weekDates = { start: "", end: "" };
//   }

//   return (

//   );
// }

import React from "react";
import moment from "moment";
import { NewTheme } from "../../Theme/Theme";

export default function BillCard(props) {
  const downloadBill = async () => {
    try {
      // Create a reference to the file path where you want to save the PDF
      const fileName = weekDates.end + "bill.pdf";
      const destPath = "/" + fileName; // Change the path as needed

      // Construct the URL
      const url =
        "https://get.dreamtrade.biz/users_bill.php?id=" +
        localStorage.getItem("client") +
        "&date=" +
        weekDates.end +
        "&server=" +
        localStorage.getItem("server") +
        "&token=" +
        localStorage.getItem("token");

      // Use the Fetch API to download the file
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const blob = await response.blob();

      const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = destPath;

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(downloadUrl);

      console.log("Bill Downloaded Successfully");
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const getWeekDates = (weekOffset) => {
    const today = moment();
    const startOfWeek = moment()
      .startOf("week")
      .subtract(weekOffset, "weeks")
      .add(1, "days");
    const endOfWeek = moment()
      .endOf("week")
      .subtract(weekOffset, "weeks")
      .add(1, "days");

    return {
      start: startOfWeek.format("YYYY-MM-DD"),
      end: endOfWeek.format("YYYY-MM-DD"),
    };
  };

  let weekDates;

  if (props.data === 0) {
    weekDates = getWeekDates(0);
  } else if (props.data === 1) {
    weekDates = getWeekDates(1);
  } else if (props.data === 2) {
    weekDates = getWeekDates(2);
  } else if (props.data === 3) {
    weekDates = getWeekDates(3);
  } else {
    // Handle other cases or provide a default behavior
    weekDates = { start: "", end: "" };
  }

  return (
    <div
      style={{
        width: "100%",
        padding: 15,
        display: "flex",
        justifyContent: "space-between",
        fontSize: 13,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: NewTheme.MainColor,
        marginBottom: 10,
        borderRadius: 5,
        fontWeight: "bold",
      }}
      onClick={downloadBill}
    >
      {props.data == 0 ? <div>CURRENT WEEK</div> : null}
      {props.data == 1 ? <div>LAST WEEK</div> : null}
      {props.data == 2 ? <div>WEEK 3</div> : null}
      {props.data == 3 ? <div>WEEK 4</div> : null}

      <div>
        {moment(weekDates.start).format("DD-MMM")} TO{" "}
        {moment(weekDates.end).format("DD-MMM")}
      </div>
    </div>

    //   <div>
    //     <p>Start Date: {weekDates.start}</p>
    //     <p>End Date: {weekDates.end}</p>
    //   </div>
  );
}
