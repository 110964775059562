import React, { useContext, useEffect, useState } from "react";
import { useWindowSize } from "usehooks-ts";
import { NewTheme } from "../Theme/Theme";
import moment from "moment";
import Backend from "../../Backend";
import SocketContect from "../../SocketContext";

const backend = new Backend();
export default function PendingModel(props) {
  const { height, width } = useWindowSize();
  const [qty, setqty] = useState(props.selected.qty);
  const [price, setprice] = useState(props.selected.price);
  const i = props.selected;
  const socket = useContext(SocketContect);

  const [chng, setchng] = useState(0);
  const [percent, setpercent] = useState(0);
  const [ask, setask] = useState(0);
  const [bid, setbid] = useState(0);
  const [high2, sethigh2] = useState(0);
  const [low2, setlow2] = useState(0);
  const [ltp, setltp] = useState(0);
  const [time, setTime] = useState("");

  const trade = (msg) => {
    if (msg == null) {
    } else {
      if (bid !== msg.Bid) {
        setbid(msg.Bid);
      }
      if (ask !== msg.Ask) {
        setask(msg.Ask);
      }
      if (high2 !== msg.High) {
        sethigh2(msg.High);
      }
      if (low2 !== msg.Low) {
        setlow2(msg.Low);
      }
      if (msg.LTP - msg.Previous_Close !== chng) {
        setchng(msg.LTP - msg.Previous_Close);
        setpercent(((msg.LTP - msg.Previous_Close) / msg.Previous_Close) * 100);
      }
      if (ltp !== msg.LTP) {
        setltp(msg.LTP);
      }
      if (time !== msg.Timestamp) {
        setTime(msg.Timestamp);
      }
    }
  };

  const bidask = (msg) => {
    if (bid !== msg.Bid) {
      setbid(msg.Bid);
    }
    if (ask !== msg.Ask) {
      setask(msg.Ask);
    }

    if (time !== msg.Timestamp) {
      setTime(msg.Time);
    }
  };

  useEffect(() => {
    socket.emit("giverate", props.selected.script_id);

    socket.on("trade" + props.selected.script_id, trade);

    socket.on("bidask" + props.selected.script_id, bidask);
    setTimeout(() => {
      document.getElementById("price").focus();
    }, 200);

    return () => {
      socket.off("trade" + props.selected.script_id, trade);

      socket.off("bidask" + props.selected.script_id, bidask);
    };
  }, []);

  const EditPending = () => {
    if (qty == null || price == null) {
      return false;
    }
    if (
      parseFloat(qty) == parseFloat(props.selected.qty) &&
      parseFloat(price) == parseFloat(props.selected.rate)
    ) {
      alert * "hi";
      return false;
    }

    let data = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
      pending_id: props.selected.id,
      qty: qty,
      price: price,
    };
    // alert(JSON.stringify(data));
    // return false;

    backend
      .edit_pending(data)
      .then((r) => {
        if (r.error == "False") {
          props.close();
        } else {
          alert(r.message);
        }

        // loadpending()
      })
      .catch((err) => {
        // alert(JSON.stringify(err));
      });
  };

  return (
    <div
      style={{
        position: "fixed",
        // height: height / 2,
        bottom: 35,
        width: width / 2,
        background: "white",
        left: width / 4,
        zIndex: 55,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: NewTheme.MainColor,
      }}
    >
      <div
        style={{
          width: "100%",
          background: i.buy_sell == 0 ? NewTheme.greencolor : NewTheme.redcolor,
          height: 50,
          display: "flex",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            color: "white",
            padding: 10,
            display: "flex",
            // justifyContent: "center",
            alignItems: "center",
            flex: 3,
          }}
        >
          {i.symbol}
          <div style={{ paddingLeft: 5, fontSize: 11 }}>
            {moment(i.expiry_date).format("DD-MMM")}
          </div>
        </div>

        <div
          style={{
            fontWeight: "bold",
            color: "white",
            padding: 5,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            flexDirection: "column",
          }}
        >
          <div>At Price</div>
          {i.price}
        </div>
        <div
          style={{
            fontWeight: "bold",
            color: "white",
            padding: 5,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            flexDirection: "column",
          }}
        >
          <div>Qty</div>
          {i.qty}
        </div>

        <div
          style={{
            fontWeight: "bold",
            color: "white",
            padding: 5,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            flexDirection: "column",
            paddingRight: 20,
          }}
        >
          <button onClick={() => props.close()}>CLOSE</button>
        </div>
      </div>

      <div style={{ display: "flex" }}>
        <div
          style={{
            fontWeight: "bold",
            color: "black",
            padding: 5,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div>BID</div>
          {bid}
        </div>

        <div
          style={{
            fontWeight: "bold",
            color: "black",
            padding: 5,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div>ASK</div>
          {ask}
        </div>

        <div
          style={{
            fontWeight: "bold",
            color: "black",
            padding: 5,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div>HIGH</div>
          {high2}
        </div>

        <div
          style={{
            fontWeight: "bold",
            color: "black",
            padding: 5,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div>LOW</div>
          {low2}
        </div>
      </div>

      <div
        style={{
          marginTop: 10,
          display: "flex",
          borderStyle: "solid",
          borderWidth: 0,
          borderTopWidth: 1,
        }}
      >
        <div style={{ flex: 1 }}>
          <div style={{ fontWeight: "bold" }}>Price</div>
          <div style={{ marginTop: 3 }}>
            <input id="price" onChange={(e) => setprice(e.target.value)} />
          </div>
          <div style={{ fontWeight: "bold" }}>{i.price}</div>
        </div>
        <div style={{ flex: 1 }}>
          <div style={{ fontWeight: "bold" }}>QTY</div>
          <div style={{ marginTop: 3 }}>
            <input onChange={(e) => setqty(e.target.value)} />
          </div>
          <div style={{ fontWeight: "bold" }}>{i.qty}</div>
        </div>
      </div>
      <div
        style={{
          marginTop: 10,
          display: "flex",
          borderStyle: "solid",
          borderWidth: 0,
          borderTopWidth: 1,
          justifyContent: "center",
          paddingTop: 10,
          paddingBottom: 50,
        }}
      >
        <button onClick={EditPending}>SUBMIT</button>
      </div>
    </div>
  );
}
