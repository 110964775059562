import { React, useState, useEffect, useCallback } from "react";
// import useWindowSize from 'usehooks-ts'
import moment from "moment";

// import "../css/Profile.scss";
import logo from "../../logo.svg";
import Backend from "../../Backend";
import { NewTheme } from "../Theme/Theme";
import PositionCard from "./PositionCard";
import "./tablenew.css";
import TopCard from "./TopCard";
import { connect } from "react-redux";
const backend = new Backend();

const Position = (props) => {
  //   const  {height}  = useWindowSize()
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState([]);
  const [position_filter, setPosition_Filter] = useState([]);
  const [booked, setBooked] = useState(0);
  const [brokerage, setBrokerage] = useState(0);
  const [balance, setBalance] = useState(0);
  const [limit, setlimit] = useState(0);
  const [multiplier, setmultiplier] = useState(0);

  const [profileSelected, setProfileSelected] = useState("");

  console.log("window.navigator.onLine ");
  console.log(window.navigator.onLine);
  useEffect(() => {
    loadPosition();
    var selector = document.getElementById("SELCTFOCUS");
    selector.click();
  }, []);

  // const loadPosition = () => {
  //   setLoading(true);
  //   let data = {
  //     server: localStorage.getItem("server"),
  //     id: localStorage.getItem("client"),
  //     token: localStorage.getItem("token"),
  //   };
  //   try {
  //     backend
  //       .loadPosition(data)
  //       .then((r) => {
  //         setLoading(false);
  //         console.log(r);
  //         if (r.error == "False") {
  //           setPosition(r.position);
  //           // setPosition(r.position);
  //           console.log("position");
  //           console.log(position);

  //           setPosition_Filter(r.position);
  //           setBalance(r.balance);
  //           setBooked(r.booked);
  //           setBrokerage(r.brokerage);
  //           setlimit(r.limit);
  //           setmultiplier(r.multiplier);
  //           // props.clear_pf();
  //         }
  //         if (r.error == "True") {
  //           console.log("error hy bhai");
  //         } else {
  //           // alert(r.message);
  //           console.log("not found");
  //         }
  //       })
  //       .then((e) => {
  //         console.log("error here");
  //       })
  //       .catch((e) => {
  //         console.log("Failed to Fetch");
  //       });
  //   } catch {
  //     console.log("Something Wents Wrong");
  //   }
  // };
  const loadPosition = () => {
    setLoading(true);
    let data = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
    };
    // props.clear_pf();
    // setBooked(0);
    backend.loadPosition(data).then((r) => {
      console.log(r);
      setTimeout(() => {
        setLoading(false);
      }, 500);
      // console.log(r);
      if (r.error == "False") {
        props.clear_pf();
        setPosition(r.data);
        setPosition_Filter(r.data);
        console.log(r);
        setBalance(r.balance);
        setBooked(r.booked);
        // setBrokerage(r.brokerage);
        setlimit(r.blocked_limit);
        setmultiplier(r.multiplier);
      } else {
        alert(r.message);
      }
    });
  };

  const handleKeyPress = useCallback((event) => {
    console.log(`key pressed: ${event.key}`);
    if (event.key === "ArrowUp") {
      console.log(position);
      console.log("position");

      if (position[position.indexOf(profileSelected) - 1] !== undefined) {
        setProfileSelected(position[position.indexOf(profileSelected) - 1]);
      }
    }
    if (event.key === "ArrowDown") {
      console.log(position);
      console.log("position");
      if (position.indexOf(profileSelected) != position.length - 1)
        setProfileSelected(position[position.indexOf(profileSelected) + 1]);
    }

    if (event.key === "Enter") {
      if (profileSelected.id != undefined) {
        PosClose(profileSelected);
      }
    }
  });
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);
  const onclickevent = useCallback((item) => {
    setProfileSelected(item);
  });

  const PosClose = (data) => {
    const newfine = window.confirm(
      "ARE YOU SURE WANT TO CLOSE " + data.symbol + " QTY " + data.qty
    );

    // if (data.segment != 0) {
    //   data.qty = data.qty / data.lot_size;
    // }
    if (newfine) {
      if (!loading) {
        if (data.qty <= 0) {
          alert("Qty Require Greater Then 0");
        } else {
          setLoading(true);
          let sendData = {
            m_code: data.script_id,
            order:
              data.segment != 0
                ? parseFloat(data.qty) / parseFloat(data.lot_size)
                : data.qty,
            ud: data.buy_sell == 1 ? "0" : "1",
            segi: data.script_type,
            token: localStorage.getItem("token"),
          };

          console.log("senddata", sendData);
          // return
          backend
            .sqoffposition(sendData)
            .then((r) => {
              setTimeout(() => {
                setLoading(false);
              }, 500);
              if (r.success == "True") {
                // load_position_overview();
                loadPosition();
                alert(r.msg);
              } else {
                alert(r.msg);
              }
            })
            .catch((err) => {
              setTimeout(() => {
                setLoading(false);
              }, 500);
              console.log(err);
              // Alert.alert('', err);
            });
        }
      }
    }
  };

  return (
    <div
      className="Profile_box"
      style={{ background: NewTheme.lightmain, fontSize: 13 }}
    >
      <div
        id="SELCTFOCUS"
        style={{
          height: 30,
          background: "black",
          display: "flex",
          color: "white",
          fontWeight: "bold",
          alignItems: "center",
          padding: 5,
          boxSizing: "border-box",
        }}
      >
        {/* <div style={{ width: 50 }}>
          <img style={{ height: 25 }} src={logo} alt="" />
        </div> */}
        <div style={{ flex: 1, display: "flex" }}>POSITION</div>
        <div style={{ width: 60 }}>
          <button onClick={props.closeProfile}>Close</button>
        </div>
      </div>
      <TopCard
        // sqoffall={sqoffall}
        booked={booked}
        balance={balance}
        limit={limit}
        multiplier={multiplier}
        loadPosition={loadPosition}
        brokerage={brokerage}
        position={position}
        loading={loading}
      />
      <div
        style={{
          maxHeight: (window.innerHeight / 100) * 75,
          overflowY: "scroll",
        }}
      >
        <table className="tablenew">
          <thead>
            <tr
              style={{
                background: NewTheme.MainColor,

                height: 30,
                alignItems: "center",
                color: "white",
                fontWeight: "bold",
                borderStyle: "solid",
                borderColor: "black",
                borderWidth: 0,
                borderTopWidth: 1,
                fontSize: 12,
              }}
            >
              <th>EXCHANGE</th>
              <th>SYMBOL</th>
              <th>EXPIRY</th>
              <th>BUY QTY</th>
              <th>BUY RATE</th>
              <th>SELL QTY</th>
              <th>SELL RATE</th>
              <th>NET QTY</th>
              <th>CURRENT</th>
              <th>P/L</th>
              <th>DATE</th>
              <th>CLOSE</th>
            </tr>
          </thead>
          <tbody
            style={
              {
                // height: 30,
                // maxHeight: 30,
              }
            }
          >
            {position &&
              position.map((item, index) => (
                <PositionCard
                  key={item.id + item.script_id}
                  PosClose={() => PosClose(item)}
                  data={item}
                  profileSelected={profileSelected}
                  total={position.length}
                />
              ))}

            {position.length > 0 ? null : <div>NO RECORDS FOUND</div>}
          </tbody>
        </table>
      </div>

      {/* <div
        style={{
          background: NewTheme.MainColor,
          display: "flex",
          height: 25,
          alignItems: "center",
          color: "white",
          fontWeight: "bold",
          borderStyle: "solid",
          borderWidth: 0,
          borderTopWidth: 1,
          fontSize: 12,
        }}
      >
        <div
          style={{
            borderStyle: "solid",
            borderWidth: 0,
            borderRightWidth: 1,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          EXCHANGE
        </div>
        <div
          style={{
            flex: 1,
            borderStyle: "solid",
            borderWidth: 0,
            borderRightWidth: 1,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          SYMBOL
        </div>
        <div
          style={{
            flex: 1,
            borderStyle: "solid",
            borderWidth: 0,
            borderRightWidth: 1,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          EXPIRY
        </div>
        <div
          style={{
            flex: 1,
            borderStyle: "solid",
            borderWidth: 0,
            borderRightWidth: 1,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          BUY QTY
        </div>

        <div
          style={{
            flex: 1,
            borderStyle: "solid",
            borderWidth: 0,
            borderRightWidth: 1,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          BUY RATE
        </div>
        <div
          style={{
            flex: 1,
            borderStyle: "solid",
            borderWidth: 0,
            borderRightWidth: 1,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          SELL QTY
        </div>
        <div
          style={{
            flex: 1,
            borderStyle: "solid",
            borderWidth: 0,
            borderRightWidth: 1,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          SELL RATE
        </div>
        <div
          style={{
            flex: 1,
            borderStyle: "solid",
            borderWidth: 0,
            borderRightWidth: 1,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          NET QTY
        </div>
        <div
          style={{
            flex: 1,
            borderStyle: "solid",
            borderWidth: 0,
            borderRightWidth: 1,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          QTY
        </div>
        <div
          style={{
            flex: 1,
            borderStyle: "solid",
            borderWidth: 0,
            borderRightWidth: 1,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          RATE
        </div>
        <div
          style={{
            flex: 1,
            borderStyle: "solid",
            borderWidth: 0,
            borderRightWidth: 1,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          CURRENT
        </div>
        <div
          style={{
            flex: 1,
            borderStyle: "solid",
            borderWidth: 0,
            borderRightWidth: 1,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          P/L
        </div>
        <div
          style={{
            flex: 1,
            borderStyle: "solid",
            borderWidth: 0,
            borderRightWidth: 1,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          DATE
        </div>
        <div
          style={{
            flex: 1,
            borderStyle: "solid",
            borderWidth: 0,
            borderRightWidth: 1,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          SQ-OFF
        </div>
      </div> */}

      {/* {position &&
        position.map((item, index) => (
          <PositionCard
            key={item.id}
            data={item}
            profileSelected={profileSelected}
          />
        ))}

      {position.length > 0 ? null : <div>NO RECORDS FOUND</div>} */}
      {/* <div className="Profile_header" >
        <img src={logo} alt="" />
        <p>Position</p>
        <button onClick={props.closeProfile}>Close</button>
      </div>
      <div className="tableFixHead" >
        <table className="table" >
          <thead>
            <tr className="table_head" >
              <th>SYMBOL</th>

              <th>EXPIRY</th>
              <th>BUY-SELL</th>
              <th>DATED</th>
              <th>QTY</th>
              <th>RATE</th>

              <th>CURRENT RATE</th>
              <th>PROFIT</th>
              <th>CLOSE</th>
            </tr>
          </thead>
          <tbody
            style={{
              height: 20 + "!important",
              overflowY: "scroll",
            }}

            // style={{
            //   "height: 10 + !important, overflow: scroll"
            // // height: 10;  overFlow: "scroll"
            // }}
          >
            {position &&
              position.map((item, index) => (
                <tr
                  id={item.id}
                  onClick={() => onclickevent(item)}
                  style={{
                    // backgroundColor: parseInt(item.buy_sell) === 1 ? "#ffe2e8" : "#e6fde5",
                   down
                  }}
                >
                  <td>
                    <p>{item.symbol}</p>
                  </td>

                  <td>{moment(item.expiry_date).format("DD-MMM")}</td>
                  <td>{parseInt(item.buy_sell) === 1 ? "Sell" : "BUY"}</td>
                  <td>{item.date_created}</td>
                  <td>{item.qty}</td>
                  <td>{item.rate}</td>

                  <td>
                    {typeof item.current_rate != undefined
                      ? item.current_rate
                      : 0}
                  </td>
                  <td>
                    {typeof item.profit_loss != undefined
                      ? item.profit_loss
                      : 0}
                  </td>
                  <td>
                    <button onClick={() => PosClose(item)} className="tra_bs">
                      Close
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div> */}
    </div>
  );
};
const MapStateToDispatch = (dispatch) => {
  return {
    clear_pf: () => {
      dispatch({ type: "CLEAR_PF" });
    },
  };
};

export default connect(null, MapStateToDispatch)(Position);
