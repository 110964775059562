import {
  React,
  useState,
  useEffect,
  useCallback,
  Component,
  useContext,
  // useIsFocused
} from "react";
import "./Position.css";
import logo from "../../logo.svg";
import moment from "moment";

import Backend from "../../Backend";

import SocketContext from "../../SocketContext";
import { FaCircleArrowDown } from "react-icons/fa6";
import { NewTheme } from "../../Theme/Theme";
import { connect } from "react-redux";
const Positionitem = (props) => {
  const socket = useContext(SocketContext);
  const i = props.item;
  const [ask, setask] = useState(0);
  const [bid, setbid] = useState(0);
  const [pf, setpf] = useState(0);
  const [btotal, setBtotal] = useState(0);
  // let brokerage_total = 0;
  // var bro
  // let ismouted = useIsFocused();

  const sockettrade = (msg) => {
    if (bid !== msg.Bid) {
      setbid(msg.Bid);
      if (props.item.buy_sell == 0) {
        let Profitloss = 0;
        let brokerage_total = 0;

        if (msg.Bid > 0) {
          Profitloss =
            parseFloat(parseFloat(msg.Bid) - parseFloat(props.item.rate)) *
            parseFloat(props.item.qty);
          Profitloss = Profitloss; //single side
          if (
            props.item.segment == 0 ||
            (props.item.segment == 1 && props.item.brokerage < 1)
          ) {
            Profitloss =
              Profitloss -
              (props.item.brokerage * props.item.qty * parseFloat(msg.Bid)) /
                100;
            brokerage_total = parseFloat(
              (props.item.brokerage * props.item.qty * parseFloat(msg.Bid)) /
                100
            );
          } else {
            Profitloss = Profitloss - props.item.brokerage;
            brokerage_total = props.item.brokerage;
          }
        } else {
          Profitloss = 0;
        }

        setpf(Profitloss);
        setBtotal(brokerage_total);
      }
    } else {
      setbid(msg.LTP);

      if (props.item.buy_sell == 0) {
        let Profitloss = 0;
        let brokerage_total = 0;
        if (msg.Bid > 0) {
          Profitloss =
            parseFloat(parseFloat(msg.LTP) - parseFloat(props.item.rate)) *
            parseFloat(props.item.qty);

          Profitloss = Profitloss; //single side

          if (
            props.item.segment == 0 ||
            (props.item.segment == 1 && props.item.brokerage < 1)
          ) {
            Profitloss =
              Profitloss -
              (props.item.brokerage * props.item.qty * parseFloat(msg.LTP)) /
                100;

            brokerage_total = parseFloat(
              (props.item.brokerage * props.item.qty * parseFloat(msg.LTP)) /
                100
            );
          } else {
            Profitloss = Profitloss - props.item.brokerage;
            brokerage_total = props.item.brokerage;
          }
        } else {
          Profitloss = 0;
        }

        setpf(Profitloss);
        setBtotal(brokerage_total);
      }
    }

    if (ask !== msg.Ask) {
      setask(msg.Ask);

      if (props.item.buy_sell == 1) {
        let Profitloss2 = 0;
        let brokerage_total = 0;
        if (parseFloat(msg.Ask) > 0) {
          Profitloss2 = (props.item.rate - msg.Ask) * props.item.qty;

          Profitloss2 = Profitloss2; //single side

          if (
            props.item.segment == 0 ||
            (props.item.segment == 1 && props.item.brokerage < 1)
          ) {
            Profitloss2 =
              Profitloss2 -
              (props.item.brokerage * props.item.qty * parseFloat(msg.Ask)) /
                100;

            brokerage_total = parseFloat(
              (props.item.brokerage * props.item.qty * parseFloat(msg.Ask)) /
                100
            );
          } else {
            Profitloss2 = Profitloss2; //single side
            brokerage_total = props.item.brokerage;
          }
        } else {
          Profitloss2 = 0;
        }
        setpf(Profitloss2);
        setBtotal(brokerage_total);
      }
    } else {
      setask(msg.LTP);
      if (props.item.buy_sell == 1) {
        let Profitloss2 = 0;
        let brokerage_total = 0;
        if (parseFloat(msg.LTP) > 0) {
          Profitloss2 = (props.item.rate - msg.LTP) * props.item.qty;

          Profitloss2 = Profitloss2; //single side

          if (props.item.segment == 0) {
            Profitloss2 =
              Profitloss2 -
              (props.item.brokerage * props.item.qty * parseFloat(msg.LTP)) /
                100;

            brokerage_total = parseFloat(
              (props.item.brokerage * props.item.qty * parseFloat(msg.LTP)) /
                100
            );
          } else {
            Profitloss2 = Profitloss2; //single side
            brokerage_total = props.item.brokerage;
          }
        } else {
          Profitloss2 = 0;
        }
        setpf(Profitloss2);
        setBtotal(brokerage_total);
      }
    }
  };

  const socketbidask = (msg) => {
    let brokerage_total = 0;
    if (bid !== msg.Bid) {
      setbid(msg.Bid);
      if (props.item.buy_sell == 0) {
        let Profitloss3 = 0;
        Profitloss3 = (msg.Bid - props.item.rate) * props.item.qty;

        Profitloss3 = Profitloss3; //single side

        if (
          props.item.segment == 0 ||
          (props.item.segment == 1 && props.item.brokerage < 1)
        ) {
          Profitloss3 =
            Profitloss3 -
            (props.item.brokerage * props.item.qty * parseFloat(msg.Bid)) / 100;

          brokerage_total = parseFloat(
            (props.item.brokerage * props.item.qty * parseFloat(msg.Bid)) / 100
          );
        } else {
          Profitloss3 = Profitloss3; //single side
          brokerage_total = props.item.brokerage;
        }

        setpf(Profitloss3);
      }
    }
    if (ask !== msg.Ask) {
      setask(msg.Ask);
      if (props.item.buy_sell == 1) {
        let Profitloss4 = 0;
        Profitloss4 = (props.item.rate - msg.Ask) * props.item.qty;

        Profitloss4 = Profitloss4; //single side

        if (
          props.item.segment == 0 ||
          (props.item.segment == 1 && props.item.brokerage < 1)
        ) {
          Profitloss4 =
            Profitloss4 -
            (props.item.brokerage * props.item.qty * parseFloat(msg.Ask)) / 100;

          brokerage_total = parseFloat(
            (props.item.brokerage * props.item.qty * parseFloat(msg.Ask)) / 100
          );
        } else {
          Profitloss4 = Profitloss4 - props.item.brokerage; //single side
          brokerage_total = props.item.brokerage;
        }

        setpf(Profitloss4);
        setBtotal(brokerage_total);
      }
    }
  };

  useEffect(
    () => {
      socket.emit("giverate", i.script_id);

      socket.on("trade" + i.script_id, sockettrade);

      socket.on("bidask" + i.script_id, socketbidask);

      return () => {
        socket.off("trade" + i.script_id, sockettrade);

        socket.off("bidask" + i.script_id, socketbidask);
      };
    },
    [
      // ismouted
    ]
  );
  // alert(props.item.script_id);
  props.calc_pf(i.script_id, pf, btotal, props.total);
  // console.log(props.item.script_id, pf, btotal, props.total)

  return (
    <>
      <div
        onClick={() => {
          localStorage.setItem("item", JSON.stringify(i));
          window.location = "/stock";
        }}
        style={{
          background: "white",
          display: "flex",
          padding: 10,
          boxSizing: "border-box",
          borderStyle: "solid",
          borderWidth: 0,
          borderTopWidth: 1,
          borderColor: "gray",
          borderBottomWidth: props.length == props.index ? 1 : 0,
        }}
      >
        <div
          style={{
            width: 70,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FaCircleArrowDown
            size={24}
            style={{
              color:
                i.buy_sell == "0" ? NewTheme.greencolor : NewTheme.redcolor,
              transform: i.buy_sell == 0 ? "rotate(225deg)" : "rotate(45deg)",
            }}
          />
          <div style={{ fontWeight: "bold", fontSize: 11 }}>{i.qty}</div>
        </div>
        <div style={{ flex: 2 }}>
          <div style={{ fontWeight: "bold", fontSize: 14, display: "flex" }}>
            {i.symbol}
            <div style={{ fontSize: 10, color: "gray", paddingLeft: 5 }}>
              {moment(i.expiry_date).format("DD-MMM")}
            </div>
          </div>

          <div
            style={{
              color: "gray",
              fontWeight: "bold",
              fontSize: 13,
              display: "flex",
              alignItems: "center",
            }}
          >
            {i.buy_sell == 0 ? "B:" : "S:"}
            {i.rate}
            <FaCircleArrowDown
              size={12}
              style={{
                color:
                  i.buy_sell == "0" ? NewTheme.greencolor : NewTheme.redcolor,
                transform: "rotate(-90deg)",
                marginLeft: 5,
                marginRight: 5,
              }}
            />
            {" C:"}
            {i.buy_sell == 0 ? bid : ask}
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "flex-end",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              color: pf < 0 ? NewTheme.redcolor : NewTheme.greencolor,
            }}
          >
            {parseFloat(pf).toFixed(2)}
          </div>
          <div style={{ fontSize: 11, fontWeight: "bold", color: "gray" }}>
            {moment(i.date_created).format("DD-MMM HH:mm:ss")}
          </div>
        </div>
      </div>
    </>
  );
};
const MapStateToProps = (state) => {
  return {
    theme: state.theme,
  };
};

const MapStateToDispatch = (dispatch) => {
  return {
    calc_pf: (r, h, b, total) => {
      dispatch({
        type: "PF",
        script_id: r,
        pf: h,
        btotal: b,
        total: total,
      });
    },
    selected: (a) => dispatch({ type: "WATCHLIST_SELECT", payload: a }),
  };
};

export default connect(MapStateToProps, MapStateToDispatch)(Positionitem);
