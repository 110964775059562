import React, { useState , useEffect ,useCallback} from "react";
import "../css/Login.scss";
// import logo from '../logo.svg';
import logo from "../Images/Images/logo.png";
import Backend from "../Backend";
import { NewTheme } from "./Theme/Theme";
import { useWindowSize } from "usehooks-ts";
const backend = new Backend();



const Login = () => {
  const [server, setserver] = useState("Hello");
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [demoscreen, setdemoscreen] = useState(false);
  const {height,width} = useWindowSize()


  const getDemo = () => {
    setdemoscreen(true)
    backend.get_demo().then(r => {
     
      // setLoading(false);
      if (r.error == 'False') {

        setTimeout(() => {
          login_demo(r.data.server, r.data.username, r.data.username);
          // setdemoscreen(false)
        }, 2500);
       
        setTimeout(() => {
          
          setdemoscreen(false)
        }, 3000);
      } else {
        alert(r.message);
      }
    });
  };

  // const onSubmitHandler = () => {
  //   let data = {
  //     server: server,
  //     username: username,
  //     password: password,
  //   };
  //   // backend.login(data).then((r) => {
  //   //   console.log(r)
  //   //   if (r.error == "False") {
  //   //     if (r.client_details.acc_type == 0) {
  //   //       localStorage.setItem("IsLoggedIn", "1");
  //   //       localStorage.setItem("token", r.token);
  //   //       localStorage.setItem("rules", JSON.stringify(r.rules));
  //   //       localStorage.setItem("server", r.client_details.server);
  //   //       localStorage.setItem("client", JSON.stringify(r.client_details.id));
  //   //       localStorage.setItem(
  //   //         "client_details",
  //   //         JSON.stringify(r.client_details)
  //   //       );
  //   //       window.location = "/";
  //   //     } else {
  //   //       alert("Client Login Only !!");
  //   //     }
  //   //   } else {
  //   //     // alert(r.message);
  //   //   }
  //   // }

  //   // )

  // }
  const login_demo = (a,b,c) => {

      // setLoading(true);
      let data = {
        un: b,
        pw: c,
        sr: a,
        fcm: "",
        ip: "",
        device: 'webtrade',
        version: localStorage.getItem("version"),
        
        // fcm: fcmtoken,
        // ip: ip,
      };
      backend.login(data).then((r) => {
        console.log(r);

        // setLoading(false);
        if (r.error == "False") {
          localStorage.setItem("token", r.jwt);
          // localStorage.setItem('rules', JSON.stringify(r.rules));
          localStorage.setItem("server", r.sv);
          localStorage.setItem("client", r.users_id);
          localStorage.setItem("client_details", JSON.stringify(r.users));
          localStorage.setItem("IsLoggedIn", "1");
          // props.token(r.jwt);
          // props.id(r.users_id);
          // props.server(r.sv);
          // props.isLogin(1);
          localStorage.setItem("IsLoggedIn", "1");
          window.location = "/";
        } else {
          alert(r.message);
        }
      });
    
  };

  const login = () => {
    if (server == "") {
      window.alert("Enter Server");
    } else if (username == "") {
      window.alert("Enter Username");
    } else if (password == "") {
      window.alert("Enter Password");
    } else {
      // setLoading(true);
      let data = {
        un: username,
        pw: password,
        sr: server,
        fcm: "",
        ip: "",
        device: 'webtrade',
        version: localStorage.getItem("version"),
        // fcm: fcmtoken,
        // ip: ip,
      };
      backend.login(data).then((r) => {
        console.log(r);

        // setLoading(false);
        if (r.error == "False") {
          localStorage.setItem("token", r.jwt);
          // localStorage.setItem('rules', JSON.stringify(r.rules));
          localStorage.setItem("server", r.sv);
          localStorage.setItem("client", r.users_id);
          localStorage.setItem("client_details", JSON.stringify(r.users));
          localStorage.setItem("IsLoggedIn", "1");
          // props.token(r.jwt);
          // props.id(r.users_id);
          // props.server(r.sv);
          // props.isLogin(1);
          localStorage.setItem("IsLoggedIn", "1");
          window.location = "/";
        } else {
          alert(r.message);
        }
      });
    }
  };

  const handleKeyPress = useCallback((event) => {
    console.log(`key pressed: ${event.key}`);
    if (event.key === "Enter") {
      // console.log("now a pressed");
      // setbuyState(true);
      // setsellState(false);
      // console.log(buyState);
      login()
    }
  });

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <div className="LoginContainer">
      {demoscreen?
      <div style={{background:'white',height:height,width:width,position:'fixed',fontWeight:'bold',justifyContent:'center',alignItems:'center',display:'flex',fontSize:22}}>
        PLEASE WAIT WE ARE CREATING DEMO ACCOUNT FOR YOU
      </div>:null}
      {/* <div className='illustrationbox'>
        <img className='illustration' src={logo} alt="" />
      </div> */}
      <div className="loginbox">
        <img className="logo" src={logo} alt="" srcset="" />
        <div className="loginitem">
          <input
          style={{padding:0}}
            type="text"
            name=""
            id=""
            onChange={(e) => setserver(e.target.value)}
            placeholder="Enter Server"
          />
        </div>

        <div className="loginitem">
          <input
            type="text"
            name=""
            id=""
            onChange={(e) => setusername(e.target.value)}
            placeholder="Enter Username"
          />
        </div>
        <div className="loginitem">
          <input
            type="password"
            name=""
            id=""
            onChange={(e) => setpassword(e.target.value)}
            placeholder="Enter your password"
          />
        </div>
        {/* <p className='forgot_text'>Forgot Password</p> */}
        <button style={{background:NewTheme.MainColor,borderColor:NewTheme.MainColor}} className="submit_btn" onClick={login} type="submit">
          Login
        </button>
        <p className="bottom_text">
          Login with demo<div onClick={getDemo} style={{color:NewTheme.MainColor,padding:10}}>Demo Login</div>
        </p>
      </div>
    </div>
  );
};

export default Login;
